<template>
  <div class="py-4 container-fluid">
    <a class="btn btn-sm btn-success" style="margin-right: 10px;" :href="apiUrl+'export-excel/dashboard-purchasing?pr_code='+search.pr_code+'&project_type='+search.project_type+'&part_name='+search.part_name+'&specification='+search.specification+'&size='+search.size+'&status='+search.status+'&date_required='+search.date_required+'&request_by='+search.request_by+'&buyer='+search.buyer+''" target="_BLANK"><i class="fa fa-file-excel-o"></i> Export to Excel</a>
    <a class="btn btn-sm btn-primary" style="margin-right: 10px;" :href="apiUrl+'print-pdf/dashboard-purchasing?pr_code='+search.pr_code+'&project_type='+search.project_type+'&part_name='+search.part_name+'&specification='+search.specification+'&size='+search.size+'&status='+search.status+'&date_required='+search.date_required+'&request_by='+search.request_by+'&buyer='+search.buyer+''" target="_BLANK"><i class="fa fa-file-pdf-o"></i> Export to PDF</a>
    <div class=" row">
      <div class="col-12">
          <div class="card"> 
            <div class="row">
              <div class="col-lg-4">
              </div>
              <div class="col-lg-5">
                <div class="card-header pb-0 text-center">
                  <h6>PR PROGRES MONITORING</h6>
                  <!-- <h6 style="margin-top: -10px;">PT. DAEKYUG INDAH HEAVY INDUSTRY</h6> -->
                </div>
              </div>
              <div class="col-lg-3 float-right">
                <argon-button
                  style="margin-right: 10px; margin-left: 60px;"
                  class="mt-4"
                  variant="gradient"
                  color="secondary"
                  size="sm"
                  @click="filter()"
                ><i class="fa fa-filter fa-sm" aria-hidden="true"></i> Filter</argon-button>
                <!-- <argon-button
                  class="mt-4"
                  variant="gradient"
                  color="success"
                  size="sm"
                  @click="createWithSubForm()"
                ><i class="fa fa-plus fa-sm" aria-hidden="true"></i> Add New</argon-button> -->
              </div>
            </div>
            
            <div class="card-body px-0 pt-0 pb-2 mt-4">
              <div class="table-responsive p-0 scroll">
                <table class="table table-hover table-striped align-items-center mb-0">
                  <thead>
                    <tr>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">No</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">PR NO</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">PR ITEM NO</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">PR TYPE</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">PART NAME</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">SPECIFICATION</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">SIZE</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">UNIT</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">QTY</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">STATUS</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">DATE REQUIRED</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">REQUEST BY</th>
                      <!-- <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">BUYER</th> -->
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">TRACKING</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, i) in table.data" :key="i">
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ i + 1 }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="badge badge-sm bg-gradient-primary">  {{ row.pr_code }} </span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold"> {{ row.pr_code }}-{{ row.no_item }} </span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold"> {{ row.project_type }} </span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold"> {{ row.part_name }} </span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold"> {{ row.specification }} </span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold"> {{ row.size }} </span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold"> {{ row.unit }} </span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold"> {{ row.qty }} </span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="badge badge-sm bg-gradient-info"> {{ row.status_item }} </span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">  {{ moment(row.date_required).locale('id').format('LL') }} </span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">  {{ row.created_by }} </span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="badge badge-sm bg-gradient-warning">  {{ row.status }} </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer">
              <div class="float-right">
                <base-pagination :page-count="pagination.page_count" :value="pagination.page" @input="changePage" :total="pagination.page_count" class="justify-content-end"></base-pagination>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>

   <!-- ================================================  MODAL FILTER ============================================== -->
  <div class="container">
    <vue-final-modal v-model="formFilter.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formFilter.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formFilter.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">PR NO </label>
        <input type="text" class="form-control" placeholder="PR NO" v-model="search.pr_code" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">PR Type </label>
        <select class="form-select" aria-label="Default select example" v-model="search.project_type" v-on:keyup.enter="get()">
          <option value="Project">Project</option>
          <option value="Non Project">Non Project</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Part Name </label>
        <input type="text" class="form-control" placeholder="Part Name" v-model="search.part_name" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Specification </label>
        <input type="text" class="form-control" placeholder="Specification" v-model="search.specification" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Size </label>
        <input type="text" class="form-control" placeholder="Size" v-model="search.size" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Status </label>
        <select class="form-select" aria-label="Default select example" v-model="search.status" v-on:keyup.enter="get()">
          <option value="F/R">F/R - FOR REFERENCE</option>
          <option value="F/W">F/W - FOR WORKING</option>
          <option value="H">H - HOLD</option>
          <option value="R">R - RELEASE</option>
          <option value="C.L">C.L - CANCELLED</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Date Required </label>
        <input type="date" class="form-control" placeholder="Date Required" v-model="search.date_required" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Request By </label>
        <input type="date" class="form-control" placeholder="Request By" v-model="search.request_by" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Buyer </label>
        <input type="date" class="form-control" placeholder="Buyer" v-model="search.buyer" v-on:keyup.enter="get()">
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="search = {}">Clear</argon-button>
        </div>
        <div class="col-1">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="get()" :disabled="onLoading">
            <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
            <span v-else>
                <span>Filter</span>
            </span>
          </argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
import { VueFinalModal } from 'vue-final-modal'
import BasePagination from "@/components/BasePagination";
import Api from '@/helpers/api';
import config from '@/configs/config';
import { toast } from 'vue3-toastify';
// import 'vue3-toastify/dist/index.css';
// import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css';
var moment = require('moment');
import purchasingRequisition from '@/services/procurement/purchasingRequisition.service';
import workOrder from '@/services/procurement/workOrder.service';
import materialGroupPR from '@/services/procurement/materialGroupPR.service';

export default {
  name: "tables",
  components: {
    ArgonButton,
    VueFinalModal,
    // Multiselect,
    BasePagination
  },
  data() {
    return {
      pagination: {
        page_count: '',
        page: 1,
      },
      moment:moment,
      table: {
        data: []
      },
      formFilter: {
        add: true,
        title: "Filter",
        show: false
      },
      onLoading: false,
      storageUrl : config.storageUrl,
      headerData: {},
      search: {
        pr_code: '',
        project_type: '',
        part_name: '',
        specification: '',
        size: '',
        status: '',
        date_required: '',
        request_by: '',
        buyer: '',
      },
      apiUrl :config.apiUrl,
      tableWo: {
        data: []
      },
      tableMaterialGroupPR: {
        data: []
      },
      tableItem: {
        data: []
      },

    };
  },
  mounted(){
    this.get();
    this.tokenApi = 'Bearer '+localStorage.getItem('token');
  },
  methods: {
    get(param){
      let context = this; 
      context.onLoading = true;

      Api(context, purchasingRequisition.getMonitoringPrItem({pr_code: this.search.pr_code, project_type: this.search.project_type, part_name: this.search.part_name, specification: this.search.specification, size: this.search.size, status: this.search.status, date_required: this.search.date_required, request_by: this.search.request_by, buyer: this.search.buyer, page: context.pagination.page})).onSuccess(function(response) {    
          context.table.data            = response.data.data.data.data;
          context.pagination.page_count = response.data.data.data.last_page

          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.table.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.formFilter.show = false;
         context.onLoading       = false;
      })
      .call()
    },
    getWo(param){
      if (param.target.value.length >= 3) {
        let context = this;        
        Api(context, workOrder.getWo({search: param.target.value})).onSuccess(function(response) {    
            context.tableWo.data = response.data.data;
        }).onError(function(error) {                    
            if (error.response.status == 404) {
                context.tableWo.data = [];
            }
        }).onFinish(function() { 
        })
        .call()
      }
    },
    getMaterialGroupPR(param){
      if (param.target.value.length >= 1) {
        let context = this;        
        Api(context, materialGroupPR.getMaterialGroupPR({search: param.target.value})).onSuccess(function(response) {    
            context.tableMaterialGroupPR.data = response.data.data;
        }).onError(function(error) {                    
            if (error.response.status == 404) {
                context.tableMaterialGroupPR.data = [];
            }
        }).onFinish(function() { 
        })
        .call()
      }
    },
    getDetailTransaction(trxnumber){
      let context = this;               
      Api(context, purchasingRequisition.transactionDetail({pr_code: trxnumber})).onSuccess(function(response) {    
          context.headerData.id                  = response.data.data.data.id;
          context.headerData.pr_no               = response.data.data.data.pr_code;
          context.headerData.project_type        = response.data.data.data.project_type;
          context.headerData.wo_no               = response.data.data.data.wo_code;
          context.headerData.material_group_code = response.data.data.data.material_group_code;
          context.headerData.date_required       = response.data.data.data.date_required;
          context.headerData.status              = response.data.data.data.status;
          context.headerData.general_note        = response.data.data.data.general_note;
          context.headerData.item_no             = response.data.data.data.item_no;
          context.headerData.client              = response.data.data.data.client;
          context.headerData.contract_delivery   = response.data.data.data.contract_delivery;

          context.headerData.wo_no               = response.data.data.dataWo;
          context.headerData.material_group_code = response.data.data.dataMGPR;

          context.dataListItem.data = response.data.data.dataListItem;

          context.formSub.add   = true;
          context.formSub.show  = true;
          context.formSub.title = "==== TRANSACTION PR DETAIL ====";

          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.headerData = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
      })
      .call()
    },
    setByWO(){
      this.headerData.client            = this.headerData.wo_no['client'];
      this.headerData.contract_delivery = this.headerData.wo_no['due_date'];
    },
    filter() {
      this.formFilter.add   = true;
      this.formFilter.show  = true;
      this.formFilter.title = "Filter";
      this.onLoading        = false;
      this.pagination.page  = 1 ;
    },
    createWithSubForm() {
      this.formSub.add       = true;
      this.formSub.show      = true;
      this.formSub.title     = "==== TRANSACTION PR ADD DATA ====";
      this.headerData        = {};
      this.headerData.status = 'Draf',
      this.dataListItem.data = {}
      this.defaultDate()
    },
    createItem() {
      this.formAddItem.add   = true;
      this.formAddItem.show  = true;
      this.formAddItem.title = "Add Item";
      this.itemData          = {};
    },
    editItem(id) {
      let context = this;               
      Api(context, purchasingRequisition.showItem(id)).onSuccess(function(response) {
          context.itemData              = response.data.data;
          context.itemData.item_pr_code = response.data.data.item_pr_code;
          context.formAddItem.show      = true;
          context.formAddItem.title     = 'Edit Data';   
      })
      .call()        
    },
    filesChange(e) {
      this.headerData.attachment = e.target.files[0];
    },
    save(){
      let api      = null;
      let context  = this;
      let formData = new FormData();

      // INSERT HEADER
      if (context.headerData.project_type != undefined && context.headerData.wo_no != undefined && context.headerData.material_group_code != undefined && context.headerData.client != undefined && context.headerData.date_required != undefined && context.headerData.contract_delivery != undefined && context.headerData.status != undefined ) {
        formData.append('pr_no', (context.headerData.pr_no == undefined) ? '' : context.headerData.pr_no);
        formData.append('project_type', context.headerData.project_type);
        formData.append('wo_no', JSON.stringify(context.headerData.wo_no));
        formData.append('material_group_code', JSON.stringify(context.headerData.material_group_code));
        formData.append('date_required', context.headerData.date_required);
        formData.append('status', context.headerData.status);
        formData.append('general_note', (context.headerData.general_note == undefined) ? '-' : context.headerData.general_note);
        formData.append('client', context.headerData.client);
        formData.append('contract_delivery', context.headerData.contract_delivery);
        formData.append('item_no', (context.headerData.item_no == undefined) ? '-' : context.headerData.item_no);
      }else{
        return alert('The Red Star Field Header is Mandatory')
      }

      // INSERT ITEM
      if (context.itemData.no_item != undefined && context.itemData.part_name != undefined && context.itemData.specification != undefined && context.itemData.size != undefined && context.itemData.unit != undefined && context.itemData.qty != undefined && context.itemData.status_item != undefined) {
        formData.append('no_item', context.itemData.no_item);
        formData.append('item_pr_code', (context.itemData.item_pr_code == undefined) ? '' : context.itemData.item_pr_code);
        formData.append('part_name', context.itemData.part_name);
        formData.append('specification', context.itemData.specification);
        formData.append('size', context.itemData.size);
        formData.append('unit', context.itemData.unit);
        formData.append('qty', context.itemData.qty);
        formData.append('weight', (context.itemData.weight == undefined) ? '' : context.itemData.weight);
        formData.append('revisi_item', (context.itemData.revisi_item == undefined) ? '' : context.itemData.revisi_item);
        formData.append('mps_no', (context.itemData.mps_no == undefined) ? '-' : context.itemData.mps_no);
        formData.append('item_no', (context.itemData.item_no == undefined) ? '-' : context.itemData.item_no);
        formData.append('use_for', (context.itemData.use_for == undefined) ? '-' : context.itemData.use_for);
        formData.append('status_item', context.itemData.status_item);
        // formData.append('attachment', context.itemData.attachment);
      }else{
        return alert('The Red Star Field Item is Mandatory')
      }

      if (context.formSub.title == '==== TRANSACTION PR ADD DATA ') {
          api = Api(context, purchasingRequisition.create(formData));
      } else {
          api = Api(context, purchasingRequisition.create(formData));
      }
      api.onSuccess(function(response) {
        context.get();
        context.getDetailTransaction(response.data.data.pr_code);
        context.notify((context.formSub.title === '==== TRANSACTION PR ADD DATA ') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      }).onError(function(error) { 
        context.notify((context.formSub.title != '==== TRANSACTION PR ADD DATA ') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
        context.formAddItem.show = false;
      })
      .call();
    },
    remove(id, total_item, transaction_type) {
      var r = confirm("Are you sure you want to delete this data ?");
      if (r == true) {
        // if ((transaction_type == 'Reduction') || (total_item == 0)) {
          let context = this;
          Api(context, purchasingRequisition.delete(id)).onSuccess(function(response) {
              context.get();
              context.notify('Data Successfully Deleted', 'success')
          }).call();
        // }else{
        //   this.notify('Failed to delete. Please clear items first', 'error')
        // }
      }
    },
    removeItem(id) {
      var r = confirm("Are you sure you want to delete this data ?");
      if (r == true) {
        let context  = this;

        Api(context, purchasingRequisition.deleteItem(id)).onSuccess(function(response) {
            context.get();
            context.getDetailTransaction(context.headerData.pr_no);
            context.notify('Data Successfully Deleted', 'success')
        }).call();
      }
    },
    prApproved(param){
      let api      = null;
      let context  = this;

      api = Api(context, purchasingRequisition.prApproved({pr_code: param}));
      // eslint-disable-next-line no-unused-vars
      api.onSuccess(function(response) {
        context.getDetailTransaction(context.headerData.pr_no)
        context.get();
        context.notify((context.form === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      // eslint-disable-next-line no-unused-vars
      }).onError(function(error) { 
        context.notify((context.form != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
      })
      .call();
    },
    modalImport(){
      this.formImport.show  = true;
      this.formImport.title = "Import Data Setting Approval";
      this.tabelError.data  = [];
    },
    filesChangeImport(e) {
        this.dataImport = e.target.files[0];
    },
    importData(){
      let api = null;
      let context = this;
      let formData = new FormData();
      this.onLoading = true;

      if (this.dataImport != undefined) {
        formData.append('import_data', this.dataImport);
      }else{
        return alert('File Import Not Found')
      }

      api = Api(context, purchasingRequisition.import(formData));
      api.onSuccess(function(response) {
          context.onLoading = false;
          context.get();
          context.formImport.show = false;
          context.notify('Import Data Success', 'success')
      }).onError(function(error) {      
          context.tabelError.data = error.response.data.data;              
          context.notify('Import Data Failed', 'error')
          context.onLoading = false;
      }).onFinish(function() {  
      })
      .call();
    },
    defaultDate(){
      var date  = new Date();
      var day   = ("0" + date.getDate()).slice(-2);
      var month = ("0" + (date.getMonth() + 1)).slice(-2);
      var today = date.getFullYear() + "-" + (month) + "-" + (day);

      this.headerData.date = today
    },
    changePage(page){
      this.pagination.page = page;
      this.get();
    },
    notify(message, type) {
      if (type == 'success') {
        toast.success(message, {
          autoClose: 2000,
        }); // ToastOptions
      }else{
        toast.error(message, {
          autoClose: 2000,
        }); // ToastOptions
      }
    },

  }
};
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 500px;
  margin-top: auto;
  margin-bottom: auto;
}
/*MODAL WIDTH KUSUS LEBAR BESAR*/
::v-deep .modal-content-width {
  /*display: flex;*/
  /*flex-direction: column;*/
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 1090px;
  margin-top: auto;
  margin-bottom: auto;
}
.modal__title {
  font-size: 1rem;
  font-weight: 600;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
.scroll { 
  overflow: auto; 
  height: 600px; 
}
.scroll thead th { 
  position: sticky; 
  top: 0; 
  z-index: 100; 
  background-color: #6495ED;
}
</style>
