<template>
  <div class="py-4 container-fluid">
    <div class=" row">
      <div class="col-12">
          <div class="card" style="margin-top: -0px;"> 
            <div class="row">
              <div class="col-12">
                <div class="card-header pb-0 text-center">
                  <argon-button v-if="btnPrintShow" style="margin-left: 800px;" color="warning" size="sm" class="mb-3" variant="gradient" @click="print()"><i class="fa fa-file-pdf-o" style="margin-right: 5px;"></i> Print</argon-button>
                  <h6>SLIP GAJI - {{ moment($route.params.periode_start).locale('id').format('MMMM YYYY').toUpperCase() }}</h6>
                  <hr>
                </div>
              </div>
            </div>
            <div class="container">
              <div class="container">
                <div class="row">
                  <div class="col-6">
                    <p style="margin-top: 10px; font-size: 15px;">ID <span style="margin-left: 70px; margin-right: 15px;">:</span> {{ slip.nik }}</p>
                    <p style="margin-top: -10px; font-size: 15px;">NAME <span style="margin-left: 45px; margin-right: 15px;">:</span> {{ slip.name }}</p>
                    <p style="margin-top: -10px; font-size: 15px;">POSITION <span style="margin-left: 18px; margin-right: 15px;">:</span> {{ slip.position }}</p>
                    <p style="margin-top: -10px; font-size: 15px;">STATUS <span style="margin-left: 32px; margin-right: 15px;">:</span> {{ slip.employee_status }}</p>
                    <!-- <hr style="margin-top: 30px;"> -->
                  </div>
                  <div class="col-6 text-center">
                    <img src="../../assets/img/logo-dihi.png" width="80" />
                    <p>PT. DAEKYUNG INDAH HEAVY INDUSTRY</p>
                  </div>
                </div>

                <div class="row">
                  <div class="col-6">
                    <hr>
                    PENERIMAAN
                    <hr style="margin-top: 2px;">
                    <p style="font-size: 15px;">Basic Salary <span style="margin-left: 115px; margin-right: 10px;">:</span> 
                      {{ convertRp(Math.round(((slip.basic_day == 0) ? (slip.basic_mounth) : (slip.basic_day * slip.total_kerja_count) )) ) }}
                    </p>
                    <p style="margin-top: -10px; font-size: 15px;">Position Allowance <span style="margin-left: 65px; margin-right: 10px;">:</span>
                      {{ convertRp(slip.position_allowance) }}
                    </p>
                    <p style="margin-top: -10px; font-size: 15px;">Health Allowance <span style="margin-left: 75PX; margin-right: 10px;">:</span> 
                      {{ convertRp(slip.health_allowance) }}
                    </p>
                    <p style="margin-top: -10px; font-size: 15px;">Pension Allowance <span style="margin-left: 65PX; margin-right: 10px;">:</span> 
                      {{ convertRp((slip.pension_allowance)) }}
                    </p>
                    <p style="margin-top: -10px; font-size: 15px;">Communication Allowance <span style="margin-left: 10PX; margin-right: 10px;">:</span> 
                      {{ convertRp((slip.communication_allowance)) }}
                    </p>
                    <p style="margin-top: -10px; font-size: 15px;">Transport Allowance <span style="margin-left: 53PX; margin-right: 10px;">:</span> 
                      {{ convertRp((slip.transport_allowance)) }}
                    </p>
                    <p style="margin-top: -10px; font-size: 15px;">Meal Allowance <span style="margin-left: 86PX; margin-right: 10px;">:</span> 
                      {{ convertRp((slip.meal_allowance)) }}
                    </p>
                    <p style="margin-top: -10px; font-size: 15px;">Over Time <span style="margin-left: 123PX; margin-right: 10px;">:</span> 
                      {{ convertRp(Math.round(slip.total_ot_count * slip.basic_hour)) }}
                    </p>
                    <p style="margin-top: -10px; font-size: 15px;">OT Hours <span style="margin-left: 128PX; margin-right: 10px;">:</span> 
                      {{ convertRp(slip.total_ot_count) }}
                    </p>
                    <!-- <p style="margin-top: -10px; font-size: 15px;">Bonus <span style="margin-left: 92PX; margin-right: 10px;">:</span> -</p> -->
                  </div>
                  <div class="col-6">
                    <hr>
                    POTONGAN
                    <hr style="margin-top: 2px;">
                    <p style="font-size: 15px;">BPJS <span style="margin-left: 95px; margin-right: 10px;">:</span> 
                      {{ convertRp(Math.round(slip.basic_mounth * 0.01)) }}
                    </p>
                    <p style="margin-top: -10px; font-size: 15px;">Borrow <span style="margin-left: 75px; margin-right: 10px;">:</span> 
                      {{ convertRp(slip.borrow) }}
                    </p>
                    <p style="margin-top: -10px; font-size: 15px;">ABSEN <span style="margin-left: 78px; margin-right: 10px;">:</span> 
                      {{ convertRp(Math.round(slip.basic_mounth / 22 * slip.total_alpa_count)) }}
                    </p>
                    <p style="margin-top: -10px; font-size: 15px;">JHT <span style="margin-left: 102px; margin-right: 10px;">:</span> 
                      {{ convertRp(Math.round(slip.basic_mounth * 0.02)) }}
                    </p>
                    <p style="margin-top: -10px; font-size: 15px;">JP <span style="margin-left: 112px; margin-right: 10px;">:</span> 
                      {{ convertRp(Math.round(slip.basic_mounth * 0.01)) }}
                    </p>
                    <p style="margin-top: -10px; font-size: 15px;">TAX By Employee<span style="margin-left: 10px; margin-right: 10px;">:</span> 
                      <span>
                        {{ convertRp(taxEngine(slip.tax_status, 
                          Number(
                            slip.basic_mounth + 
                            slip.position_allowance + 
                            slip.health_allowance + 
                            slip.pension_allowance + 
                            slip.communication_allowance + 
                            slip.transport_allowance + 
                            slip.meal_allowance) +
                            Math.round(slip.total_ot_count * slip.basic_hour) ))
 }}
                      </span>
                    </p>
                    <p style="margin-top: -10px; font-size: 15px;">Iuran Kematian <span style="margin-left: 19px; margin-right: 10px;">:</span> 
                      <span v-if="slip.rel_payroll != null">
                        {{ convertRp(slip.iuran_kematian) }}
                      </span>
                    </p>
                    <p style="margin-top: -10px; font-size: 15px;">HK <span style="margin-left: 107px; margin-right: 10px;">:</span> 
                      <span v-if="slip.rel_payroll != null">
                        {{ (+slip.total_kerja_count + +slip.total_alpa_count) }} / {{  slip.total_kerja_count }}
                      </span>
                    </p>
                  </div>
                </div>
                <hr style="margin-top: 0px;">
                <div class="row font-weight-bold">
                  <div class="col-6" style="margin-top: -10px;">
                    TOTAL <span style="margin-left: 100px; margin-right: 30px;"></span> {{ convertRp(
                      Math.round(Number((((slip.basic_day == 0) ? (slip.basic_mounth) : (slip.basic_day * slip.total_kerja_count) ) )
                      + slip.position_allowance 
                      + slip.health_allowance 
                      + slip.pension_allowance 
                      + slip.communication_allowance 
                      + slip.transport_allowance 
                      + slip.meal_allowance 
                      + slip.total_ot_count * slip.basic_hour) )) }}
                    <hr style="margin-top: 2px;">
                  </div>
                  <div class="col-6" style="margin-top: -10px;">
                    <span v-if="slip.rel_payroll != null">
                      TOTAL <span style="margin-left: 100px; margin-right: 30px;"></span> {{ convertRp(Number(
                        Math.round(slip.basic_mounth * 0.01) 
                        + slip.borrow 
                        + Math.round(slip.basic_mounth / 22 * slip.total_alpa_count) 
                        + Math.round(slip.basic_mounth * 0.02) 
                        + Math.round(slip.basic_mounth * 0.01) 
                        + slip.iuran_kematian 
                        + Math.round(((slip.harian == 0) ? ((slip.bulanan / 22) * slip.total_alpa_count) : 0 ))) 
                        + (taxEngine(slip.tax_status, 
                          Number(
                            slip.basic_mounth + 
                            slip.position_allowance + 
                            slip.health_allowance + 
                            slip.pension_allowance + 
                            slip.communication_allowance + 
                            slip.transport_allowance + 
                            slip.meal_allowance) +
                            Math.round(slip.total_ot_count * slip.basic_hour) ))
                        ) }}
                    </span>
                    <hr style="margin-top: 2px;">
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 text-center">
                    <span v-if="slip.rel_payroll != null">
                      <p style="margin-top: 30px; font-weight: bold;">THP <span style="margin-left: 65px; "></span> 
                        <span style="border-bottom: 3px double;" v-if="slip.basic_day == 0">
                          {{ convertRp(
                            Math.round(Number((((slip.basic_day == 0) ? (slip.basic_mounth) : (slip.basic_day * slip.total_kerja_count) ) )
                            + slip.position_allowance 
                            + slip.health_allowance 
                            + slip.pension_allowance 
                            + slip.communication_allowance 
                            + slip.transport_allowance 
                            + slip.meal_allowance 
                            + slip.total_ot_count * slip.basic_hour) )
                            - 
                            Number(
                            Math.round(slip.basic_mounth * 0.01) 
                            + slip.borrow 
                            + Math.round(slip.basic_mounth / 22 * slip.total_alpa_count) 
                            + Math.round(slip.basic_mounth * 0.02) 
                            + Math.round(slip.basic_mounth * 0.01) 
                            + slip.iuran_kematian 
                            + Math.round(((slip.harian == 0) ? ((slip.bulanan / 22) * slip.total_alpa_count) : 0 ))
                            + (taxEngine(slip.tax_status, 
                            Number(
                              slip.basic_mounth + 
                              slip.position_allowance + 
                              slip.health_allowance + 
                              slip.pension_allowance + 
                              slip.communication_allowance + 
                              slip.transport_allowance + 
                              slip.meal_allowance) +
                              Math.round(slip.total_ot_count * slip.basic_hour) )))
                            ) }}
                        </span>
                        <span v-else>
                           {{ convertRp(
                            Math.round(Number((((slip.basic_day == 0) ? (slip.basic_mounth) : (slip.basic_day * slip.total_kerja_count) ) )
                            + slip.position_allowance 
                            + slip.health_allowance 
                            + slip.pension_allowance 
                            + slip.communication_allowance 
                            + slip.transport_allowance 
                            + slip.meal_allowance 
                            + slip.total_ot_count * slip.basic_hour) )
                            ) }}
                        </span>
                      </p>
                    </span>
                  </div>
                </div>
                    <p style="margin-left: 58px; margin-top: 70px; margin-bottom: 80px;">Payroll</p>
                    <span style="border-bottom: 3px double; margin-left: 35px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <hr style="margin-top: 30px;">
              </div>
              <div class="card-footer">
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '@/helpers/api';
import dataPayroll from '@/services/human_resource/dataPayroll.service';
var moment = require('moment');
import ArgonButton from "@/components/ArgonButton.vue";

export default {
  name: "tables",
  components: {
    ArgonButton,
  },
  data() {
    return {
      moment:moment,
      table: {
        data: []
      },
      form: {
        add: true,
        title: "Add Data",
        show: false
      },
      slip: {},
      search: '',
      btnPrintShow: true,
    };
  },
  mounted(){
    this.get();
    this.tokenApi = 'Bearer '+localStorage.getItem('token');
    window.onafterprint = function(){
      location.reload()
    }
  },
  methods: {
    get(){
      let context = this;               
      Api(context, dataPayroll.show({id_karyawan: context.$route.params.id_karyawan, periode_start: context.$route.params.periode_start, periode_end: context.$route.params.periode_end,})).onSuccess(function(response) {    
          context.slip = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.table.data = [];
          }
      })
      .call()
    },
    convertRp(bilangan) {
      if (bilangan) {
        var number_string = bilangan.toString(),
            sisa    = number_string.length % 3,
            rupiah  = number_string.substr(0, sisa),
            ribuan  = number_string.substr(sisa).match(/\d{3}/g);

        if(ribuan){
          var separator = sisa ? '.' : '';
          rupiah += separator + ribuan.join('.');
          return rupiah
        }else{
          return bilangan
        }
      }
    },
    print(){
      this.btnPrintShow = false;
      setTimeout(function () {
        window.print();
      },1000); // 5 seconds
    },
    printClose(){
      this.btnPrintShow = true
      alert('ok')
    },
    taxEngine(tax_status, gross_salary){
      if (tax_status == 'TK0' || tax_status == 'TK1' || tax_status == 'K0') {
        if (gross_salary <= 5400000) {
          // 0
          return Math.round((gross_salary * 0.00))
        }
        if (gross_salary > 5400000 && gross_salary <= 5650000) {
          // 0,25%
          return Math.round((gross_salary * 0.0025))
        }
        if (gross_salary > 5650000 && gross_salary <= 5950000) {
          // 0,5%
          return Math.round((gross_salary * 0.005))
        }
        if (gross_salary > 5950000 && gross_salary <= 6300000) {
          // 0,75%
          return Math.round((gross_salary * 0.0075))
        }
        if (gross_salary > 6300000 && gross_salary <= 6750000) {
          // 1%
          return Math.round((gross_salary * 0.01))
        }
        if (gross_salary > 6750000 && gross_salary <= 7500000) {
          // 1,25%
          return Math.round((gross_salary * 0.0125))
        }
        if (gross_salary > 7500000 && gross_salary <= 8550000) {
          // 1,5%
          return Math.round((gross_salary * 0.0150))
        }
        if (gross_salary > 8550000 && gross_salary <= 9650000) {
          // 1,75%
          return Math.round((gross_salary * 0.0175))
        }
        if (gross_salary > 9650000 && gross_salary <= 10050000) {
          // 2%
          return Math.round((gross_salary * 0.02))
        }
        if (gross_salary > 10050000 && gross_salary <= 10350000) {
          // 2,25%
          return Math.round((gross_salary * 0.0225))
        }
        if (gross_salary > 10350000 && gross_salary <= 10700000) {
          // 2,5%
          return Math.round((gross_salary * 0.025))
        }
        if (gross_salary > 10700000 && gross_salary <= 11050000) {
          // 3%
          return Math.round((gross_salary * 0.03))
        }
        if (gross_salary > 11050000 && gross_salary <= 11600000) {
          // 3,5%
          return Math.round((gross_salary * 0.035))
        }
        if (gross_salary > 11600000 && gross_salary <= 12500000) {
          // 4%
          return Math.round((gross_salary * 0.04))
        }
        if (gross_salary > 12500000 && gross_salary <= 13750000) {
          // 5%
          return Math.round((gross_salary * 0.05))
        }
        if (gross_salary > 13750000 && gross_salary <= 15100000) {
          // 6%
          return Math.round((gross_salary * 0.06))
        }
        if (gross_salary > 15100000 && gross_salary <= 16950000) {
          // 7%
          return Math.round((gross_salary * 0.07))
        }
        if (gross_salary > 16950000 && gross_salary <= 19750000) {
          // 8%
          return Math.round((gross_salary * 0.08))
        }
        if (gross_salary > 19750000 && gross_salary <= 24150000) {
          // 9%
          return Math.round((gross_salary * 0.09))
        }
        if (gross_salary > 24150000 && gross_salary <= 26450000) {
          // 10%
          return Math.round((gross_salary * 0.1))
        }
        if (gross_salary > 26450000 && gross_salary <= 28000000) {
          // 11%
          return Math.round((gross_salary * 0.11))
        }
        if (gross_salary > 28000000 && gross_salary <= 30050000) {
          // 12%
          return Math.round((gross_salary * 0.12))
        }
        if (gross_salary > 30050000 && gross_salary <= 32400000) {
          // 13%
          return Math.round((gross_salary * 0.13))
        }
        if (gross_salary > 32400000 && gross_salary <= 35400000) {
          // 14%
          return Math.round((gross_salary * 0.14))
        }
        if (gross_salary > 35400000 && gross_salary <= 39100000) {
          // 15%
          return Math.round((gross_salary * 0.15))
        }
        if (gross_salary > 39100000 && gross_salary <= 43850000) {
          // 16%
          return Math.round((gross_salary * 0.16))
        }
        if (gross_salary > 43850000 && gross_salary <= 47800000) {
          // 17%
          return Math.round((gross_salary * 0.17))
        }
        if (gross_salary > 47800000 && gross_salary <= 51400000) {
          // 18%
          return Math.round((gross_salary * 0.18))
        }
        if (gross_salary > 51400000 && gross_salary <= 56300000) {
          // 19%
          return Math.round((gross_salary * 0.19))
        }
        if (gross_salary > 56300000 && gross_salary <= 62200000) {
          // 20%
          return Math.round((gross_salary * 0.20))
        }
        if (gross_salary > 62200000 && gross_salary <= 68600000) {
          // 21%
          return Math.round((gross_salary * 0.21))
        }
        if (gross_salary > 68600000 && gross_salary <= 77500000) {
          // 22%
          return Math.round((gross_salary * 0.22))
        }
        if (gross_salary > 77500000 && gross_salary <= 89000000) {
          // 23%
          return Math.round((gross_salary * 0.23))
        }
        if (gross_salary > 89000000 && gross_salary <= 103000000) {
          // 24%
          return Math.round((gross_salary * 0.24))
        }
        if (gross_salary > 103000000 && gross_salary <= 125000000) {
          // 25%
          return Math.round((gross_salary * 0.25))
        }
        if (gross_salary > 125000000 && gross_salary <= 157000000) {
          // 26%
          return Math.round((gross_salary * 0.26))
        }
        if (gross_salary > 157000000 && gross_salary <= 206000000) {
          // 27%
          return Math.round((gross_salary * 0.27))
        }
        if (gross_salary > 206000000 && gross_salary <= 337000000) {
          // 28%
          return Math.round((gross_salary * 0.28))
        }
        if (gross_salary > 337000000 && gross_salary <= 454000000) {
          // 29%
          return Math.round((gross_salary * 0.29))
        }
        if (gross_salary > 454000000 && gross_salary <= 550000000) {
          // 30%
          return Math.round((gross_salary * 0.30))
        }
        if (gross_salary > 550000000 && gross_salary <= 695000000) {
          // 31%
          return Math.round((gross_salary * 0.31))
        }
        if (gross_salary > 695000000 && gross_salary <= 910000000) {
          // 32%
          return Math.round((gross_salary * 0.32))
        }
        if (gross_salary > 910000000 && gross_salary <= 1400000000) {
          // 33%
          return Math.round((gross_salary * 0.33))
        }
        if (gross_salary > 1400000000) {
          // 34%
          return Math.round((gross_salary * 0.34))
        }
      }
      if (tax_status == 'TK2' || tax_status == 'TK3' || tax_status == 'K1' || tax_status == 'K2') {
        if (gross_salary <= 6200000) {
          // 0
          return Math.round((gross_salary * 0.00))
        }
        if (gross_salary > 6200000 && gross_salary <= 6500000) {
          // 0,25%
          return Math.round((gross_salary * 0.0025))
        }
        if (gross_salary > 6500000 && gross_salary <= 6850000) {
          // 0,5%
          return Math.round((gross_salary * 0.005))
        }
        if (gross_salary > 6850000 && gross_salary <= 7300000) {
          // 0,75%
          return Math.round((gross_salary * 0.0075))
        }
        if (gross_salary > 7300000 && gross_salary <= 9200000) {
          // 1%
          return Math.round((gross_salary * 0.01))
        }
        if (gross_salary > 9200000 && gross_salary <= 10750000) {
          // 1,5%
          return Math.round((gross_salary * 0.0150))
        }
        if (gross_salary > 10750000 && gross_salary <= 11250000) {
          // 2%
          return Math.round((gross_salary * 0.02))
        }
        if (gross_salary > 11250000 && gross_salary <= 11600000) {
          // 2,5%
          return Math.round((gross_salary * 0.025))
        }
        if (gross_salary > 11600000 && gross_salary <= 12600000) {
          // 3%
          return Math.round((gross_salary * 0.03))
        }
        if (gross_salary > 12600000 && gross_salary <= 13600000) {
          // 4%
          return Math.round((gross_salary * 0.04))
        }
        if (gross_salary > 13600000 && gross_salary <= 14950000) {
          // 5%
          return Math.round((gross_salary * 0.05))
        }
        if (gross_salary > 14950000 && gross_salary <= 16400000) {
          // 6%
          return Math.round((gross_salary * 0.06))
        }
        if (gross_salary > 16400000 && gross_salary <= 18450000) {
          // 7%
          return Math.round((gross_salary * 0.07))
        }
        if (gross_salary > 18450000 && gross_salary <= 21850000) {
          // 8%
          return Math.round((gross_salary * 0.08))
        }
        if (gross_salary > 21850000 && gross_salary <= 26000000) {
          // 9%
          return Math.round((gross_salary * 0.09))
        }
        if (gross_salary > 26000000 && gross_salary <= 27700000) {
          // 10%
          return Math.round((gross_salary * 0.1))
        }
        if (gross_salary > 27700000 && gross_salary <= 29350000) {
          // 11%
          return Math.round((gross_salary * 0.11))
        }
        if (gross_salary > 29350000 && gross_salary <= 31450000) {
          // 12%
          return Math.round((gross_salary * 0.12))
        }
        if (gross_salary > 31450000 && gross_salary <= 33950000) {
          // 13%
          return Math.round((gross_salary * 0.13))
        }
        if (gross_salary > 33950000 && gross_salary <= 37100000) {
          // 14%
          return Math.round((gross_salary * 0.14))
        }
        if (gross_salary > 37100000 && gross_salary <= 41100000) {
          // 15%
          return Math.round((gross_salary * 0.15))
        }
        if (gross_salary > 41100000 && gross_salary <= 45800000) {
          // 16%
          return Math.round((gross_salary * 0.16))
        }
        if (gross_salary > 45800000 && gross_salary <= 49500000) {
          // 17%
          return Math.round((gross_salary * 0.17))
        }
        if (gross_salary > 49500000 && gross_salary <= 53800000) {
          // 18%
          return Math.round((gross_salary * 0.18))
        }
        if (gross_salary > 53800000 && gross_salary <= 58500000) {
          // 19%
          return Math.round((gross_salary * 0.19))
        }
        if (gross_salary > 58500000 && gross_salary <= 64000000) {
          // 20%
          return Math.round((gross_salary * 0.20))
        }
        if (gross_salary > 64000000 && gross_salary <= 71000000) {
          // 21%
          return Math.round((gross_salary * 0.21))
        }
        if (gross_salary > 71000000 && gross_salary <= 80000000) {
          // 22%
          return Math.round((gross_salary * 0.22))
        }
        if (gross_salary > 80000000 && gross_salary <= 93000000) {
          // 23%
          return Math.round((gross_salary * 0.23))
        }
        if (gross_salary > 93000000 && gross_salary <= 109000000) {
          // 24%
          return Math.round((gross_salary * 0.24))
        }
        if (gross_salary > 109000000 && gross_salary <= 129000000) {
          // 25%
          return Math.round((gross_salary * 0.25))
        }
        if (gross_salary > 129000000 && gross_salary <= 163000000) {
          // 26%
          return Math.round((gross_salary * 0.26))
        }
        if (gross_salary > 163000000 && gross_salary <= 211000000) {
          // 27%
          return Math.round((gross_salary * 0.27))
        }
        if (gross_salary > 211000000 && gross_salary <= 374000000) {
          // 28%
          return Math.round((gross_salary * 0.28))
        }
        if (gross_salary > 374000000 && gross_salary <= 459000000) {
          // 29%
          return Math.round((gross_salary * 0.29))
        }
        if (gross_salary > 459000000 && gross_salary <= 555000000) {
          // 30%
          return Math.round((gross_salary * 0.30))
        }
        if (gross_salary > 555000000 && gross_salary <= 704000000) {
          // 31%
          return Math.round((gross_salary * 0.31))
        }
        if (gross_salary > 704000000 && gross_salary <= 957000000) {
          // 32%
          return Math.round((gross_salary * 0.32))
        }
        if (gross_salary > 957000000 && gross_salary <= 1405000000) {
          // 33%
          return Math.round((gross_salary * 0.33))
        }
        if (gross_salary > 1405000000) {
          // 34%
          return Math.round((gross_salary * 0.34))
        }
      }
      if (tax_status == 'K3') {
        if (gross_salary <= 6600000) {
          // 0
          return Math.round((gross_salary * 0.00))
        }
        if (gross_salary > 6600000 && gross_salary <= 6950000) {
          // 0,25%
          return Math.round((gross_salary * 0.0025))
        }
        if (gross_salary > 6950000 && gross_salary <= 7350000) {
          // 0,5%
          return Math.round((gross_salary * 0.005))
        }
        if (gross_salary > 7350000 && gross_salary <= 7800000) {
          // 0,75%
          return Math.round((gross_salary * 0.0075))
        }
        if (gross_salary > 7800000 && gross_salary <= 8850000) {
          // 1%
          return Math.round((gross_salary * 0.01))
        }
        if (gross_salary > 8850000 && gross_salary <= 9800000) {
          // 1,25%
          return Math.round((gross_salary * 0.0125))
        }
        if (gross_salary > 9800000 && gross_salary <= 10950000) {
          // 1,5%
          return Math.round((gross_salary * 0.0150))
        }
        if (gross_salary > 10950000 && gross_salary <= 11200000) {
          // 1,75%
          return Math.round((gross_salary * 0.0175))
        }
        if (gross_salary > 11200000 && gross_salary <= 12050000) {
          // 2%
          return Math.round((gross_salary * 0.02))
        }
        if (gross_salary > 12050000 && gross_salary <= 12950000) {
          // 3%
          return Math.round((gross_salary * 0.03))
        }
        if (gross_salary > 12950000 && gross_salary <= 14150000) {
          // 4%
          return Math.round((gross_salary * 0.04))
        }
        if (gross_salary > 14150000 && gross_salary <= 15550000) {
          // 5%
          return Math.round((gross_salary * 0.05))
        }
        if (gross_salary > 15550000 && gross_salary <= 17050000) {
          // 6%
          return Math.round((gross_salary * 0.06))
        }
        if (gross_salary > 17050000 && gross_salary <= 19500000) {
          // 7%
          return Math.round((gross_salary * 0.07))
        }
        if (gross_salary > 19500000 && gross_salary <= 22700000) {
          // 8%
          return Math.round((gross_salary * 0.08))
        }
        if (gross_salary > 22700000 && gross_salary <= 26600000) {
          // 9%
          return Math.round((gross_salary * 0.09))
        }
        if (gross_salary > 26600000 && gross_salary <= 28100000) {
          // 10%
          return Math.round((gross_salary * 0.1))
        }
        if (gross_salary > 28100000 && gross_salary <= 30100000) {
          // 11%
          return Math.round((gross_salary * 0.11))
        }
        if (gross_salary > 30100000 && gross_salary <= 32600000) {
          // 12%
          return Math.round((gross_salary * 0.12))
        }
        if (gross_salary > 32600000 && gross_salary <= 35400000) {
          // 13%
          return Math.round((gross_salary * 0.13))
        }
        if (gross_salary > 35400000 && gross_salary <= 38900000) {
          // 14%
          return Math.round((gross_salary * 0.14))
        }
        if (gross_salary > 38900000 && gross_salary <= 43000000) {
          // 15%
          return Math.round((gross_salary * 0.15))
        }
        if (gross_salary > 43000000 && gross_salary <= 47400000) {
          // 16%
          return Math.round((gross_salary * 0.16))
        }
        if (gross_salary > 47400000 && gross_salary <= 51200000) {
          // 17%
          return Math.round((gross_salary * 0.17))
        }
        if (gross_salary > 51200000 && gross_salary <= 55800000) {
          // 18%
          return Math.round((gross_salary * 0.18))
        }
        if (gross_salary > 55800000 && gross_salary <= 60400000) {
          // 19%
          return Math.round((gross_salary * 0.19))
        }
        if (gross_salary > 60400000 && gross_salary <= 66700000) {
          // 20%
          return Math.round((gross_salary * 0.20))
        }
        if (gross_salary > 66700000 && gross_salary <= 74500000) {
          // 21%
          return Math.round((gross_salary * 0.21))
        }
        if (gross_salary > 74500000 && gross_salary <= 83200000) {
          // 22%
          return Math.round((gross_salary * 0.22))
        }
        if (gross_salary > 83200000 && gross_salary <= 95600000) {
          // 23%
          return Math.round((gross_salary * 0.23))
        }
        if (gross_salary > 95600000 && gross_salary <= 110000000) {
          // 24%
          return Math.round((gross_salary * 0.24))
        }
        if (gross_salary > 110000000 && gross_salary <= 134000000) {
          // 25%
          return Math.round((gross_salary * 0.25))
        }
        if (gross_salary > 134000000 && gross_salary <= 169000000) {
          // 26%
          return Math.round((gross_salary * 0.26))
        }
        if (gross_salary > 169000000 && gross_salary <= 221000000) {
          // 27%
          return Math.round((gross_salary * 0.27))
        }
        if (gross_salary > 221000000 && gross_salary <= 390000000) {
          // 28%
          return Math.round((gross_salary * 0.28))
        }
        if (gross_salary > 390000000 && gross_salary <= 463000000) {
          // 29%
          return Math.round((gross_salary * 0.29))
        }
        if (gross_salary > 463000000 && gross_salary <= 561000000) {
          // 30%
          return Math.round((gross_salary * 0.30))
        }
        if (gross_salary > 561000000 && gross_salary <= 709000000) {
          // 31%
          return Math.round((gross_salary * 0.31))
        }
        if (gross_salary > 709000000 && gross_salary <= 965000000) {
          // 32%
          return Math.round((gross_salary * 0.32))
        }
        if (gross_salary > 965000000 && gross_salary <= 1419000000) {
          // 33%
          return Math.round((gross_salary * 0.33))
        }
        if (gross_salary > 1419000000) {
          // 34%
          return Math.round((gross_salary * 0.34))
        }
      }
    }
  },
  beforeMount() {
    // this.$store.state.imageLayout = "profile-overview";
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = false;
    // this.$store.state.hideConfigButton = true;
    // body.classList.add("profile-overview");
  },
  beforeUnmount() {
    // this.$store.state.isAbsolute = false;
    // this.$store.state.imageLayout = "default";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = false;
    // this.$store.state.hideConfigButton = false;
    // body.classList.remove("profile-overview");
  }
};
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 500px;
  margin-top: auto;
  margin-bottom: auto;
}
.modal__title {
  font-size: 1rem;
  font-weight: 600;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
.scroll { 
  overflow: auto; 
  height: 500px; 
}
.scroll thead th { 
  position: sticky; 
  top: 0; 
  z-index: 100; 
  background-color: #F0F8FF;
}
</style>
