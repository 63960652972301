import Service from '@/helpers/service'

export default {

    index(params) {
        return Service().get('material-group-pr/index', {params});
    },
    getMaterialGroupPR(params) {
        return Service().get('material-group-pr/get-material-group-pr', {params});
    },
    show(id) {
        return Service().get('material-group-pr/show/'+id);
    },
    create(params) {
        return Service().post('material-group-pr/create', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    update(id, params) {
        return Service().post('material-group-pr/update/'+id, params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    delete(id) {
        return Service().post('material-group-pr/delete/'+id);
    },
}