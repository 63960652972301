import Service from '@/helpers/service'

export default {

    index(params) {
        return Service().get('medical-reimbushment-glasses/index', {params});
    },
    getActivityByUser(params) {
        return Service().get('medical-reimbushment-glasses/index-by-user', {params});
    },
    getUom(params) {
        return Service().get('medical-reimbushment-glasses/get-medical-reimbushment-glasses', {params});
    },
    show(id) {
        return Service().get('medical-reimbushment-glasses/show/'+id);
    },
    create(params) {
        return Service().post('medical-reimbushment-glasses/create', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    update(id, params) {
        return Service().post('medical-reimbushment-glasses/update/'+id, params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    delete(id) {
        return Service().post('medical-reimbushment-glasses/delete/'+id);
    },
    closed(id) {
        return Service().post('medical-reimbushment-glasses/closed/'+id);
    },
}