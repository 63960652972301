import Service from '@/helpers/service'

export default {

    index(params) {
        return Service().get('employee/index', {params});
    },
    getEmployee(params) {
        return Service().get('employee/get-employee', {params});
    },
    show(id) {
        return Service().get('employee/show/'+id);
    },
    create(params) {
        return Service().post('employee/create', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    update(id, params) {
        return Service().post('employee/update/'+id, params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    delete(id) {
        return Service().post('employee/delete/'+id);
    },
    getPicWarehouse(params) {
        return Service().get('employee/get-pic-warehouse', {params});
    },
    getLeaderWhereDept(params) {
        return Service().get('employee/get-leader-where-dept', {params});
    },
}