import Service from '@/helpers/service'

export default {

    index(params) {
        return Service().get('approval-cuti/index', {params});
    },
    show(id) {
        return Service().get('approval-cuti/show/'+id);
    },
    create(params) {
        return Service().post('approval-cuti/create', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    update(id, params) {
        return Service().post('approval-cuti/update/'+id, params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    updateCuti(id, params) {
        return Service().post('approval-cuti/update-cuti/'+id, params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    delete(id) {
        return Service().post('approval-cuti/delete/'+id);
    },
}