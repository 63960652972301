<template>
  <div class="py-4 container-fluid">
    <a class="btn btn-sm btn-primary" style="margin-right: 10px;" :href="apiUrl+'export-excel/item-list?code='+search.code+'&name='+search.name+'&description='+search.description+'&category='+search.category+'&uom='+search.uom+''" target="_BLANK"><i class="fa fa-download fa-sm"></i> Export</a>
    <argon-button color="info" size="sm" class="mb-3" variant="gradient" @click="modalImport()"><i class="fa fa-upload fa-sm"></i> Import</argon-button>
    <a class="btn btn-sm btn-warning" style="margin-left: 13px;" :href="apiUrl+'print-pdf/item-list-qr-code?code='+search.code+'&name='+search.name+'&description='+search.description+'&category='+search.category+'&uom='+search.uom+''" target="_BLANK"><i class="fa fa-qrcode fa-sm"></i> Print QR Code</a>
    <div class=" row">
      <div class="col-12">
          <div class="card"> 
            <div class="row">
              <div class="col-4">
                <div class="card-header pb-0">
                  <h6>Item List</h6>
                </div>
              </div>
              <div class="col-4">
              </div>
              <div class="col-4 float-right">
                <argon-button
                  style="margin-right: 10px; margin-left: 60px;"
                  class="mt-4"
                  variant="gradient"
                  color="secondary"
                  size="sm"
                  @click="filter()"
                ><i class="fa fa-filter fa-sm" aria-hidden="true"></i> Filter</argon-button>
                <argon-button
                  class="mt-4"
                  variant="gradient"
                  color="success"
                  size="sm"
                  @click="create()"
                ><i class="fa fa-plus fa-sm" aria-hidden="true"></i> Add New</argon-button>
              </div>
            </div>
            
            <div class="card-body px-0 pt-0 pb-2 mt-4">
              <div class="table-responsive p-0 scroll">
                <table class=" align-items-center mb-0" style="border-collapse: collapse;">
                  <thead>
                    <tr style="background-color: #F0F8FF;">
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder" style="width:70px;">No</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder" style="width:100px;">Image</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder" style="width:100px;">Code</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder" style="width:200px;">Name</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder" style="width:300px;">Description</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder" style="width:100px;">Category</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder" style="width:100px;">UOM</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder" style="width:100px;">Actual Stock</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder" style="width:100px;">Min Stock</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder" style="width:100px;">Reorder Time Days</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder" style="width:100px;">Reorder Qty</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder" style="width:150px;">Average Unit Price</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder" style="width:100px;">Factory Code</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder" style="width:100px;">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, i) in table.data" :key="i" style="border-bottom: 1pt solid #DCDCDC; padding: 5px;">
                      <td class="align-middle text-center text-sm" >
                        <span class="text-secondary text-xs font-weight-bold">{{ i + 1 }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <img
                          :src="storageUrl+'/image_warehouse/'+row.image"
                          class="avatar avatar-lg me-1"
                          alt="user1"
                        />
                      </td>
                      <td class="align-middle text-center text-sm">
                        <router-link :to="/items-detail/+row.code">
                          <span class="badge badge-sm bg-gradient-primary">{{ row.code }}</span>
                        </router-link>
                      </td>
                      <td class="align-middle text-left text-sm" style="border-bottom: 1px;">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.name }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.description }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.category }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.uom_code }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.total_qty_item_count - row.total_qty_item_on_loan_count }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.min_stock }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.reorder_time_days }} Days</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.reorder_qty }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold"> Rp. {{ convertRp(row.average_price_count) }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold"> {{ row.factory_code }}</span>
                      </td>
                      <td class="align-middle text-center">
                        <i class="fas fa-edit fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="edit(row.id)" title="Edit"></i>
                        <i class="fa fa-trash fa-sm" aria-hidden="true" title="Hapus" style="cursor: pointer; margin-right: 20px;" @click="remove(row.id)"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer">
              <div class="float-right">
                <base-pagination :page-count="pagination.page_count" :value="pagination.page" @input="changePage" :total="pagination.page_count" class="justify-content-end"></base-pagination>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>

  <!-- =======  MODAL ADD DATA ======= -->
  <div class="container">
    <vue-final-modal v-model="form.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{form.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="form.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Name <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Name" v-model="inventoryListData.name">
        <label for="example-text-input" class="form-control-label mt-3">Description <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Description" v-model="inventoryListData.description">
        <label for="example-text-input" class="form-control-label mt-3">Category <span style="color: red;">*</span></label>
        <select class="form-select" aria-label="Default select example" v-model="inventoryListData.category" :disabled="form.title != 'Add Data'">
          <option selected>Category</option>
          <option value="Machine">Machine</option>
          <option value="Tools">Tools</option>
          <option value="Consumable">Consumable</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Uom <span style="color: red;">*</span></label>
        <multiselect v-model="inventoryListData.uom" :options="tableUom.data" :multiple="false" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Select Uom" label="name" track-by="name" :preselect-first="true" @keyup="getUom">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
        <label for="example-text-input" class="form-control-label mt-3">Warehouse Location <span style="color: red;">*</span></label>
        <multiselect v-model="inventoryListData.warehouse_location" :options="tableWarehouseLocation.data" :multiple="false" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Select Warehouse Location" label="name" track-by="name" :preselect-first="true" @keyup="getWarehouseLocation" :disabled="form.title != 'Add Data'">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
        <label for="example-text-input" class="form-control-label mt-3">Min Stock <span style="color: red;">*</span></label>
        <input type="number" class="form-control" placeholder="Min Stock" v-model="inventoryListData.min_stock">
        <label for="example-text-input" class="form-control-label mt-3">Reorder Time Days <span style="color: red;">*</span></label>
        <input type="number" class="form-control" placeholder="Reorder Time Days" v-model="inventoryListData.reorder_time_days">
        <label for="example-text-input" class="form-control-label mt-3">Reorder Qty <span style="color: red;">*</span></label>
        <input type="number" class="form-control" placeholder="Reorder Qty" v-model="inventoryListData.reorder_qty">
        <label for="example-text-input" class="form-control-label mt-3">Image <span style="color: red;" v-if="form.title == 'Add Data'">*</span></label>
        <input type="file" ref="fileupload" class="form-control" placeholder="Image" @change="filesChange">
        <label for="example-text-input" class="form-control-label mt-3">Factory Code </label>
        <input type="text" class="form-control" placeholder="Factory Code" v-model="inventoryListData.factory_code">
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="form.show = true">Close</argon-button>
        </div>
        <div class="col-1">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="save()">Save</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

   <!-- =======  MODAL FILTER ======= -->
  <div class="container">
    <vue-final-modal v-model="formFilter.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formFilter.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formFilter.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Code</label>
        <input type="text" class="form-control" placeholder="Code" v-model="search.code" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Name</label>
        <input type="text" class="form-control" placeholder="Name" v-model="search.name" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Description</label>
        <input type="text" class="form-control" placeholder="Description" v-model="search.description" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Category </label>
        <select class="form-select" aria-label="Default select example" v-model="search.category" :disabled="form.title != 'Add Data'">
          <option selected>Category</option>
          <option value="Machine">Machine</option>
          <option value="Tools">Tools</option>
          <option value="Consumable">Consumable</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">UOM</label>
        <input type="text" class="form-control" placeholder="UOM" v-model="search.uom" v-on:keyup.enter="get()">
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="search = {}">Clear</argon-button>
        </div>
        <div class="col-1">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="get()">Filter</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

  <!-- =======  MODAL IMPORT ======= -->
  <div class="container">
    <vue-final-modal v-model="formImport.show" classes="modal-container" content-class="modal-content" :z-index="10000">
    <!-- header -->
    <div class="row">
      <div class="col-11 float-left">
        <span class="modal__title">{{formImport.title}}</span>
      </div>
      <div class="col-1 float-right">
        <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formImport.show = false"></i>
      </div>
    </div><hr>
    <!-- end header -->
    <div class="modal__content container">
      <div>
        <label for="example-text-input" class="form-control-label mt-3">Upload File <span style="color: red;">*</span></label>
        <input type="file" class="form-control" placeholder="Upload File" @change="filesChangeImport" style="margin-bottom: 20px;">
        <small>Download Template<a :href="storageUrl+'/template_import/Template Import Item List.xlsx'" style="color: blue;"> Import Item List</a></small>
        <div class="mt-2" v-if="tabelError.data.length !== 0 ">
          <table>
            <thead>
              <slot name="columns">
                <tr style="background-color: #F0F8FF;">
                  <th style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC; width: 50px;">No</th>
                  <th style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC; width: 50px;">Column</th>
                  <th style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC; width: 150px;">Error</th>
                  <th style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC; width: 50px;">Row</th>
                  <th style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC; width: 150px;">Values</th>
                </tr>
              </slot>
            </thead>
            <tbody>
              <tr v-for="(row, i) in tabelError.data" :key="i">
                <td style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC;">{{ i + 1 }}</td>
                <td style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC;">{{ row.attribute }}</td>
                <td style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC;">{{ row.errors }}</td>
                <td style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC;">{{ row.row }}</td>
                <td style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC;">{{ row.values }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- footer -->
    <div class="row float-right mt-5">
      <div class="col-6"> 
      </div>
      <div class="col-2" style="margin-right: 20px;">
        <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="formImport.show = false">Close</argon-button>
      </div>
      <div class="col-1">
        <button type="primary" class="btn btn-sm btn-info btn-fill" @click="importData()" :disabled="onLoading">
          <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
          <span v-else>
              <span>Import</span>
          </span>
        </button>
        <!-- <argon-button variant="gradient" color="success" size="sm" width="1" @click="get(), formImport.show = false">Import</argon-button> -->
      </div>
    </div>
    <!-- end footer -->
    </vue-final-modal>
  </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
import { VueFinalModal } from 'vue-final-modal'
import BasePagination from "@/components/BasePagination";
import Api from '@/helpers/api';
import config from '@/configs/config';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css';
import inventoryList from '@/services/inventoryList.service';
import unitOfMeasurement from '@/services/unitOfMeasurement.service';
import warehouseLocation from '@/services/warehouseLocation.service';

export default {
  name: "tables",
  components: {
    ArgonButton,
    VueFinalModal,
    Multiselect,
    BasePagination,
  },
  data() {
    return {
      pagination: {
        page_count: '',
        page: 1,
      },
      table: {
        data: []
      },
      form: {
        add: true,
        title: "Add Data",
        show: false
      },
      formImport: {
        add: true,
        title: "Import Item List",
        show: false
      },
      formFilter: {
        add: true,
        title: "Filter",
        show: false
      },
      dataImport: '',
      onLoading: false,
      tabelError: {
        data: []
      },
      storageUrl : config.storageUrl,
      inventoryListData: {},
      search: {
        code: '',
        name: '',
        description: '',
        category: '',
        uom: '',
      },
      searchMenu: '',
      apiUrl :config.apiUrl,
      tableUom: {
        data: []
      },
      tableWarehouseLocation: {
        data: []
      },

    };
  },
  mounted(){
    this.get();
    this.tokenApi = 'Bearer '+localStorage.getItem('token');
  },
  methods: {
    get(param){
      let context = this;               
      Api(context, inventoryList.index({code: this.search.code, name: this.search.name, category: this.search.category, description: this.search.description, uom: this.search.uom, page: context.pagination.page})).onSuccess(function(response) {    
          context.table.data            = response.data.data.data.data;
          context.pagination.page_count = response.data.data.data.last_page

          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.table.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.formFilter.show  = false;
      })
      .call()
    },
    getUom(param){
      let context = this;        
      Api(context, unitOfMeasurement.getUom({search: param.target.value})).onSuccess(function(response) {    
          context.tableUom.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableUom.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    getWarehouseLocation(param){
      let context = this;        
      Api(context, warehouseLocation.getWarehouseLocation({search: param.target.value})).onSuccess(function(response) {    
          context.tableWarehouseLocation.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableWarehouseLocation.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    filter() {
      this.formFilter.add   = true;
      this.formFilter.show  = true;
      this.formFilter.title = "Filter";
      this.onLoading        = false;
      this.pagination.page  = 1 ;
    },
    create() {
      this.form.add          = true;
      this.form.show         = true;
      this.form.title        = "Add Data";
      this.inventoryListData = {};
    },
    edit(id) {
      let context = this;               
      Api(context, inventoryList.show(id)).onSuccess(function(response) {
          context.inventoryListData                    = response.data.data.data;
          context.inventoryListData.uom                = response.data.data.dataUom
          context.inventoryListData.warehouse_location = response.data.data.dataWL
          context.form.show                            = true;
          context.form.title                           = 'Edit Data'; 
      })
      .call()        
      context.$refs.fileupload.value = null;
    },
    filesChange(e) {
      this.inventoryListData.image = e.target.files[0];
    },
    save(){
      let api      = null;
      let context  = this;
      let formData = new FormData();

      if (context.inventoryListData.name != undefined && context.inventoryListData.description != undefined && context.inventoryListData.category != undefined && context.inventoryListData.uom != undefined && context.inventoryListData.warehouse_location != undefined && context.inventoryListData.min_stock != undefined && context.inventoryListData.reorder_time_days != undefined && context.inventoryListData.reorder_qty != undefined ) {
        formData.append('name', context.inventoryListData.name);
        formData.append('description', context.inventoryListData.description);
        formData.append('category', context.inventoryListData.category);
        formData.append('uom', JSON.stringify(context.inventoryListData.uom));
        formData.append('warehouse_location', JSON.stringify(context.inventoryListData.warehouse_location));
        formData.append('min_stock', context.inventoryListData.min_stock);
        formData.append('reorder_time_days', context.inventoryListData.reorder_time_days);
        formData.append('reorder_qty', context.inventoryListData.reorder_qty);
        formData.append('image', (context.inventoryListData.image == undefined) ? '' : context.inventoryListData.image);
        formData.append('factory_code', (context.inventoryListData.factory_code == undefined) ? '' : context.inventoryListData.factory_code);
      }else{
        return alert('The Red Star Field is Mandatory')
      }

      if (context.form.title == 'Add Data') {
          api = Api(context, inventoryList.create(formData));
      } else {
          api = Api(context, inventoryList.update(this.inventoryListData.id, formData));
      }
      // eslint-disable-next-line no-unused-vars
      api.onSuccess(function(response) {
        context.get();
        context.form.show = false;
        context.notify((context.form === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      // eslint-disable-next-line no-unused-vars
      }).onError(function(error) { 
        context.notify((context.form != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
      })
      .call();
    },
    remove(id) {
      var r = confirm("Are you sure you want to delete this data ?");
      if (r == true) {
        let context = this;

        Api(context, inventoryList.delete(id)).onSuccess(function(response) {
            context.get();
            context.notify('Data Successfully Deleted', 'success')
        }).call();
      }
    },
    modalImport(){
      this.formImport.show  = true;
      this.formImport.title = "Import Item List";
      this.tabelError.data  = [];
    },
    filesChangeImport(e) {
        this.dataImport = e.target.files[0];
    },
    importData(){
      let api = null;
      let context = this;
      let formData = new FormData();
      this.onLoading = true;

      if (this.dataImport != undefined) {
        formData.append('import_data', this.dataImport);
      }else{
        return alert('File Import Not Found')
      }

      api = Api(context, inventoryList.import(formData));
      api.onSuccess(function(response) {
          context.onLoading = false;
          context.get();
          context.formImport.show = false;
          context.notify('Import Data Success', 'success')
      }).onError(function(error) {      
          context.tabelError.data = error.response.data.data;              
          context.notify('Import Data Failed', 'error')
          context.onLoading = false;
      }).onFinish(function() {  
      })
      .call();
    },
    changePage(page){
      this.pagination.page = page;
      this.get();
    },
    convertRp(bilangan) {
      if (bilangan) {
        var number_string = bilangan.toString(),
            sisa    = number_string.length % 3,
            rupiah  = number_string.substr(0, sisa),
            ribuan  = number_string.substr(sisa).match(/\d{3}/g);

        if(ribuan){
          var separator = sisa ? '.' : '';
          rupiah += separator + ribuan.join('.');
          return rupiah
        }else{
          return bilangan
        }
      }
    },
    notify(message, type) {
      if (type == 'success') {
        toast.success(message, {
          autoClose: 2000,
        }); // ToastOptions
      }else{
        toast.error(message, {
          autoClose: 2000,
        }); // ToastOptions
      }
    },

  }
};
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 500px;
  margin-top: auto;
  margin-bottom: auto;
}
.modal__title {
  font-size: 1rem;
  font-weight: 600;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
.scroll { 
  overflow: auto; 
  height: 600px; 
}
.scroll thead th { 
  position: sticky; 
  top: 0; 
  z-index: 100; 
  background-color: #F0F8FF;
  padding-top: 5px;
  padding-bottom: 5px;
}

tr:hover {background-color: #D3D3D3;}

</style>
