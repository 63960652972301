<template>
  <div class="py-4 container-fluid">
    <a :style="search.periode_start == '' ? 'margin-right: 10px; pointer-events: none; cursor: default;' : 'margin-right: 10px;'" style="margin-right: 10px;" :href="apiUrl+'export-excel/absen?periode_start='+search.periode_start+'&periode_end='+search.periode_end+'&nama='+search.nama+''" target="_BLANK"><argon-button :disabled="search.periode_start == ''" color="primary" size="sm" class="mb-3" variant="gradient"><i class="fa fa-file-excel-o" style="margin-right: 5px;"></i> Export Excel</argon-button></a>
    <!-- <argon-button color="warning" size="sm" class="mb-3" variant="gradient"><i class="fa fa-upload fa-sm"></i> Import</argon-button> -->
    <div class=" row">
      <div class="col-12">
          <div class="card"> 
            <div class="row">
              <div class="col-2">
                <div class="card-header pb-0">
                  <h6>Data Absensi</h6>
                </div>
              </div>
              <div class="col-5">
                <p style="margin-top: 25px; font-weight: bold; padding-left: 85px;">PERIODE {{ moment(search.periode_start).locale('id').format('D MMMM').toUpperCase() }} - {{ moment(search.periode_end).locale('id').format('D MMMM YYYY').toUpperCase() }}</p>
              </div>
              <div class="col-5 float-right">
                <argon-button
                  v-if="search.periode_start != ''"
                  class="mt-4"
                  variant="gradient"
                  color="danger"
                  size="sm"
                  @click="setLibur()"
                ><i class="fa fa-calendar fa-sm" aria-hidden="true"></i> Set Libur</argon-button>
                <argon-button
                  style="margin-right: 5px; margin-left: 5px;"
                  class="mt-4"
                  variant="gradient"
                  color="success"
                  size="sm"
                  @click="create()"
                ><i class="fa fa-plus fa-sm" aria-hidden="true"></i> Add Periode</argon-button>
                <argon-button
                  class="mt-4"
                  variant="gradient"
                  color="secondary"
                  size="sm"
                  @click="filter()"
                ><i class="fa fa-filter fa-sm" aria-hidden="true"></i> Filter</argon-button>
              </div>
            </div>
            
            <div class="card-body px-0 pt-0 pb-2 mt-4">
              <div class="table-responsive p-0 scroll">
                <table class="table align-items-center mb-0">
                  <thead>
                    <tr style="color: white;">
                      <th class="text-uppercase text-xxs font-weight-bolder ">Nama Karyawan</th>
                      <th></th>
                      <th v-for="(row, i) in tableAbsen.data" :key="i" class="text-uppercase text-xxs font-weight-bolder ps-2 text-center" :style="(moment(row.date).locale('id').format('ddd') == 'Sab' || moment(row.date).locale('id').format('ddd') == 'Min') ? 'background-color: red;' : ''">
                        <p class="text-xs font-weight-bold mb-0 text-center">{{moment(row.date).locale('id').format('ddd')}}</p>
                        <p class="text-xs font-weight-bold mb-0 text-center">{{moment(row.date).locale('id').format('DD')}}</p>
                      </th>
                      <th class="text-uppercase text-xxs font-weight-bolder ps-2 text-center" style="background-color: #FF8C00;"><p class="text-xs font-weight-bold mb-0 text-center">S</p></th>
                      <th class="text-uppercase text-xxs font-weight-bolder ps-2 text-center" style="background-color: #FF8C00;"><p class="text-xs font-weight-bold mb-0 text-center">I</p></th>
                      <th class="text-uppercase text-xxs font-weight-bolder ps-2 text-center" style="background-color: #FF8C00;"><p class="text-xs font-weight-bold mb-0 text-center">A</p></th>
                      <th class="text-uppercase text-xxs font-weight-bolder ps-2 text-center" style="background-color: #FF8C00;"><p class="text-xs font-weight-bold mb-0 text-center">C</p></th>
                      <th class="text-uppercase text-xxs font-weight-bolder ps-2 text-center" style="background-color: #FF8C00;"><p class="text-xs font-weight-bold mb-0 text-center">HK</p></th>
                      <th class="text-uppercase text-xxs font-weight-bolder ps-2 text-center" style="background-color: #FF8C00;"><p class="text-xs font-weight-bold mb-0 text-center">OT</p></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, i) in absenKaryawan.data" :key="i" @click="setBg(i)" :style="(backgroundRed == i) ? 'background-color: #5F9EA0; cursor: pointer;' : 'cursor: pointer;' ">
                      <td>
                        <div class="d-flex px-2 py-1">
                          <div class="d-flex flex-column justify-content-center">
                            <h6 class="mb-0 text-sm">{{ row.name }}</h6>
                            <p class="text-xs text-secondary mb-0">{{ row.position}}</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <p class="text-xs font-weight-bold mb-0 text-center" style="background-color: #FFFACD;">HK</p>
                        <p class="text-xs font-weight-bold mb-0 text-center" style="background-color: #7FFF00;">OT</p>
                      </td>
                      <td v-for="(value, j) in row.rel_absen_karyawan" :key="j">
                        <p class="text-xs font-weight-bold mb-0 text-center" :style="(value.type_hk == '0') ? 'background-color: #FF6347;' : (value.type_hk == '') ? 'background-color: #FF6347;' : (value.type_hk == 'S') ? 'background-color: #8FBC8F;' : (value.type_hk == 'L') ? 'background-color: #7FFF00;' : (value.type_hk == 'C') ? 'background-color: yellow;' :'background-color: #FFFACD;'">
                          <input style="border: 1px solid transparent; background: transparent; text-align: center;" size="2" v-model="value.type_hk" @change="updateAbsen(value.id, value.type_hk, 'HK', row.position, moment(value.date).locale('id').format('ddd'))">
                          <!-- {{moment(value.date).locale('id').format('ddd')}} -->
                        </p>
                        <p class="text-xs font-weight-bold mb-0 text-center" :style="(value.type_ot != '0.00') ? 'background-color: #7FFF00;' : (value.type_ot == '')">
                          <input style="border: 1px solid transparent; background: transparent; text-align: center;" size="2" v-model="value.type_ot" @change="updateAbsen(value.id, value.type_ot, 'OT', row.position, moment(value.date).locale('id').format('ddd'))">
                        </p>
                      </td>
                      <td style="background-color: #8FBC8F; text-align: center;">{{row.total_sakit_count}}</td>
                      <td style="background-color: #DCDCDC; text-align: center;">{{row.total_ijin_count}}</td>
                      <td style="background-color: #FF6347; text-align: center;">{{row.total_alpa_count}}</td>
                      <td style="background-color: yellow; text-align: center;">{{row.total_cuti_count}}</td>
                      <td style="background-color: #FFFACD; text-align: center;">{{row.total_kerja_count}}</td>
                      <td style="background-color: #7FFF00; text-align: center;">{{row.total_ot_count}}</td>
                    </tr>
                    <tr>
                      <td :colspan="(absenKaryawan.data.length != 0) ? absenKaryawan.data[0].rel_absen_karyawan.length  + 2 : 0" style="background-color: #F8F8FF; text-align: center;">TOTAL </td>
                      <td style="background-color: #F8F8FF; text-align: center;">{{ totalSakitAll }}</td>
                      <td style="background-color: #F8F8FF; text-align: center;">{{ totalIjinAll }}</td>
                      <td style="background-color: #F8F8FF; text-align: center;">{{ totalAlpaAll }}</td>
                      <td style="background-color: #F8F8FF; text-align: center;">{{ totalCutiAll }}</td>
                      <td style="background-color: #F8F8FF; text-align: center;">{{ totalKerjAll }}</td>
                      <td style="background-color: #7FFF00; text-align: center;">{{ totalOTAll }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer">
            </div>
          </div>
      </div>
    </div>
  </div>

  <!-- ======== ATTENDANCE LOGS ======== -->
  <div class="container">
    <div class="card">
      <div class="card-header pb-0">
        <div class="d-flex align-items-center">
          <h6>ATTENDANCE LOGS</h6>
          <a class="ms-auto" :href="apiUrl+'export-excel/attendance-logs?nama='+searchAttLog.nama+'&shift='+searchAttLog.shift+'&date='+searchAttLog.date+'&type='+searchAttLog.type+''" target="_BLANK"><argon-button color="success" size="xs" class="ms-auto" variant="gradient"><i class="fa fa-file-excel-o" aria-hidden="true" style="margin-right: 5px;"></i> Export Excel</argon-button></a>
          <argon-button
            style="margin-left: 5px;"
            variant="gradient"
            color="warning"
            size="sm"
            @click="getAttLog()"
          ><i class="fa fa-refresh fa-sm" aria-hidden="true"></i> Refresh</argon-button>
          <argon-button
            style="margin-left: 5px;"
            variant="gradient"
            color="secondary"
            size="sm"
            @click="filterAttLog()"
          ><i class="fa fa-filter fa-sm" aria-hidden="true"></i> Filter</argon-button>
          <argon-button
            style="margin-left: 5px;"
            variant="gradient"
            color="info"
            size="sm"
            @click="createLog()"
          ><i class="fa fa-plus fa-sm" aria-hidden="true"></i> Add Logs</argon-button>

        </div>
      </div>
      <div class="card-body">
        <div class="card-body px-0 pt-0 pb-2">
            <div class="table-responsive p-0 scroll">
              <table class="table align-items-center mb-0">
                <thead>
                  <tr style="background-color: #F0F8FF;">
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder ">NO</th>
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder  ps-2">NAME</th>
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder  ps-2">POSITION</th>
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder  ps-2">SHIFT</th>
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder ">DAY</th>
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder ">DATE</th>
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder ">Clock IN</th>
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder ">Clock Out</th>
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder "></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row, i) in tableAttLog.data" :key="i">
                    <td class="align-middle text-center text-sm">
                      <span class="text-xs text-secondary font-weight-bold"> {{ i + 1 }} </span>
                    </td>
                    <td>
                      <div class="d-flex px-2 py-1">
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="mb-0 text-sm"> {{ row.name }} </h6>
                          <p class="text-xs text-secondary mb-0"> {{ row.pin }} </p>
                        </div>
                      </div>
                    </td>
                    <td class="align-middle text-center text-sm">
                      <span class="text-secondary text-xs font-weight-bold"> {{ row.position }} </span>
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"> {{row.shift}} </span>
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"> {{ moment(row.date).locale('id').format('dddd') }} </span>
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"> {{ moment(row.date).locale('id').format('LL') }} </span>
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"> {{row.clock_in}} </span>
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"> {{row.clock_out}} </span>
                    </td>
                    <!-- <td class="align-middle text-center">
                      <span class="badge badge-sm bg-gradient-info" v-if="row.type == 'Masuk'">{{ row.type }}</span>
                      <span class="badge badge-sm bg-gradient-success" v-else-if="row.type == 'Keluar'">{{ row.type }}</span>
                      <span class="badge badge-sm bg-gradient-danger" v-else>{{ row.type }}</span>
                    </td> -->
                    <td>
                      <i class="fas fa-edit fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="editAttLogs(row.id)" ></i>
                      <i class="fa fa-trash fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="removeAttLog(row.id)"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
      </div>
    </div>
  </div>

  <!-- ======== HISTORY LEMBUR ======== -->
  <div class="container mt-4">
    <div class="card">
      <div class="card-header pb-0">
        <div class="d-flex align-items-center">
          <h6>HISTORY LEMBUR</h6>
          <a class="ms-auto" :href="apiUrl+'export-excel/history-lembur?nama='+searchHisLembur.nama+'&tanggal='+searchHisLembur.tanggal+'&status_approval='+searchHisLembur.status_approval+'&code_spl='+searchHisLembur.code_spl+'&created_by='+searchHisLembur.created_by+''" target="_BLANK"><argon-button color="success" size="xs" class="ms-auto" variant="gradient"><i class="fa fa-file-excel-o" aria-hidden="true" style="margin-right: 5px;"></i> Export Excel</argon-button></a>
          <argon-button
            style="margin-left: 5px;"
            variant="gradient"
            color="secondary"
            size="sm"
            @click="filterHisLembur()"
          ><i class="fa fa-filter fa-sm" aria-hidden="true"></i> Filter</argon-button>
          <!-- <argon-button
            style="margin-left: 5px;"
            variant="gradient"
            color="info"
            size="sm"
            @click="getAttLog()"
          ><i class="fa fa-refresh fa-sm" aria-hidden="true"></i> Refresh</argon-button> -->

        </div>
      </div>
      <div class="card-body">
        <div class="card-body px-0 pt-0 pb-2">
            <div class="table-responsive p-0 scroll">
              <table class="table align-items-center mb-0">
                <thead>
                  <tr style="background-color: #F0F8FF;">
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder  ps-2">NAMA</th>
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder  ps-2">HARI</th>
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder  ps-2">Tanggal Lembur</th>
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder  ps-2">AKTUAL JAM</th>
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder  ps-2">KALKULASI JAM</th>
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder ">STATUS</th>
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder ">CODE SPL</th>
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder ">APPROVED BY</th>
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder ">APPROVED AT</th>
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder ">CREATED BY</th>
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder ">CREATED AT</th>
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder "></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row, i) in tableHisLembur.data" :key="i">
                    <td>
                      <div class="d-flex px-2 py-1">
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="mb-0 text-sm"> {{ row.nama }} </h6>
                          <p class="text-xs text-secondary mb-0"> {{ row.id_karyawan }} </p>
                        </div>
                      </div>
                    </td>
                    <td class="align-middle text-center text-sm">
                      <span class="text-secondary text-xs font-weight-bold"> {{ moment(row.tanggal).locale('id').format('dddd') }} </span>
                    </td>
                    <td class="align-middle text-center text-sm">
                      <span class="text-secondary text-xs font-weight-bold"> {{ moment(row.tanggal).locale('id').format('LL') }} </span>
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"> {{row.actual_jam}} </span>
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"> {{row.calculate_jam}} </span>
                    </td>
                    <td class="align-middle text-center">
                      <span class="badge badge-sm bg-gradient-success" v-if="row.status_approval == 'Approved'"> {{ row.status_approval }} </span>
                      <span class="badge badge-sm bg-gradient-danger" v-if="row.status_approval == 'Not Approved'"> {{ row.status_approval }} </span>
                      <span class="badge badge-sm bg-gradient-warning" v-if="row.status_approval == 'Waiting'"> {{ row.status_approval }} </span>
                    </td>
                    <td class="align-middle text-center">
                      <span class="badge badge-sm bg-gradient-info"> {{ row.code_spl }} </span>
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"> {{row.approved_by}} </span>
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"> {{ moment(row.approved_date).locale('id').format('LL') }} </span>
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"> {{row.created_by}} </span>
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"> {{ moment(row.created_at).locale('id').format('LL') }} </span>
                    </td>
                    <td>
                      <i class="fas fa-edit fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="editLembur(row.id)" ></i>
                      <i class="fa fa-trash fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="removeLembur(row.id)"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
      </div>
    </div>
  </div>

  <!-- ======== HISTORY CUTI / SAKIT ======== -->
  <div class="container mt-4">
    <div class="card">
      <div class="card-header pb-0">
        <div class="d-flex align-items-center">
          <h6>HISTORY CUTI / SAKIT</h6>
          <a class="ms-auto" :href="apiUrl+'export-excel/history-lembur?nama='+searchHisLembur.nama+'&tanggal='+searchHisLembur.tanggal+'&status_approval='+searchHisLembur.status_approval+'&code_spl='+searchHisLembur.code_spl+'&created_by='+searchHisLembur.created_by+''" target="_BLANK"><argon-button color="success" size="xs" class="ms-auto" variant="gradient"><i class="fa fa-file-excel-o" aria-hidden="true" style="margin-right: 5px;"></i> Export Excel</argon-button></a>
          <argon-button
            style="margin-left: 5px;"
            variant="gradient"
            color="secondary"
            size="sm"
            @click="filterHisCuti()"
          ><i class="fa fa-filter fa-sm" aria-hidden="true"></i> Filter</argon-button>
          <argon-button
            style="margin-left: 5px;"
            variant="gradient"
            color="info"
            size="sm"
            @click="createCuti()"
          ><i class="fa fa-plus fa-sm" aria-hidden="true"></i> Add Cuti / Izin</argon-button>

        </div>
      </div>
      <div class="card-body">
        <div class="card-body px-0 pt-0 pb-2">
            <div class="table-responsive p-0 scroll">
              <table class="table align-items-center mb-0">
                <thead>
                  <tr style="background-color: #F0F8FF;">
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder  ps-2">DIBUAT OLEH</th>
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder  ps-2">ALASAN</th>
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder  ps-2">DARI TANGAL</th>
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder  ps-2">SAMPAI TANGAL</th>
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder  ps-2">TOTAL HARI</th>
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder ">BUKTI</th>
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder ">STATUS</th>
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder ">TYPE</th>
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder ">APPROVED BY</th>
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder ">APPROVED AT</th>
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder ">CREATED AT</th>
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder "></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row, i) in tableHisCuti.data" :key="i">
                    <td>
                      <div class="d-flex px-2 py-1">
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="mb-0 text-sm"> {{ row.dibuat_oleh }} </h6>
                          <p class="text-xs text-secondary mb-0"> {{ row.id_karyawan }} </p>
                        </div>
                      </div>
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"> {{row.alasan_cuti}} </span>
                    </td>
                    <!-- <td class="align-middle text-center text-sm">
                      <span class="text-secondary text-xs font-weight-bold"> {{ moment(row.tanggal).locale('id').format('dddd') }} </span>
                    </td> -->
                    <td class="align-middle text-center text-sm">
                      <span class="text-secondary text-xs font-weight-bold"> {{ moment(row.dari_tanggal).locale('id').format('LL') }} </span>
                    </td>
                    <td class="align-middle text-center text-sm">
                      <span class="text-secondary text-xs font-weight-bold"> {{ moment(row.sampai_tanggal).locale('id').format('LL') }} </span>
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"> {{row.total_day}} </span>
                    </td>
                    <td class="align-middle text-center">
                      <a v-if="row.bukti" :href="storageUrl+'/foto_bukti_sakit/'+row.bukti" target="_BLANK" class="text-xs font-weight-bold text-primary">
                        Bukti Surat Sakit
                      </a>
                    </td>
                    <td class="align-middle text-center">
                      <span class="badge badge-sm bg-gradient-success" v-if="row.status_approval == 'Approved'"> {{ row.status_approval }} </span>
                      <span class="badge badge-sm bg-gradient-danger" v-if="row.status_approval == 'Not Approved'"> {{ row.status_approval }} </span>
                      <span class="badge badge-sm bg-gradient-warning" v-if="row.status_approval == 'Waiting'"> {{ row.status_approval }} </span>
                    </td>
                    <td class="align-middle text-center">
                      <span class="badge badge-sm bg-gradient-info" v-if="row.type == 'Cuti'"> {{ row.type }} </span>
                      <span class="badge badge-sm bg-gradient-danger" v-if="row.type == 'Sakit'"> {{ row.type }} </span>
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"> {{row.approved_by}} </span>
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"> {{ moment(row.approved_date).locale('id').format('LL') }} </span>
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"> {{ moment(row.created_at).locale('id').format('LL') }} </span>
                    </td>
                    <td>
                      <i class="fas fa-edit fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="editCuti(row.id)" ></i>
                      <i class="fa fa-trash fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="removeCuti(row.id)"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
      </div>
    </div>
  </div>

  <!-- =======  MODAL CREATE ======= -->
  <div class="container">
    <vue-final-modal v-model="form.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{form.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="form.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <!-- <label for="example-text-input" class="form-control-label">ID Karyawan <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="ID Karyawan" v-model="karyawan.id_karyawan">
        <label for="example-text-input" class="form-control-label">Nama <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Nama" v-model="karyawan.nama" required>
        <label for="example-text-input" class="form-control-label">Type</label>
        <select class="form-select" aria-label="Default select example" v-model="karyawan.type">
          <option selected>Type</option>
          <option value="HK">Masuk</option>
          <option value="A">Alpa</option>
          <option value="S">Sakit</option>
          <option value="I">Ijin</option>
          <option value="C">Cuti</option>
        </select> -->
        <label for="example-text-input" class="form-control-label">Periode Start <span style="color: red;">*</span></label>
        <input type="date" class="form-control" placeholder="Nama" v-model="karyawan.periode_start" required>
        <label for="example-text-input" class="form-control-label mt-3">Periode End <span style="color: red;">*</span></label>
        <input type="date" class="form-control" placeholder="Nama" v-model="karyawan.periode_end" required>
      </div>
      <!-- footer -->
      <div class="row float-right mt-5">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="form.show = false">Close</argon-button>
        </div>
        <div class="col-1">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="save()" :disabled="onLoading == true">
            <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
            <span v-else>
                <span> Save</span>
            </span>
          </argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

   <!-- =======  MODAL FILTER ======= -->
  <div class="container">
    <vue-final-modal v-model="formFilter.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formFilter.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formFilter.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label">Nama <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Nama" v-model="search.nama" required>
        <label for="example-text-input" class="form-control-label mt-3">Periode Start <span style="color: red;">*</span></label>
        <input type="date" class="form-control" placeholder="Nama" v-model="search.periode_start" required>
        <label for="example-text-input" class="form-control-label mt-3">Periode End <span style="color: red;">*</span></label>
        <input type="date" class="form-control" placeholder="Nama" v-model="search.periode_end" required>
      </div>
      <!-- footer -->
      <div class="row float-right mt-5">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="formFilter.show = false">Close</argon-button>
        </div>
        <div class="col-1">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="getAbsen()" :disabled="onLoading == true">
            <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
            <span v-else>
                <span> Filter</span>
            </span>
          </argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

   <!-- =======  MODAL SET LIBUR ======= -->
  <div class="container">
    <vue-final-modal v-model="formLibur.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formLibur.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formLibur.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label">Set Tanggal <span style="color: red;">*</span></label>
        <input type="date" class="form-control" placeholder="Nama" v-model="setDataLibur.tanggal" required>
      </div>
      <!-- footer -->
      <div class="row float-right mt-5">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="formLibur.show = false">Close</argon-button>
        </div>
        <div class="col-1">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="setLiburGo()" :disabled="onLoading == true">
            <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
            <span v-else>
                <span> Save</span>
            </span>
          </argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

  <!-- =======  MODAL FILTER ATT LOG ======= -->
  <div class="container">
    <vue-final-modal v-model="formFilterAttLog.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formFilterAttLog.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formFilterAttLog.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label">Nama</label>
        <input type="text" class="form-control" placeholder="Nama" v-model="searchAttLog.nama">
        <label for="example-text-input" class="form-control-label mt-3">Shift</label>
        <select class="form-select" aria-label="Default select example" v-model="searchAttLog.shift">
          <option selected>Shift</option>
          <option value="Non">Non</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Date</label>
        <input type="date" class="form-control" placeholder="Nama" v-model="searchAttLog.date">
        <label for="example-text-input" class="form-control-label mt-3">Type</label>
        <select class="form-select" aria-label="Default select example" v-model="searchAttLog.type">
          <option selected>Type</option>
          <option value="Masuk">Masuk</option>
          <option value="Keluar">Keluar</option>
        </select>
      </div>
      <!-- footer -->
      <div class="row float-right mt-5">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="formFilterAttLog.show = false">Close</argon-button>
        </div>
        <div class="col-1">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="getAttLog()" :disabled="onLoading == true">
            <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
            <span v-else>
                <span> Filter</span>
            </span>
          </argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

   <!-- =======  MODAL FILTER HISTORY LEMBUR ======= -->
  <div class="container">
    <vue-final-modal v-model="formFilterHisLembur.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formFilterHisLembur.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formFilterHisLembur.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label">Nama</label>
        <input type="text" class="form-control" placeholder="Nama" v-model="searchHisLembur.nama">
        <label for="example-text-input" class="form-control-label mt-3">Tanggal Lembur</label>
        <input type="date" class="form-control" placeholder="Tanggal Lembur" v-model="searchHisLembur.tanggal">
        <label for="example-text-input" class="form-control-label mt-3">Approval Status</label>
        <select class="form-select" aria-label="Default select example" v-model="searchHisLembur.status_approval">
          <option selected>Select Status</option>
          <option value="Approved">Approved</option>
          <option value="Not Approved">Not Approved</option>
          <option value="Waiting">Waiting</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Code SPL</label>
        <input type="text" class="form-control" placeholder="Code SPL" v-model="searchHisLembur.code_spl">
        <label for="example-text-input" class="form-control-label">Di Buat Oleh</label>
        <input type="text" class="form-control" placeholder="Di Buat Oleh" v-model="searchHisLembur.created_by">
      </div>
      <!-- footer -->
      <div class="row float-right mt-5">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="formFilterHisLembur.show = false">Close</argon-button>
        </div>
        <div class="col-1">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="getHisLembur()" :disabled="onLoading == true">
            <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
            <span v-else>
                <span> Filter</span>
            </span>
          </argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

   <!-- =======  MODAL FILTER HISTORY CUTI / IZIN ======= -->
  <div class="container">
    <vue-final-modal v-model="formFilterHisCuti.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formFilterHisCuti.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formFilterHisCuti.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label">DIbuat Oleh</label>
        <input type="text" class="form-control" placeholder="DIbuat Oleh" v-model="searchHisCuti.dibuat_oleh">
        <label for="example-text-input" class="form-control-label mt-3">Dari Tanggal</label>
        <input type="date" class="form-control" placeholder="Dari Tanggal" v-model="searchHisCuti.dari_tanggal">
        <label for="example-text-input" class="form-control-label mt-3">Type</label>
        <select class="form-select" aria-label="Default select example" v-model="searchHisCuti.type">
          <option selected>Select Status</option>
          <option value="Cuti">Cuti</option>
          <option value="Sakit">Sakit</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Approval Status</label>
        <select class="form-select" aria-label="Default select example" v-model="searchHisCuti.status_approval">
          <option selected>Select Status</option>
          <option value="Approved">Approved</option>
          <option value="Not Approved">Not Approved</option>
          <option value="Waiting">Waiting</option>
        </select>
      </div>
      <!-- footer -->
      <div class="row float-right mt-5">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="formFilterHisCuti.show = false">Close</argon-button>
        </div>
        <div class="col-1">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="getHisCuti()" :disabled="onLoading == true">
            <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
            <span v-else>
                <span> Filter</span>
            </span>
          </argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

  <!-- =======  MODAL EDIT ATT LOGS ======= -->
  <div class="container">
    <vue-final-modal v-model="formEditAttLogs.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formEditAttLogs.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formEditAttLogs.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Employee Name</label>
        <multiselect v-model="dataAttLogs.name" :options="tableEmployee.data" :multiple="false" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Select Employee Name" label="name" track-by="name" :preselect-first="true" @keyup="getEmployee">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
        <label for="example-text-input" class="form-control-label mt-3">Shift</label>
        <select class="form-select" aria-label="Default select example" v-model="dataAttLogs.shift">
          <option selected>Shift</option>
          <option value="Non">Non</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Date</label>
        <input type="date" class="form-control" placeholder="Nama" v-model="dataAttLogs.date">
        <label for="example-text-input" class="form-control-label mt-3">Time</label>
        <input type="time" class="form-control" placeholder="Nama" v-model="dataAttLogs.time">
        <label for="example-text-input" class="form-control-label mt-3">Type</label>
        <select class="form-select" aria-label="Default select example" v-model="dataAttLogs.type">
          <option value="Clock In">Clock In</option>
          <option value="Clock Out">Clock Out</option>
        </select>
      </div>
      <!-- footer -->
      <div class="row float-right mt-5">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="formEditAttLogs.show = false">Close</argon-button>
        </div>
        <div class="col-1">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="saveAttLogs()" :disabled="onLoading == true">
            <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
            <span v-else>
                <span> Save</span>
            </span>
          </argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

   <!-- =======  MODAL EDIT LEMBUR ======= -->
  <div class="container">
    <vue-final-modal v-model="formEditLembur.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formEditLembur.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formEditLembur.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Code SPL</label>
        <input type="text" class="form-control" placeholder="Code SPL" v-model="dataLembur.code_spl" disabled>
        <label for="example-text-input" class="form-control-label mt-3">Nama</label>
        <input type="text" class="form-control" placeholder="Code SPL" v-model="dataLembur.nama" disabled>
        <label for="example-text-input" class="form-control-label mt-3">Tanggal</label>
        <input type="date" class="form-control" placeholder="Tanggal" v-model="dataLembur.tanggal" disabled>
        <label for="example-text-input" class="form-control-label mt-3">Aktual Jam</label>
        <input type="number" class="form-control" placeholder="Aktual Jam" v-model="dataLembur.actual_jam">
        <label for="example-text-input" class="form-control-label mt-3">Uraian Pekerjaan</label>
        <input type="text" class="form-control" placeholder="Uraian Pekerjaan" v-model="dataLembur.uraian_pekerjaan" disabled>
      </div>
      <!-- footer -->
      <div class="row float-right mt-5">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="formEditLembur.show = false">Close</argon-button>
        </div>
        <div class="col-1">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="saveLembur()" :disabled="onLoading == true">
            <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
            <span v-else>
                <span> Save</span>
            </span>
          </argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

  <!-- =======  MODAL EDIT CUTI ======= -->
  <div class="container">
    <vue-final-modal v-model="formEditCuti.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formEditCuti.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formEditCuti.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3" v-if="formEditCuti.title == 'Edit Data Cuti'">Dibuat Oleh</label>
        <input type="text" class="form-control" placeholder="Dibuat Oleh" v-model="dataCuti.dibuat_oleh" v-if="formEditCuti.title == 'Edit Data Cuti'">
        <label for="example-text-input" class="form-control-label mt-3" v-if="formEditCuti.title == 'Add Data Cuti / Izin'">Id Karyawan</label>
        <input type="text" class="form-control" placeholder="Id Karyawan" v-model="dataCuti.id_karyawan" v-if="formEditCuti.title == 'Add Data Cuti / Izin'">
        <label for="example-text-input" class="form-control-label mt-3">Alasan</label>
        <input type="text" class="form-control" placeholder="Alasan" v-model="dataCuti.alasan_cuti" :disabled="formEditCuti.title == 'Edit Data Cuti'">
        <label for="example-text-input" class="form-control-label mt-3">Dari Tanggal</label>
        <input type="date" class="form-control" placeholder="Dari Tanggal" v-model="dataCuti.dari_tanggal">
        <label for="example-text-input" class="form-control-label mt-3">Sampai Tanggal</label>
        <input type="date" class="form-control" placeholder="Sampai Tanggal" v-model="dataCuti.sampai_tanggal">
        <label for="example-text-input" class="form-control-label mt-3">Type</label>
        <select class="form-select" aria-label="Default select example" v-model="dataCuti.type">
          <option selected>Type</option>
          <option value="Cuti">Cuti</option>
          <option value="Sakit">Sakit</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Status</label>
        <select class="form-select" aria-label="Default select example" v-model="dataCuti.status_approval">
          <option selected>Status</option>
          <option value="Approved">Approved</option>
          <option value="Not Approved">Not Approved</option>
          <option value="Waiting">Waiting</option>
        </select>
      </div>
      <!-- footer -->
      <div class="row float-right mt-5">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="formEditCuti.show = false">Close</argon-button>
        </div>
        <div class="col-1">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="saveCuti()" :disabled="onLoading == true">
            <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
            <span v-else>
                <span> Save</span>
            </span>
          </argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
import { VueFinalModal } from 'vue-final-modal'
import Api from '@/helpers/api';
import config from '@/configs/config';
// import dataKaryawan from '@/services/dataKaryawan.service';
var moment = require('moment');
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css';
import dataAbsensi from '@/services/human_resource/dataAbsensi.service';
import akun from '@/services/akun.service';
import attendanceLog from '@/services/human_resource/attendanceLog.service';
import approvalLembur from '@/services/human_resource/approvalLembur.service';
import approvalCuti from '@/services/human_resource/approvalCuti.service';
import employee from '@/services/human_resource/employee.service';

export default {
  name: "tables",
  components: {
    ArgonButton,
    VueFinalModal,
    Multiselect,
  },
  data() {
    return {
      moment:moment,
      onLoading: false,
      absenKaryawan: {
        data: []
      },
      tableAbsen: {
        data: []
      },
      tableAttLog: {
        data: []
      },
      tableHisLembur: {
        data: []
      },
      tableHisCuti: {
        data: []
      },
      tableEmployee: {
        data: []
      },
      form: {
        add: true,
        title: "Create Periode Absensi",
        show: false
      },
      formLibur: {
        add: true,
        title: "Set Tanggal Libur / Tanggal Merah",
        show: false
      },
      formFilter: {
        add: true,
        title: "Filter",
        show: false
      },
      formFilterAttLog: {
        add: true,
        title: "Filter Att Log",
        show: false
      },
      formFilterHisLembur: {
        add: true,
        title: "Filter History Lembur",
        show: false
      },
      formFilterHisCuti: {
        add: true,
        title: "Filter History Lembur",
        show: false
      },
      formEditAttLogs: {
        add: true,
        title: "Edit Data Att Logs",
        show: false
      },
      formEditLembur: {
        add: true,
        title: "Edit Data Lembur",
        show: false
      },
      formEditCuti: {
        add: true,
        title: "Edit Data Cuti",
        show: false
      },
      karyawan: {},
      totalSakitAll: '',
      totalIjinAll: '',
      totalAlpaAll: '',
      totalCutiAll: '',
      totalKerjAll: '',
      totalOTAll: '',
      search: {
        nama: '',
        periode_start: '',
        periode_end: '',
      },
      searchAttLog: {
        nama: '',
        shift: '',
        date: '',
        type: '',
      },
      searchHisLembur: {
        nama: '',
        tanggal: '',
        code_spl: '',
        created_by: '',
        status_approval: '',
      },
      searchHisCuti: {
        dibuat_oleh: '',
        dari_tanggal: '',
        type: '',
        status_approval: '',
      },
      setDataLibur: {},
      dataAttLogs: {},
      dataLembur: {},
      dataCuti: {},
      backgroundRed: null,
      apiUrl :config.apiUrl,
      storageUrl : config.storageUrl,
      role: '',
    };
  },
  mounted(){
    // this.get();
    this.getAbsen();
    this.getAttLog();
    this.getHisLembur();
    this.getHisCuti();
    this.tokenApi = 'Bearer '+localStorage.getItem('token');
    this.getRole();
  },
  methods: {
    // get(){
    //   let context = this;               
    //   Api(context, dataKaryawan.index({search: this.search})).onSuccess(function(response) {    
    //       context.table.data = response.data.data.data.data;
    //   }).onError(function(error) {                    
    //       if (error.response.status == 404) {
    //           context.table.data = [];
    //       }
    //   })
    //   .call()
    // },
    getAbsen(){
      let context = this;    
      this.onLoading = true;

      Api(context, dataAbsensi.index({nama: context.search.nama, periode_start: context.search.periode_start, periode_end: context.search.periode_end})).onSuccess(function(response) {    
          context.tableAbsen.data    = response.data.data.data;
          context.absenKaryawan.data = response.data.data.absenKaryawan;

          context.totalSakitAll = response.data.data.totalSakitAll;
          context.totalIjinAll  = response.data.data.totalIjinAll;
          context.totalAlpaAll  = response.data.data.totalAlpaAll;
          context.totalCutiAll  = response.data.data.totalCutiAll;
          context.totalKerjAll  = response.data.data.totalKerjAll;
          context.totalOTAll    = response.data.data.totalOTAll;
          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.table.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.onLoading = false;
         context.formFilter.show  = false;
      })
      .call()
    },
    getAttLog(){
      let context = this;    
      this.onLoading = true;

      Api(context, dataAbsensi.attLog({nama: context.searchAttLog.nama, shift: context.searchAttLog.shift, date: context.searchAttLog.date, type: context.searchAttLog.type})).onSuccess(function(response) {
          context.tableAttLog.data = response.data.data;
          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableAttLog.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.onLoading             = false;
         context.formFilterAttLog.show = false;
      })
      .call()
    },
    getHisLembur(){
      let context = this;    
      this.onLoading = true;

      Api(context, dataAbsensi.getHisLembur({nama: context.searchHisLembur.nama, tanggal: context.searchHisLembur.tanggal, code_spl: context.searchHisLembur.code_spl, created_by: context.searchHisLembur.created_by, status_approval: context.searchHisLembur.status_approval })).onSuccess(function(response) {
          context.tableHisLembur.data = response.data.data;
          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableHisLembur.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.onLoading             = false;
         context.formFilterHisLembur.show = false;
      })
      .call()
    },
    getHisCuti(){
      let context = this;    
      this.onLoading = true;

      Api(context, dataAbsensi.getHisCuti({dibuat_oleh: context.searchHisCuti.dibuat_oleh, dari_tanggal: context.searchHisCuti.dari_tanggal, type: context.searchHisCuti.type, status_approval: context.searchHisCuti.status_approval })).onSuccess(function(response) {
          context.tableHisCuti.data = response.data.data;
          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableHisCuti.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.onLoading              = false;
         context.formFilterHisCuti.show = false;
      })
      .call()
    },
    getEmployee(param){
      let context = this;        
      Api(context, employee.getEmployee({search: param.target.value})).onSuccess(function(response) {    
          context.tableEmployee.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableEmployee.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    createLog() {
      this.formEditAttLogs.add   = true;
      this.formEditAttLogs.show  = true;
      this.formEditAttLogs.title = "Add Data Att Logs";
      this.dataAttLogs           = {};
    },
    editAttLogs(id) {
      let context = this;               
      Api(context, attendanceLog.show(id)).onSuccess(function(response) {
          context.dataAttLogs            = response.data.data;
          context.formEditAttLogs.show   = true;
          context.formEditAttLogs.title  = 'Edit Data Att Logs';       
      })
      .call()        
    },
    saveAttLogs(){
      let api      = null;
      let context  = this;
      let formData = new FormData();

      if (context.dataAttLogs.name != undefined && context.dataAttLogs.shift != undefined && context.dataAttLogs.date != undefined && context.dataAttLogs.time != undefined && context.dataAttLogs.type != undefined) {
        formData.append('name', JSON.stringify(context.dataAttLogs.name));
        formData.append('shift', context.dataAttLogs.shift);
        formData.append('date', context.dataAttLogs.date);
        formData.append('time', context.dataAttLogs.time);
        formData.append('type', context.dataAttLogs.type);
      }else{
        return alert('Field Bintang Merah Wajib Di Isi')
      }

      if (context.formEditAttLogs.title == 'Add Data Att Logs') {
          api = Api(context, attendanceLog.create(formData));
      } else {
          api = Api(context, attendanceLog.update(context.dataAttLogs.id, formData));
      }
      // eslint-disable-next-line no-unused-vars
      api.onSuccess(function(response) {
        context.getAttLog();
        context.formEditAttLogs.show = false;
        context.notify((context.formEditAttLogs.title === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      // eslint-disable-next-line no-unused-vars
      }).onError(function(error) { 
        context.notify((context.formEditAttLogs.title != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
      })
      .call();
    },
    removeAttLog(id) {
      var r = confirm("Anda yakin ingin menghapus data ini ?");
      if (r == true) {
        let context = this;

        Api(context, attendanceLog.deleteLog(id)).onSuccess(function(response) {
            context.getAttLog();
            context.notify('Data Successfully Deleted', 'success')
        }).call();
      }
    },
    editLembur(id) {
      let context = this;               
      Api(context, approvalLembur.editLembur(id)).onSuccess(function(response) {
          context.dataLembur           = response.data.data;
          context.formEditLembur.show  = true;
          context.formEditLembur.title = 'Edit Data Lembur';       
      })
      .call()        
    },
    saveLembur(){
      let api      = null;
      let context  = this;
      let formData = new FormData();

      if (context.dataLembur.id != undefined && context.dataLembur.actual_jam != undefined) {
        formData.append('id_karyawan', context.dataLembur.id_karyawan);
        formData.append('tanggal', context.dataLembur.tanggal);
        formData.append('actual_jam', context.dataLembur.actual_jam);
      }else{
        return alert('Field Bintang Merah Wajib Di Isi')
      }

      api = Api(context, approvalLembur.updateLembur(context.dataLembur.id, formData));

      // eslint-disable-next-line no-unused-vars
      api.onSuccess(function(response) {
        context.notify((context.formEditLembur.title === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      // eslint-disable-next-line no-unused-vars
      }).onError(function(error) { 
        context.notify((context.formEditLembur.title != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
        context.getHisLembur();
        context.formEditLembur.show = false;
      })
      .call();
    },
    removeLembur(id) {
      var r = confirm("Anda yakin ingin menghapus data ini ?");
      if (r == true) {
        let context = this;

        Api(context, approvalLembur.deleteAnggota(id)).onSuccess(function(response) {
            context.getHisLembur();
            context.notify('Data Successfully Deleted', 'success')
        }).call();
      }
    },
    createCuti() {
      this.formEditCuti.add   = true;
      this.formEditCuti.show  = true;
      this.formEditCuti.title = "Add Data Cuti / Izin";
      this.dataCuti           = {};
    },
    editCuti(id) {
      let context = this;               
      Api(context, approvalCuti.show(id)).onSuccess(function(response) {
          context.dataCuti           = response.data.data;
          context.formEditCuti.show  = true;
          context.formEditCuti.title = 'Edit Data Cuti';       
      })
      .call()        
    },
    saveCuti(){
      let api      = null;
      let context  = this;
      let formData = new FormData();

      if (context.dataCuti.dari_tanggal != undefined && context.dataCuti.sampai_tanggal != undefined && context.dataCuti.type != undefined) {
        formData.append('id_karyawan', context.dataCuti.id_karyawan);
        formData.append('dari_tanggal', context.dataCuti.dari_tanggal);
        formData.append('alasan_cuti', context.dataCuti.alasan_cuti);
        formData.append('sampai_tanggal', context.dataCuti.sampai_tanggal);
        formData.append('type', context.dataCuti.type);
        formData.append('status_approval', context.dataCuti.status_approval);
      }else{
        return alert('Field Bintang Merah Wajib Di Isi')
      }

      if (context.formEditCuti.title == "Add Data Cuti / Izin") {
          api = Api(context, approvalCuti.create(formData));
      } else {
          api = Api(context, approvalCuti.updateCuti(context.dataCuti.id, formData));
      }
      // eslint-disable-next-line no-unused-vars
      api.onSuccess(function(response) {
        context.notify((context.formEditCuti.title === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      // eslint-disable-next-line no-unused-vars
      }).onError(function(error) { 
        context.notify((context.formEditCuti.title != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
        context.getHisCuti();
        context.formEditCuti.show = false;
      })
      .call();
    },
    removeCuti(id) {
      var r = confirm("Anda yakin ingin menghapus data ini ?");
      if (r == true) {
        let context = this;

        Api(context, approvalCuti.delete(id)).onSuccess(function(response) {
            context.getHisCuti();
            context.notify('Data Successfully Deleted', 'success')
        }).call();
      }
    },
    getRole(){
      let context = this;     
      context.onLoading = true;
      Api(context, akun.indexProfile()).onSuccess(function(response) {
          context.role = response.data.data[0].role;
          if (response.data.data[0].role != 'Admin') {
            // context.$router.push({ path: /detail-profile/+response.data.data[0].employee_id})
          }
      }).onError(function(error) {  
      })
      .call() 
    },
    filter() {
      this.formFilter.add   = true;
      this.formFilter.show  = true;
      this.formFilter.title = "Filter";
      this.onLoading        = false;
    },
    create() {
      this.form.add   = true;
      this.form.show  = true;
      this.form.title = "Create Periode Absensi";
      this.onLoading  = false;
    },
    setLibur() {
      this.formLibur.add   = true;
      this.formLibur.show  = true;
      this.formLibur.title = "Set Tanggal Libur / Tanggal Merah";
      this.onLoading       = false;
    },
    filterAttLog() {
      this.formFilterAttLog.add   = true;
      this.formFilterAttLog.show  = true;
      this.formFilterAttLog.title = "Filter Att Log";
      this.onLoading              = false;
    },
    filterHisLembur() {
      this.formFilterHisLembur.add   = true;
      this.formFilterHisLembur.show  = true;
      this.formFilterHisLembur.title = "Filter History Lembur";
      this.onLoading                 = false;
    },
    filterHisCuti() {
      this.formFilterHisCuti.add   = true;
      this.formFilterHisCuti.show  = true;
      this.formFilterHisCuti.title = "Filter History Cuti / Sakit";
      this.onLoading               = false;
    },
    save(){
      let api      = null;
      let context  = this;
      this.onLoading = true;
      let formData = new FormData();

      if (context.karyawan.periode_start != undefined && context.karyawan.periode_end != undefined) {
        // formData.append('id_karyawan', context.karyawan.id_karyawan);
        // formData.append('nama', context.karyawan.nama);
        // formData.append('type', context.karyawan.type);
        formData.append('periode_start', context.karyawan.periode_start);
        formData.append('periode_end', context.karyawan.periode_end);
      }else{
        return alert('Field Bintang Merah Wajib Di Isi')
      }

      api = Api(context, dataAbsensi.create(formData));
      // eslint-disable-next-line no-unused-vars
      api.onSuccess(function(response) {
        context.getAbsen();
        context.form.show = false;
        context.notify('Created Success', 'success')
      // eslint-disable-next-line no-unused-vars
      }).onError(function(error) {                    
        context.notify('Created Failed', 'error')
      }).onFinish(function() { 
         context.onLoading = false; 
      })
      .call();
    },
    setLiburGo(){
      let api      = null;
      let context  = this;
      this.onLoading = true;

      let formData = new FormData();

      if (context.setDataLibur.tanggal != undefined) {
        formData.append('periode_start', context.search.periode_start);
        formData.append('periode_end', context.search.periode_end);
        formData.append('tanggal', context.setDataLibur.tanggal);
      }else{
        return alert('Field Bintang Merah Wajib Di Isi')
      }

      api = Api(context, dataAbsensi.setLibur(formData));
      // eslint-disable-next-line no-unused-vars
      api.onSuccess(function(response) {
        context.getAbsen();
        context.notify('Created Success', 'success')
      // eslint-disable-next-line no-unused-vars
      }).onError(function(error) {                    
        context.notify('Created Failed', 'error')
      }).onFinish(function() { 
         context.onLoading = false; 
         context.formLibur.show = false;
      })
      .call();
    },
    updateAbsen(id, value, type, unit_user, type_hari){
      let api     = null;
      let context = this;

      api = Api(context, dataAbsensi.updateAbsen({
          id: id,
          value: value,
          type: type,
          unit_user: unit_user,
          type_hari: type_hari, 
      }));
      api.onSuccess(function(response) {
          context.notify('Update Success', 'success')
      }).onError(function(error) { 
          context.notify('Update Failed', 'error')
      }).onFinish(function() {  
          context.getAbsen();
      })
      .call();
    },
    notify(message, type) {
      if (type == 'success') {
        toast.success(message, {
          autoClose: 2000,
        }); // ToastOptions
      }else{
        toast.error(message, {
          autoClose: 2000,
        }); // ToastOptions
      }
    },
    setBg(id) {
      this.backgroundRed = id
    }
  }
};
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 500px;
  margin-top: auto;
  margin-bottom: auto;
}
.modal__title {
  font-size: 1rem;
  font-weight: 600;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
.scroll { 
  overflow: auto; 
  height: 600px; 
}
.scroll thead th { 
  position: sticky; 
  top: 0; 
  z-index: 100; 
  background-color: #6495ED; 
}

/*for freze ke samping*/
.scroll table td:first-child {
  position: sticky;
  left: 0;
  background-color: #ddd;
}
</style>
