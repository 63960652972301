<template>
  <div class="py-4 container-fluid">
    <!-- <a class="btn btn-sm btn-primary" style="margin-right: 10px;" :href="apiUrl+'export-excel/karyawan?id_karyawan='+search.id_karyawan+'&nama='+search.nama+'&nik='+search.nik+'&jabatan='+search.jabatan+'&unit='+search.unit+'&status='+search.status+''" target="_BLANK"><i class="fa fa-download fa-sm"></i> Export</a> -->
    <!-- <argon-button color="info" size="sm" class="mb-3" variant="gradient" style="margin-right: 10px;"><i class="fa fa-download fa-sm"></i> Export</argon-button> -->
    <!-- <argon-button color="warning" size="sm" class="mb-3" variant="gradient" @click="modalImport()"><i class="fa fa-upload fa-sm"></i> Import</argon-button> -->
    <div class=" row">
      <div class="col-12">
          <div class="card"> 
            <div class="row text-center">
              <div class="col-2">
              </div>
              <div class="col-8">
                <div class="card-header pb-0">
                  <h6 class="font-weight-bold">==== TRANSACTION DETAIL {{ $route.params.type.toUpperCase() }} ====</h6>
                </div>
              </div>
              <div class="col-2 text-center">
                <div class="card-header pb-0">
                  <a class="btn btn-sm btn-warning" style="margin-left: 0px;" :href="apiUrl+'print-pdf/transaction-detail?transaction_number='+dataTransaction.transaction_number+''" target="_BLANK"><i class="fa fa-print fa-sm"></i> Print</a> 
                </div>
              </div>
            </div><hr>
            
            <div class="card-body px-0 pt-0 pb-2 mt-4">
              <div class="row">
                <div class="col-lg-6 col-sm-12">
                  <div class="container">
                  <p style="margin-top: 10px; font-size: 13px; font-weight: bold; margin-right: -70px;">TRANSACTION NUMBER 
                    <span style="margin-left: 10px; margin-right: 10px;">:</span> <span class="badge bg-gradient-primary">{{ dataTransaction.transaction_number }}</span>
                  </p>
                  <p style="margin-top: -7px; font-size: 13px; font-weight: bold; margin-right: -70px;" v-if="$route.params.type == 'Reciev New'">PO NUMBER 
                    <span style="margin-left: 85px; margin-right: 10px;">:</span> {{ dataTransaction.po_number }}</p>
                  <p style="margin-top: -7px; font-size: 13px; font-weight: bold; margin-right: -70px;" v-if="$route.params.type == 'Reciev New'">DELIVERY NUMBER 
                    <span style="margin-left: 43px; margin-right: 10px;">:</span> {{ dataTransaction.delivery_order_number }}
                  </p>
                  <p style="margin-top: -7px; font-size: 13px; font-weight: bold; margin-right: -70px;" v-if="$route.params.type == 'Reciev New'">SUPPLIER 
                    <span style="margin-left: 105px; magin-right: 10px;">: </span> <span style="margin-left: 10px;"></span>{{ dataTransaction.supplier }}
                  </p>
                  <p style="margin-top: -7px; font-size: 13px; font-weight: bold; margin-right: -70px;" v-if="$route.params.type == 'Usage / Loan' || $route.params.type == 'Return'">PROJECT 
                    <span style="margin-left: 109px; magin-right: 10px;">: </span> <span style="margin-left: 10px;"></span>{{ dataTransaction.project }} - {{ dataTransaction.project_name }}
                  </p>
                  <p style="margin-top: -7px; font-size: 13px; font-weight: bold; margin-right: -70px;" v-if="$route.params.type == 'Usage / Loan' || $route.params.type == 'Return'">USER 
                    <span style="margin-left: 132px; magin-right: 10px;">: </span> <span style="margin-left: 10px;"></span>{{ dataTransaction.user }}
                  </p>
                  </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <p style="margin-top: 10px; font-size: 13px; font-weight: bold; margin-right:">WAREHOUSE LOCATION 
                    <span style="margin-left: 10px; margin-right: 10px;">:</span> {{ dataTransaction.warehouse_location }}
                  </p>
                  <p style="margin-top: -7px; font-size: 13px; font-weight: bold; margin-right:">PIC WAREHOUSE 
                    <span style="margin-left: 57px; margin-right: 10px;">:</span> {{ dataTransaction.pic_warehouse }}
                  </p>
                  <p style="margin-top: -7px; font-size: 13px; font-weight: bold; margin-right:">DATE 
                    <span style="margin-left: 130px; margin-right: 10px;">:</span> {{ dataTransaction.date }}
                  </p>
                  <p style="margin-top: -7px; font-size: 13px; font-weight: bold; margin-right:">STATUS 
                    <span style="margin-left: 114px; margin-right: 10px;">:</span> {{ dataTransaction.status }}
                  </p>
                </div>
                <!-- ITEM LIST -->
                <div>
                  <div class="container-fluid">
                  </div>
                  <div class="container-fluid">
                    <hr>
                    <div class="row mb-2">
                      <div class="col-4">
                        <div class="card-header pb-0">
                          <h6><i class="fas fa-list-ol" style="margin-right: 10px;"></i> Item List</h6>
                          <!-- <StreamBarcodeReader @decode="onDecode" @loaded="onLoaded"></StreamBarcodeReader> -->
                        </div>
                      </div>
                      <div class="col-3">
                        <input type="text" class="form-control mt-3" placeholder="Input Item Code Here" v-model="qrItemCode" v-on:keyup.enter="getItemSave(), qrItemCode = ''" :disabled="dataTransaction.status == 'Completed'" ref="focusQr">
                      </div>
                      <div class="col-5 float-right">
                        <argon-button
                          style="margin-right: 10px; margin-left: 60px;"
                          class="mt-4"
                          variant="gradient"
                          color="warning"
                          size="sm"
                          @click="fixData()"
                          :disabled="dataTransaction.status == 'Completed'"
                        ><i class="fa fa-check-circle-o" aria-hidden="true"></i> Fixed Data</argon-button>
                        <argon-button
                          class="mt-4"
                          variant="gradient"
                          color="success"
                          size="sm"
                          @click="create()"
                          :disabled="dataTransaction.status == 'Completed'"
                        ><i class="fa fa-plus fa-sm" aria-hidden="true"></i> Add New</argon-button>
                      </div>
                    </div>
                    <div class="table-responsive p-0 scroll">
                      <table class="table table-hover align-items-center mb-0">
                        <thead>
                          <tr>
                            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">No</th>
                            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Image</th>
                            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Code</th>
                            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Name</th>
                            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Description</th>
                            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Category</th>
                            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">UOM</th>
                            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">QTY</th>
                            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder" v-if="$route.params.type == 'Reciev New'">Unit Price</th>
                            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(row, i) in table.data" :key="i">
                            <td class="align-middle text-center text-sm">
                              <span class="text-secondary text-xs font-weight-bold">{{ i + 1 }}</span>
                            </td>
                            <td class="align-middle text-center text-sm">
                              <img
                                :src="storageUrl+'/image_warehouse/'+row.image"
                                class="avatar avatar-lg me-1"
                                alt="user1"
                              />
                            </td>
                            <td class="align-middle text-center text-sm">
                              <router-link :to="/items-detail/+row.code">
                                <span class="badge bg-gradient-primary">{{ row.code }}</span>
                              </router-link>
                            </td>
                            <td class="align-middle text-center text-sm">
                              <span class="text-secondary text-xs font-weight-bold">{{ row.name }}</span>
                            </td>
                            <td class="align-middle text-center text-sm">
                              <span class="text-secondary text-xs font-weight-bold">{{ row.description }}</span>
                            </td>
                            <td class="align-middle text-center text-sm">
                              <span class="text-secondary text-xs font-weight-bold">{{ row.category }}</span>
                            </td>
                            <td class="align-middle text-center text-sm">
                              <span class="text-secondary text-xs font-weight-bold">{{ row.uom_code }}</span>
                            </td>
                            <td class="align-middle text-center text-sm">
                              <span class="badge bg-gradient-info">{{ row.qty }}</span>
                            </td>
                            <td class="align-middle text-center text-sm" v-if="$route.params.type == 'Reciev New'">
                              <span class="text-secondary text-xs font-weight-bold"> Rp. {{ convertRp(row.unit_price) }}</span>
                            </td>
                            <td class="align-middle text-center">
                              <!-- <i class="fas fa-edit fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="edit(row.id)" title="Edit"></i> -->
                              <i class="fa fa-trash fa-sm" aria-hidden="true" title="Hapus" style="cursor: pointer; margin-right: 20px;" @click="remove(row.id)" v-if="dataTransaction.status === 'Draf'"></i>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="card-footer">
            </div>
          </div>
      </div>
    </div>
  </div>

  <!-- =======  MODAL ADD DATA ======= -->
  <div class="container">
    <vue-final-modal v-model="form.show" classes="modal-container" content-class="modal-content" :z-index="10000" :focus-retain="false">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{form.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="form.show = false, $refs.focusQr.focus()"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3" v-if="$route.params.type == 'Return'">From Warehouse Location </label>
        <multiselect v-model="itemData.from_warehouse_location" :options="tableFromWarehouseLocation.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Item" label="name" track-by="name" :preselect-first="true" @keyup="getFromWarehouseLocation" v-if="$route.params.type == 'Return'">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
        <small style="color: red; font-size: 12px;" v-if="$route.params.type == 'Return'">Note : Select Warehouse Location if warehouse withdrawal is different</small><br>
        <label for="example-text-input" class="form-control-label mt-3">Item Name <span style="color: red;">*</span></label>

        <multiselect v-model="itemData.item_name" :options="tableItem.data" :multiple="false" :close-on-select="true" :clear-on-select="true" :preserve-search="true" placeholder="Select Item" label="code" track-by="code" :preselect-first="true" @keyup="getItem" :custom-label="nameWithLang" @click="itemData.qty = '' ">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>

        <label for="example-text-input" class="form-control-label mt-3">UOM </label>
        <input type="text" class="form-control" placeholder="UOM" v-model="itemData.uom_code" disabled>

        <label for="example-text-input" class="form-control-label mt-3">Qty <span style="color: red;">*</span></label>
        <input type="number" class="form-control" placeholder="Qty" v-model="itemData.qty" @click="setMaxQty" :max="qtyMax" min="0" v-on:keyup.enter="save()" ref="focusQty">
        <small style="color: red; font-size: 12px;" v-if="qtyMax">Note : Qty Maximum {{ qtyMax }}</small><br>
        <label for="example-text-input" class="form-control-label " v-if="$route.params.type == 'Reciev New'">Unit Price <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Unit Price" v-model="itemData.unit_price" v-if="$route.params.type == 'Reciev New'" v-on:keyup.enter="save()">
        <small style="color: red; font-size: 12px;" v-if="$route.params.type == 'Reciev New'">Note : for currency except IDR, please convert currency first.</small><br>
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="itemData = {}, qtyMax = '', tableItem.data = []">Clear</argon-button>
        </div>
        <div class="col-1">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="save()" :disabled="onLoading">
            <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
            <span v-else>
                <span>Save</span>
            </span>
          </argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

   <!-- =======  MODAL FILTER ======= -->
  <div class="container">
    <vue-final-modal v-model="formFilter.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formFilter.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formFilter.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Name</label>
        <input type="text" class="form-control" placeholder="Name" v-model="search.name" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Address</label>
        <input type="text" class="form-control" placeholder="Address" v-model="search.address" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Phone Number</label>
        <input type="text" class="form-control" placeholder="Phone Number" v-model="search.phone_number" v-on:keyup.enter="get()">
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="search = {}">Clear</argon-button>
        </div>
        <div class="col-1">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="get()">Filter</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
import { VueFinalModal } from 'vue-final-modal'
// import ArgonPagination from "@/components/ArgonPagination.vue";
// import ArgonPaginationItem from "@/components/ArgonPaginationItem.vue";
import Api from '@/helpers/api';
import config from '@/configs/config';
import { toast } from 'vue3-toastify';
// import { StreamBarcodeReader } from "vue-barcode-reader";
import 'vue3-toastify/dist/index.css';
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css';
import transactionInventory from '@/services/transactionInventory.service';
import inventoryList from '@/services/inventoryList.service';
import warehouseLocation from '@/services/warehouseLocation.service';

export default {
  name: "tables",
  components: {
    ArgonButton,
    VueFinalModal,
    Multiselect,
    // StreamBarcodeReader
    // ArgonPagination,
    // ArgonPaginationItem,
  },
  data() {
    return {
      table: {
        data: []
      },
      form: {
        add: true,
        title: "Add Data",
        show: false
      },
      formImport: {
        add: true,
        title: "Import Data Setting Approval",
        show: false
      },
      formFilter: {
        add: true,
        title: "Filter",
        show: false
      },
      dataImport: '',
      onLoading: false,
      tabelError: {
        data: []
      },
      storageUrl : config.storageUrl,
      dataTransaction: {},
      itemData: {},
      search: {
        code: '',
        name: '',
        address: '',
      },
      apiUrl :config.apiUrl,
      tableItem: {
        data: []
      },
      tableFromWarehouseLocation: {
        data: []
      },
      qtyMax: '',
      qrItemCode: '',

    };
  },
  mounted(){
    this.get();
    this.tokenApi = 'Bearer '+localStorage.getItem('token');
    this.$refs.focusQr.focus()
  },
  methods: {
    nameWithLang ({ code, name }) {
      return `${code} - ${name}`
    },
    get(param){
      let context = this;               
      Api(context, transactionInventory.transactionDetail({transaction_number: context.$route.params.code})).onSuccess(function(response) {    
          context.dataTransaction = response.data.data.data;
          context.table.data      = response.data.data.dataListItem;
          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.dataTransaction = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
      })
      .call()
    },
    getItem(param){
      let context = this;   
      let WhLoc   = (context.itemData.from_warehouse_location == undefined) ? context.dataTransaction.warehouse_location : context.itemData.from_warehouse_location['name']

      Api(context, inventoryList.getItem({item_name: param.target.value, type_transaction: context.$route.params.type, warehouse_location: WhLoc, user: context.dataTransaction.user, project: context.dataTransaction.project})).onSuccess(function(response) {    
          context.tableItem.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableItem.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    getFromWarehouseLocation(param){
      let context = this;        
      Api(context, warehouseLocation.getFromWarehouseLocation({search: param.target.value, item_name: '', type_transaction: context.$route.params.type, user: context.dataTransaction.user, project: context.dataTransaction.project})).onSuccess(function(response) {    
          context.tableFromWarehouseLocation.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableFromWarehouseLocation.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    // for qr code
    getItemSave(param){
      let context  = this;   
      let WhLoc    = (context.itemData.from_warehouse_location == undefined) ? context.dataTransaction.warehouse_location : context.itemData.from_warehouse_location['name']
      let itemCode = (context.qrItemCode.length == 45) ? context.qrItemCode.slice(38) : context.qrItemCode;

      Api(context, inventoryList.getItemSave({item_code: itemCode, type_transaction: context.$route.params.type, warehouse_location: WhLoc, user: context.dataTransaction.user, project: context.dataTransaction.project})).onSuccess(function(response) {    
          context.create()
          context.itemData.item_name = response.data.data[0];
          context.tableItem.data     = response.data.data;
          context.setMaxQty()
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableItem.data = [];
          }
      }).onFinish(function() { 
          context.$refs.focusQty.focus()
      })
      .call()
    },
    filter() {
      this.formFilter.add   = true;
      this.formFilter.show  = true;
      this.formFilter.title = "Filter";
      this.onLoading        = false;
    },
    create() {
      this.form.add       = true;
      this.form.show      = true;
      this.form.title     = "Add Data";
      this.itemData       = {};
      this.tableItem.data = []
    },
    edit(id) {
      let context = this;               
      Api(context, transactionInventory.show(id)).onSuccess(function(response) {
          context.itemData   = response.data.data;
          context.form.show  = true;
          context.form.title = 'Edit Data';   
      })
      .call()        
    },
    save(){
      let api           = null;
      let context       = this;
      let formData      = new FormData();
      context.onLoading = true;

      if (context.$route.params.type != 'Reciev New' && context.itemData.qty > context.qtyMax) {
        return context.notify('The quantity you entered exceeds the maximum quantity !!!', 'error')
      }

      if (context.itemData.item_name != undefined && context.itemData.qty != undefined) {
        formData.append('transaction_number', context.$route.params.code);
        formData.append('item_name', JSON.stringify(context.itemData.item_name));
        formData.append('qty', context.itemData.qty);
        formData.append('unit_price', context.itemData.unit_price);

        // jika return change location
        formData.append('transaction_type', context.$route.params.type);
        formData.append('project', context.dataTransaction.project);
        formData.append('user', context.dataTransaction.user);
        formData.append('warehouse_location', context.dataTransaction.warehouse_location);
        formData.append('from_warehouse_location', (JSON.stringify(context.itemData.from_warehouse_location) == undefined) ? '' : JSON.stringify(context.itemData.from_warehouse_location));
      }else{
        return alert('The Red Star Field is Mandatory')
      }

      if (context.form.title == 'Add Data') {
          api = Api(context, transactionInventory.createItem(formData));
      } else {
          api = Api(context, transactionInventory.update(this.itemData.id, formData));
      }
      // eslint-disable-next-line no-unused-vars
      api.onSuccess(function(response) {
        context.get();
        context.form.show = false;
        context.$refs.focusQr.focus()
        context.notify((context.form === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      // eslint-disable-next-line no-unused-vars
      }).onError(function(error) { 
        context.notify((context.form != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() { 
        context.onLoading = false;
      })
      .call();
    },
    fixData(){
      let api      = null;
      let context  = this;

      api = Api(context, transactionInventory.fixData({transaction_number: context.$route.params.code}));
      // eslint-disable-next-line no-unused-vars
      api.onSuccess(function(response) {
        context.get();
        context.notify((context.form === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      // eslint-disable-next-line no-unused-vars
      }).onError(function(error) { 
        context.notify((context.form != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
      })
      .call();
    },
    setMaxQty(){
      if (this.itemData.item_name) {
        this.itemData.uom_code = this.itemData.item_name['uom_code']
        
        if (this.$route.params.type == 'Usage / Loan') {
          this.qtyMax = (JSON.stringify(Number(this.itemData.item_name['total_qty_item_count'])) - JSON.stringify(Number(this.itemData.item_name['total_qty_item_on_loan_count'])))
        }else if(this.$route.params.type == 'Return'){
          if (this.itemData.item_name['category'] === 'Consumable') {
            this.qtyMax = 9999
          }else{
            this.qtyMax = JSON.stringify(Number(this.itemData.item_name['total_qty_item_on_loan_count']))
          }
        }else if (this.$route.params.type == 'Reduction') {
          this.qtyMax = JSON.stringify(Number(this.itemData.item_name['total_qty_item_count']))
        }
      }
    },
    remove(id) {
      var r = confirm("Are you sure you want to delete this data ?");
      if (r == true) {
        let context  = this;
        let formData = new FormData();

        formData.append('transaction_type', context.$route.params.type);
        formData.append('warehouse_location', context.dataTransaction.warehouse_location);

        Api(context, transactionInventory.deleteItem(id, formData)).onSuccess(function(response) {
            context.get();
            context.notify('Data Successfully Deleted', 'success')
        }).call();
      }
    },
    modalImport(){
      this.formImport.show  = true;
      this.formImport.title = "Import Data Setting Approval";
      this.tabelError.data  = [];
    },
    filesChange(e) {
        this.dataImport = e.target.files[0];
    },
    importData(){
      let api = null;
      let context = this;
      let formData = new FormData();
      this.onLoading = true;

      if (this.dataImport != undefined) {
        formData.append('import_data', this.dataImport);
      }else{
        return alert('File Import Not Found')
      }

      api = Api(context, transactionInventory.import(formData));
      api.onSuccess(function(response) {
          context.onLoading = false;
          context.get();
          context.formImport.show = false;
          context.notify('Import Data Success', 'success')
      }).onError(function(error) {      
          context.tabelError.data = error.response.data.data;              
          context.notify('Import Data Failed', 'error')
          context.onLoading = false;
      }).onFinish(function() {  
      })
      .call();
    },
    convertRp(bilangan) {
      if (bilangan) {
        var number_string = bilangan.toString(),
            sisa    = number_string.length % 3,
            rupiah  = number_string.substr(0, sisa),
            ribuan  = number_string.substr(sisa).match(/\d{3}/g);

        if(ribuan){
          var separator = sisa ? '.' : '';
          rupiah += separator + ribuan.join('.');
          return rupiah
        }else{
          return bilangan
        }
      }
    },
    notify(message, type) {
      if (type == 'success') {
        toast.success(message, {
          autoClose: 2000,
        }); // ToastOptions
      }else{
        toast.error(message, {
          autoClose: 2000,
        }); // ToastOptions
      }
    },
    // onDecode(a, b, c) {
    //   console.log(a, b, c);
    //   this.qrItemCode = a
    //   this.getItemSave()
    //   this.qrItemCode = '';
    //   this.text = a;
    //   if (this.id) clearTimeout(this.id);
    //   this.id = setTimeout(() => {
    //     if (this.text === a) {
    //       this.text = "";
    //     }
    //   }, 5000);
    // },

  }
};
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 500px;
  margin-top: auto;
  margin-bottom: auto;
}
.modal__title {
  font-size: 1rem;
  font-weight: 600;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
.scroll { 
  overflow: auto; 
  height: 600px; 
}
.scroll thead th { 
  position: sticky; 
  top: 0; 
  z-index: 100; 
  background-color: #F0F8FF;
}
</style>
