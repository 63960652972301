import Service from '@/helpers/service'

export default {

    index(params) {
        return Service().get('vendor-list/index', {params});
    },
    getVendor(params) {
        return Service().get('vendor-list/get-vendor-list', {params});
    },
    show(id) {
        return Service().get('vendor-list/show/'+id);
    },
    create(params) {
        return Service().post('vendor-list/create', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    update(id, params) {
        return Service().post('vendor-list/update/'+id, params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    delete(id) {
        return Service().post('vendor-list/delete/'+id);
    },
    createUpdate(params) {
        return Service().post('vendor-list/create-or-update-vendor', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
}