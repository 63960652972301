import Service from '@/helpers/service'

export default {

    index(params) {
        return Service().get('purchasing-requisition/index', {params});
    },
    getPrNo(params) {
        return Service().get('purchasing-requisition/get-pr-no', {params});
    },
    searchPr(params) {
        return Service().get('purchasing-requisition/get-purchasing-requisition', {params});
    },
    create(params) {
        return Service().post('purchasing-requisition/create', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    delete(id, params) {
        return Service().post('purchasing-requisition/delete/'+id, params);
    },
    // ------- ITEM ROUTE -----
    transactionDetail(params) {
        return Service().get('purchasing-requisition/transaction-detail', {params});
    },
    showItem(id) {
        return Service().get('purchasing-requisition/show-item/'+id);
    },
    deleteItem(id, params) {
        return Service().post('purchasing-requisition/delete-item/'+id, params);
    },
    prApproved(params) {
        return Service().post('purchasing-requisition/pr-approved', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    saveRevision(params) {
        return Service().post('purchasing-requisition/save-item-revision', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    showRevision(item_pr_code) {
        return Service().get('purchasing-requisition/show-revision/'+item_pr_code);
    },
    import(params) {
        return Service().post('import/pr-item', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
     // ------- DASHBOARD -----
    getMonitoringPrItem(params) {
        return Service().get('purchasing-requisition/get-monitoring-pr-item', {params});
    },
}