<template>
  <div class="py-4 container-fluid">
    <a class="btn btn-sm btn-primary" style="margin-right: 10px;" :href="apiUrl+'export-excel/daily-activity?project='+search.project['project_number']+'&activity='+search.activity['activity_name']+'&employee='+search.employee['nik']+'&leader='+search.leader+'&date='+search.date+'&remarks='+search.remarks+''" target="_BLANK"><i class="fa fa-file-excel-o"></i> Export to Excel</a>
    <a class="btn btn-sm btn-warning" style="margin-right: 10px;" :href="apiUrl+'print-pdf/daily-activity?project='+search.project['project_number']+'&activity='+search.activity['activity_name']+'&employee='+search.employee['nik']+'&leader='+search.leader+'&date='+search.date+'&remarks='+search.remarks+''" target="_BLANK"><i class="fa fa-file-pdf-o"></i> Export to PDF</a>
    <div class=" row">
      <div class="col-12">
          <div class="card"> 
            <div class="row">
              <div class="col-lg-4">
                <div class="card-header pb-0">
                  <h6>Daily Activity</h6>
                </div>
              </div>
              <div class="col-lg-4">
              </div>
              <div class="col-lg-4 float-right">
                <argon-button
                  style="margin-right: 10px; margin-left: 60px;"
                  class="mt-4"
                  variant="gradient"
                  color="secondary"
                  size="sm"
                  @click="filter()"
                ><i class="fa fa-filter fa-sm" aria-hidden="true"></i> Filter</argon-button>
                <argon-button
                  class="mt-4"
                  variant="gradient"
                  color="success"
                  size="sm"
                  @click="create()"
                ><i class="fa fa-plus fa-sm" aria-hidden="true"></i> Add New</argon-button>
              </div>
            </div>
            
            <div class="card-body px-0 pt-0 pb-2 mt-4">
              <div class="table-responsive p-0 scroll">
                <table class="table table-hover table-striped align-items-center mb-0">
                  <thead>
                    <tr>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">No</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Project</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Employee</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Activity</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Total Hour</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Remarks</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, i) in table.data" :key="i">
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ i + 1 }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold"><span class="font-weight-bold text-dark">PROJECT NO &nbsp;:&nbsp; </span>  {{ row.project_number }} </span><br>
                        <span class="text-secondary text-xs font-weight-bold"><span class="font-weight-bold text-dark">PROJECT &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp; </span>  {{ row.project_name }} </span><br>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-dark text-xs font-weight-bold"> {{ row.employee_name }} </span><br> 
                        <span class="text-secondary text-xs font-weight-bold"><span class="font-weight-bold text-dark">GROUP &nbsp; :&nbsp; </span>  {{ row.group_name }} </span><br>
                        <span class="text-secondary text-xs font-weight-bold"><span class="font-weight-bold text-dark">LEADER &nbsp;:&nbsp; </span>  {{ row.leader_name }} </span><br>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-dark text-xs font-weight-bold"> {{ row.activity_name }} </span><br>
                        <span class="text-secondary text-xs font-weight-bold"><span class="font-weight-bold text-dark">START &nbsp;:&nbsp; </span>  {{ moment(row.start_working).locale('id').format('lll') }} WIB </span><br>
                        <span class="text-secondary text-xs font-weight-bold"><span class="font-weight-bold text-dark">END &nbsp;&nbsp;&nbsp;&nbsp;:&nbsp; </span>  {{ moment(row.end_working).locale('id').format('lll') }} WIB </span><br>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">
                          {{ row.working_hour }} Hour {{ row.working_minute }} Minute <br>
                          <span v-if="row.break_minutes != '0'">Break : {{ row.break_minutes }} Minute</span>
                        </span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">
                          <span class="text-secondary text-xs font-weight-bold"><span class="font-weight-bold text-dark">{{ row.remarks }} </span></span><br>
                          <template v-if="row.attachment">
                            <span class="text-secondary text-xs font-weight-bold"><span class="font-weight-bold text-dark">ATTACHMENT &nbsp;:&nbsp; </span> <a :href="storageUrl+'/wh_attachment/'+row.attachment" target="_BLANK" style="color: blue;">See Attachment</a></span><br>
                          </template>
                          <template v-else>
                            <span class="text-secondary text-xs font-weight-bold"><span class="font-weight-bold text-danger">NO ATTACHMENT </span></span>
                          </template>
                        </span>
                      </td>
                      <td class="align-middle text-center">
                        <i class="fas fa-edit fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="edit(row.id)" title="Edit"></i>
                        <i class="fa fa-trash fa-sm" aria-hidden="true" title="Hapus" style="cursor: pointer; margin-right: 20px;" @click="remove(row.id)"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer">
              <div class="float-right">
                <span class="font-weight-bold text-danger ">TOTAL WORKING HOUR : {{ totalWorkingHour }} Hour {{ totalWorkingMinutes }} Minutes</span>
              </div>
              <div class="float-right">
                <base-pagination :page-count="pagination.page_count" :value="pagination.page" @input="changePage" :total="pagination.page_count" class="justify-content-end"></base-pagination>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>

  <!-- =======  MODAL ADD DATA ======= -->
  <div class="container">
    <vue-final-modal v-model="form.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{form.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="form.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Project <span style="color: red;">*</span></label>
        <multiselect v-model="dailyActivityData.project" :options="tableProject.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Project" :custom-label="nameWithLang" label="project_name" track-by="project_name" :preselect-first="true" @keyup="getProject">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
        <label for="example-text-input" class="form-control-label mt-3">Activity <span style="color: red;">*</span></label>
        <multiselect v-model="dailyActivityData.activity" :options="tableActivity.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Activity" label="activity_name" track-by="activity_name" :preselect-first="true" @keyup="getActivity">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
        <label for="example-text-input" class="form-control-label mt-3">Employee <span style="color: red;">*</span></label>
        <div class="row">
          <div class="col-6">
            <multiselect v-model="dailyActivityData.group" :options="tableGroup.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Group" label="group_name" track-by="group_name" :preselect-first="true" @keyup="getGroup">
              <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
            </multiselect>
          </div>
          <div class="col-6">
            <multiselect v-model="dailyActivityData.employee" :options="tableEmployee.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Employee" label="name" track-by="name" :preselect-first="true" @click="getGroupMember" @keyup="getGroupMember" :disabled="!dailyActivityData.group">
              <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
            </multiselect>
          </div>
        </div>

        <label for="example-text-input" class="form-control-label mt-3">Start Working <span style="color: red;">*</span></label>
        <div class="row">
          <div class="col-6">
            <input type="date" class="form-control" placeholder="Start Working" v-model="dailyActivityData.start_working_date">
          </div>
          <div class="col-6">
            <flat-pickr :config="{ enableTime: 'true', noCalendar: 'true', dateFormat: 'H:i', time_24hr: 'true',}" class="form-control" v-model="dailyActivityData.start_working_time"/>
          </div>
        </div>
        <label for="example-text-input" class="form-control-label mt-3">End Working <span style="color: red;">*</span></label>
        <div class="row">
          <div class="col-6">
            <input type="date" class="form-control" placeholder="End Working" v-model="dailyActivityData.end_working_date">
          </div>
          <div class="col-6">
            <flat-pickr :config="{ enableTime: 'true', noCalendar: 'true', dateFormat: 'H:i', time_24hr: 'true',}" class="form-control" v-model="dailyActivityData.end_working_time"/>
          </div>
        </div>
        <label for="example-text-input" class="form-control-label mt-3">Attachment </label>
        <input type="file" class="form-control" placeholder="Attachment" @change="filesChange" >
        <label for="example-text-input" class="form-control-label mt-3">Remarks </label>
        <textarea class="form-control" v-model="dailyActivityData.remarks" placeholder="Remarks"></textarea>
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-4"> 
        </div>
        <div class="col-3" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="form.show = true">Close</argon-button>
        </div>
        <div class="col-2">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="save()">Save</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

  <!-- =======  MODAL FILTER ======= -->
  <div class="container">
    <vue-final-modal v-model="formFilter.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formFilter.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formFilter.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Project</label>
        <multiselect v-model="search.project" :options="tableProject.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Project" :custom-label="nameWithLang" label="project_name" track-by="project_name" :preselect-first="true" @keyup="getProject"  v-on:keyup.enter="get()">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
        <label for="example-text-input" class="form-control-label mt-3">Activity</label>
        <multiselect v-model="search.activity" :options="tableActivity.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Activity" label="activity_name" track-by="activity_name" :preselect-first="true" @keyup="getActivity"  v-on:keyup.enter="get()">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
        <label for="example-text-input" class="form-control-label mt-3">Employee</label>
        <multiselect v-model="search.employee" :options="tableEmployee.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Employee" label="name" track-by="name" :preselect-first="true" @keyup="getEmployee" v-on:keyup.enter="get()">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
        <label for="example-text-input" class="form-control-label mt-3">Leader</label>
        <input type="text" class="form-control" placeholder="Leader" v-model="search.leader" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Date Range</label>
        <flat-pickr :config="{ mode: 'range',}" class="form-control" v-model="search.date"/>
        <label for="example-text-input" class="form-control-label mt-3">Remarks</label>
        <input type="text" class="form-control" placeholder="Remarks" v-model="search.remarks" v-on:keyup.enter="get()">
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-4"> 
        </div>
        <div class="col-3" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="search = {}">Clear</argon-button>
        </div>
        <div class="col-2">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="get()">Filter</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

</template>

<script>

import ArgonButton from "@/components/ArgonButton.vue";
import { VueFinalModal } from 'vue-final-modal'
// import ArgonPagination from "@/components/ArgonPagination.vue";
// import ArgonPaginationItem from "@/components/ArgonPaginationItem.vue";
import Api from '@/helpers/api';
import config from '@/configs/config';
import { toast } from 'vue3-toastify';
import Multiselect from 'vue-multiselect'
import 'vue3-toastify/dist/index.css';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import BasePagination from "@/components/BasePagination";
var moment = require('moment');
import dailyActivity from '@/services/dailyActivity.service';
import projectList from '@/services/projectList.service';
import activityList from '@/services/activityList.service';
import workingGroup from '@/services/workingGroup.service';
import workingGroupMember from '@/services/workingGroupMember.service';


export default {
  name: "tables",
  components: {
    ArgonButton,
    VueFinalModal,
    Multiselect,
    flatPickr,
    BasePagination
  },
  data() {
    return {
      pagination: {
        page_count: '',
        page: 1,
      },
      moment:moment,
      table: {
        data: []
      },
      tableProject: {
        data: []
      },
      tableActivity: {
        data: []
      },
      tableGroup: {
        data: []
      },
      tableEmployee: {
        data: []
      },
      form: {
        add: true,
        title: "Add Data",
        show: false
      },
      formImport: {
        add: true,
        title: "Import Data Setting Approval",
        show: false
      },
      formFilter: {
        add: true,
        title: "Filter",
        show: false
      },
      dataImport: '',
      onLoading: false,
      tabelError: {
        data: []
      },
      storageUrl : config.storageUrl,
      dailyActivityData: {},
      search: {
        project: '',
        activity: '',
        employee: '',
        leader: '',
        date: '',
        remarks: '',
      },
      searchMenu: '',
      apiUrl :config.apiUrl,
      totalWorkingHour: '',
      totalWorkingMinutes: '',

    };
  },
  mounted(){
    this.get();
    this.tokenApi = 'Bearer '+localStorage.getItem('token');
  },
  methods: {
    nameWithLang ({ project_number, project_name }) {
      return `${project_number} | ${project_name}`
    },
    get(param){
      let context = this;               
      Api(context, dailyActivity.index({project: this.search.project['project_number'], activity: this.search.activity['activity_name'], employee: this.search.employee['nik'], leader: this.search.leader, date: this.search.date, remarks: this.search.remarks, page: context.pagination.page})).onSuccess(function(response) {    
          context.table.data            = response.data.data.data.data;
          context.pagination.page_count = response.data.data.data.last_page
          context.totalWorkingHour      = response.data.data.totalHour;
          context.totalWorkingMinutes   = response.data.data.totalMinutes;

          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.table.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.formFilter.show  = false;
      })
      .call()
    },
    getProject(param){
      let context = this; 
      Api(context, projectList.getProject({search: param.target.value})).onSuccess(function(response) {      
          context.tableProject.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableProject.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    getActivity(param){
      let context = this; 
      Api(context, activityList.getActivity({search: param.target.value})).onSuccess(function(response) {       
          context.tableActivity.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableActivity.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    getGroup(param){
      let context = this;        
      Api(context, workingGroup.getGroup({search: param.target.value})).onSuccess(function(response) {    
          context.tableGroup.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableGroup.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    getGroupMember(param){
      let context = this;        
      Api(context, workingGroupMember.getGroupMember({search: context.dailyActivityData.group['code']})).onSuccess(function(response) {    
          context.tableEmployee.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableEmployee.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    filter() {
      this.formFilter.add   = true;
      this.formFilter.show  = true;
      this.formFilter.title = "Filter";
      this.onLoading        = false;
      this.pagination.page  = 1 ;
    },
    create() {
      this.form.add   = true;
      this.form.show  = true;
      this.form.title = "Add Data";
      this.dailyActivityData   = {};
    },
    edit(id) {
      let context = this;               
      Api(context, dailyActivity.show(id)).onSuccess(function(response) {
          context.dailyActivityData          = response.data.data.data;
          context.dailyActivityData.project  = response.data.data.dataProject
          context.dailyActivityData.activity = response.data.data.dataActivity
          context.dailyActivityData.group    = response.data.data.dataGroup
          context.dailyActivityData.employee = response.data.data.dataEmployee
          context.form.show                  = true;
          context.form.title                 = 'Edit Data';   
      })
      .call()        
    },
    save(){
      let api      = null;
      let context  = this;
      let formData = new FormData();

      if (context.dailyActivityData.project != undefined && context.dailyActivityData.activity != undefined && context.dailyActivityData.group != undefined && context.dailyActivityData.employee != undefined && context.dailyActivityData.start_working_date != undefined && context.dailyActivityData.start_working_time != undefined && context.dailyActivityData.end_working_date != undefined && context.dailyActivityData.end_working_time != undefined) {
        formData.append('project', JSON.stringify(context.dailyActivityData.project));
        formData.append('activity', JSON.stringify(context.dailyActivityData.activity));
        formData.append('group', JSON.stringify(context.dailyActivityData.group));
        formData.append('employee', JSON.stringify(context.dailyActivityData.employee));
        formData.append('start_working_date', context.dailyActivityData.start_working_date);
        formData.append('start_working_time', context.dailyActivityData.start_working_time);
        formData.append('end_working_date', context.dailyActivityData.end_working_date);
        formData.append('end_working_time', context.dailyActivityData.end_working_time);
        formData.append('attachment', context.dailyActivityData.attachment);
        formData.append('remarks', (context.dailyActivityData.remarks == undefined) ? '-' : context.dailyActivityData.remarks);
      }else{
        return alert('The Red Star Field is Mandatory')
      }

      if (context.form.title == 'Add Data') {
          api = Api(context, dailyActivity.create(formData));
      } else {
          api = Api(context, dailyActivity.update(this.dailyActivityData.id, formData));
      }
      // eslint-disable-next-line no-unused-vars
      api.onSuccess(function(response) {
        context.get();
        context.form.show = false;
        context.notify((context.form === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      // eslint-disable-next-line no-unused-vars
      }).onError(function(error) { 
        context.notify((context.form != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
      })
      .call();
    },
    remove(id) {
      var r = confirm("Are you sure you want to delete this data ?");
      if (r == true) {
        let context = this;
        Api(context, dailyActivity.delete(id)).onSuccess(function(response) {
            context.get();
            context.notify('Data Successfully Deleted', 'success')
        }).call();
      }
    },
    filesChange(e) {
        this.dailyActivityData.attachment = e.target.files[0];
    },
    changePage(page){
      this.pagination.page = page;
      this.get();
    },
    notify(message, type) {
      if (type == 'success') {
        toast.success(message, {
          autoClose: 2000,
        }); // ToastOptions
      }else{
        toast.error(message, {
          autoClose: 2000,
        }); // ToastOptions
      }
    },

  }
};
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 500px;
  margin-top: auto;
  margin-bottom: auto;
}
.modal__title {
  font-size: 1rem;
  font-weight: 600;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
.scroll { 
  overflow: auto; 
  height: 600px; 
}
.scroll thead th { 
  position: sticky; 
  top: 0; 
  z-index: 100; 
  background-color: #6495ED;
}
</style>
