import Service from '@/helpers/service'

export default {

    index(params) {
        return Service().get('setting-shift/index', {params});
    },
    show(id) {
        return Service().get('setting-shift/show/'+id);
    },
    create(params) {
        return Service().post('setting-shift/create', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    update(id, params) {
        return Service().post('setting-shift/update/'+id, params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    delete(id) {
        return Service().post('setting-shift/delete/'+id);
    },
    import(params) {
        return Service().post('import/setting-shift', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    saveShiftKaryawan(params) {
        return Service().get('setting-shift/save-setting-karyawan', {params});
    },
}