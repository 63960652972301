<template>
  <div class="py-4 container-fluid">
    <a class="btn btn-sm btn-success" style="margin-right: 10px;" :href="apiUrl+'export-excel/daily-activity?project_type='+search.project_type+'&po_number='+search.po_number+'&c_date='+search.c_date+'&client='+search.client+'&owner='+search.owner+'&site='+search.site+'&kinds='+search.kinds+'&due_date='+search.due_date+''" target="_BLANK"><i class="fa fa-file-excel-o"></i> Export to Excel</a>
    <a class="btn btn-sm btn-primary" style="margin-right: 10px;" :href="apiUrl+'print-pdf/daily-activity?project_type='+search.project_type+'&po_number='+search.po_number+'&c_date='+search.c_date+'&client='+search.client+'&owner='+search.owner+'&site='+search.site+'&kinds='+search.kinds+'&due_date='+search.due_date+''" target="_BLANK"><i class="fa fa-file-pdf-o"></i> Export to PDF</a>
    <div class=" row">
      <div class="col-12">
          <div class="card"> 
            <div class="row">
              <div class="col-lg-4">
                <div class="card-header pb-0">
                  <h6>Vendor List</h6>
                </div>
              </div>
              <div class="col-lg-4">
              </div>
              <div class="col-lg-4 float-right">
                <argon-button
                  style="margin-right: 10px; margin-left: 60px;"
                  class="mt-4"
                  variant="gradient"
                  color="secondary"
                  size="sm"
                  @click="filter()"
                ><i class="fa fa-filter fa-sm" aria-hidden="true"></i> Filter</argon-button>
                <argon-button
                  class="mt-4"
                  variant="gradient"
                  color="success"
                  size="sm"
                  @click="create()"
                ><i class="fa fa-plus fa-sm" aria-hidden="true"></i> Add New</argon-button>
              </div>
            </div>
            
            <div class="card-body px-0 pt-0 pb-2 mt-4">
              <div class="table-responsive p-0 scroll">
                <table class="table table-hover table-striped align-items-center mb-0">
                  <thead>
                    <tr>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">No</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">VENDOR CODE</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">COMPANY NAME</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">NPWP</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">CONTACT PERSON</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">ADDRES</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">EMAIL</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">NO HP</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">PHONE</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">FAX</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">CATEGORY</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">ITEM GROUP</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">NOTE</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, i) in table.data" :key="i">
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ i + 1 }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">  {{ row.vendor_code }} </span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">  {{ row.company_name }} </span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">  {{ row.npwp }} </span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">  {{ row.contact_person }} </span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">  {{ row.address }} </span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">  {{ row.email }} </span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">  {{ row.no_hp }} </span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">  {{ row.phone }} </span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">  {{ row.fax }} </span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">  {{ row.vendor_category }} </span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">  {{ row.item_group }} </span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">  {{ row.note }} </span>
                      </td>
                      <td class="align-middle text-center">
                        <i class="fas fa-edit fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="edit(row.id)" title="Edit"></i>
                        <i class="fa fa-trash fa-sm" aria-hidden="true" title="Hapus" style="cursor: pointer; margin-right: 20px;" @click="remove(row.id)"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer">
              <div class="float-right">
                <!-- <span class="font-weight-bold text-danger ">TOTAL WORKING HOUR : {{ totalWorkingHour }} Hour {{ totalWorkingMinutes }} Minutes</span> -->
              </div>
              <div class="float-right">
                <base-pagination :page-count="pagination.page_count" :value="pagination.page" @input="changePage" :total="pagination.page_count" class="justify-content-end"></base-pagination>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>

  <!-- =======  MODAL ADD DATA ======= -->
  <div class="container">
    <vue-final-modal v-model="form.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{form.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="form.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Company Name <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Company Name" v-model="vendorData.company_name">
        <label for="example-text-input" class="form-control-label mt-3">NPWP <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="NPWP" v-model="vendorData.npwp">
        <label for="example-text-input" class="form-control-label mt-3">Contact Person <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Contact Person" v-model="vendorData.contact_person">
        <label for="example-text-input" class="form-control-label mt-3">Address <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Address" v-model="vendorData.address">
        <label for="example-text-input" class="form-control-label mt-3">Email <span style="color: red;">*</span></label>
        <input type="email" class="form-control" placeholder="Email" v-model="vendorData.email">
        <label for="example-text-input" class="form-control-label mt-3">No HP </label>
        <input type="text" class="form-control" placeholder="No HP" v-model="vendorData.no_hp">
        <label for="example-text-input" class="form-control-label mt-3">Phone </label>
        <input type="text" class="form-control" placeholder="Phone" v-model="vendorData.phone">
        <label for="example-text-input" class="form-control-label mt-3">Fax </label>
        <input type="text" class="form-control" placeholder="Fax" v-model="vendorData.fax">
        <label for="example-text-input" class="form-control-label mt-3">Vendor Category <span style="color: red;">*</span></label>
        <select class="form-select" aria-label="Default select example" v-model="vendorData.vendor_category">
          <option value="Agent">Agent</option>
          <option value="Consumable">Consumable</option>
          <option value="Equipment">Equipment</option>
          <option value="Manufacture">Manufacture</option>
          <option value="Mill Maker">Mill Maker</option>
          <option value="Services">Services</option>
          <option value="Supplier">Supplier</option>
          <option value="Tools">Tools</option>
          <option value="Trading Company">Trading Company</option>
          <option value="Transport">Transport</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Item Group <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Item Group" v-model="vendorData.item_group">
        <label for="example-text-input" class="form-control-label mt-3">Note </label>
        <textarea class="form-control" v-model="vendorData.note" placeholder="Note"></textarea>
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-4"> 
        </div>
        <div class="col-3" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="form.show = true">Close</argon-button>
        </div>
        <div class="col-2">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="save()">Save</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
  </div>

  <!-- =======  MODAL FILTER ======= -->
  <div class="container">
    <vue-final-modal v-model="formFilter.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formFilter.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formFilter.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Vendor Code </label>
        <input type="text" class="form-control" placeholder="Vendor Code" v-model="search.vendor_code" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Company Name </label>
        <input type="text" class="form-control" placeholder="Company Name" v-model="search.company_name" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Contact Person </label>
        <input type="text" class="form-control" placeholder="Contact Person" v-model="search.contact_person" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Address </label>
        <input type="text" class="form-control" placeholder="Address" v-model="search.address" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Email </label>
        <input type="email" class="form-control" placeholder="Email" v-model="search.email" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Vendor Category </label>
        <select class="form-select" aria-label="Default select example" v-model="search.vendor_category" v-on:keyup.enter="get()">
          <option value="Agent">Agent</option>
          <option value="Consumable">Consumable</option>
          <option value="Equipment">Equipment</option>
          <option value="Manufacture">Manufacture</option>
          <option value="Mill Maker">Mill Maker</option>
          <option value="Services">Services</option>
          <option value="Supplier">Supplier</option>
          <option value="Tools">Tools</option>
          <option value="Trading Company">Trading Company</option>
          <option value="Transport">Transport</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Item Group </label>
        <input type="text" class="form-control" placeholder="Item Group" v-model="search.item_group" v-on:keyup.enter="get()">

        <!-- <label for="example-text-input" class="form-control-label mt-3">Date Range</label>
        <flat-pickr :config="{ mode: 'range',}" class="form-control" v-model="search.date"/> -->
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-4"> 
        </div>
        <div class="col-3" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="search = {}">Clear</argon-button>
        </div>
        <div class="col-2">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="get()">Filter</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

</template>

<script>

import ArgonButton from "@/components/ArgonButton.vue";
import { VueFinalModal } from 'vue-final-modal'
import Api from '@/helpers/api';
import config from '@/configs/config';
import { toast } from 'vue3-toastify';
// import Multiselect from 'vue-multiselect'
import 'vue3-toastify/dist/index.css';
// import flatPickr from 'vue-flatpickr-component';
// import 'flatpickr/dist/flatpickr.css';
import BasePagination from "@/components/BasePagination";
var moment = require('moment');
import vendorList from '@/services/procurement/vendorList.service';


export default {
  name: "tables",
  components: {
    ArgonButton,
    VueFinalModal,
    // Multiselect,
    // flatPickr,
    BasePagination
  },
  data() {
    return {
      pagination: {
        page_count: '',
        page: 1,
      },
      moment:moment,
      table: {
        data: []
      },
      tableProject: {
        data: []
      },
      tableActivity: {
        data: []
      },
      tableGroup: {
        data: []
      },
      tableEmployee: {
        data: []
      },
      form: {
        add: true,
        title: "Add Data",
        show: false
      },
      formImport: {
        add: true,
        title: "Import Data Setting Approval",
        show: false
      },
      formFilter: {
        add: true,
        title: "Filter",
        show: false
      },
      dataImport: '',
      onLoading: false,
      tabelError: {
        data: []
      },
      storageUrl : config.storageUrl,
      vendorData: {},
      search: {
        vendor_code: '',
        company_name: '',
        contact_person: '',
        address: '',
        email: '',
        vendor_category: '',
        item_group: '',
      },
      searchMenu: '',
      apiUrl :config.apiUrl,
      totalWorkingHour: '',
      totalWorkingMinutes: '',

    };
  },
  mounted(){
    this.get();
    this.tokenApi = 'Bearer '+localStorage.getItem('token');
  },
  methods: {
    nameWithLang ({ project_number, project_name }) {
      return `${project_number} | ${project_name}`
    },
    get(param){
      let context = this;               
      Api(context, vendorList.index({vendor_code: this.search.vendor_code, company_name: this.search.company_name, contact_person: this.search.contact_person, address: this.search.address, email: this.search.email, vendor_category: this.search.vendor_category, item_group: this.search.item_group, page: context.pagination.page})).onSuccess(function(response) {    
          context.table.data            = response.data.data.data.data;
          context.pagination.page_count = response.data.data.data.last_page

          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.table.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.formFilter.show  = false;
      })
      .call()
    },
    filter() {
      this.formFilter.add   = true;
      this.formFilter.show  = true;
      this.formFilter.title = "Filter";
      this.onLoading        = false;
      this.pagination.page  = 1 ;
    },
    create() {
      this.form.add   = true;
      this.form.show  = true;
      this.form.title = "Add Data";
      this.vendorData = {};
    },
    edit(id) {
      let context = this;               
      Api(context, vendorList.show(id)).onSuccess(function(response) {
          context.vendorData = response.data.data;
          context.form.show  = true;
          context.form.title = 'Edit Data';   
      })
      .call()        
    },
    save(){
      let api      = null;
      let context  = this;
      let formData = new FormData();

      if (context.vendorData.company_name != undefined && context.vendorData.npwp != undefined && context.vendorData.contact_person != undefined && context.vendorData.address != undefined && context.vendorData.email != undefined && context.vendorData.vendor_category != undefined && context.vendorData.item_group != undefined) {
        formData.append('company_name', context.vendorData.company_name);
        formData.append('npwp', context.vendorData.npwp);
        formData.append('contact_person', context.vendorData.contact_person);
        formData.append('address', context.vendorData.address);
        formData.append('email', context.vendorData.email);
        formData.append('no_hp', (context.vendorData.no_hp == undefined) ? '-' : context.vendorData.no_hp);
        formData.append('phone', (context.vendorData.phone == undefined) ? '-' : context.vendorData.phone);
        formData.append('fax', (context.vendorData.fax == undefined) ? '-' : context.vendorData.fax);
        formData.append('vendor_category', context.vendorData.vendor_category);
        formData.append('item_group', context.vendorData.item_group);
        formData.append('note', (context.vendorData.note == undefined) ? '-' : context.vendorData.note);
      }else{
        return alert('The Red Star Field is Mandatory')
      }

      if (context.form.title == 'Add Data') {
          api = Api(context, vendorList.create(formData));
      } else {
          api = Api(context, vendorList.update(this.vendorData.id, formData));
      }
      // eslint-disable-next-line no-unused-vars
      api.onSuccess(function(response) {
        context.get();
        context.form.show = false;
        context.notify((context.form === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      // eslint-disable-next-line no-unused-vars
      }).onError(function(error) { 
        context.notify((context.form != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
      })
      .call();
    },
    remove(id) {
      var r = confirm("Are you sure you want to delete this data ?");
      if (r == true) {
        let context = this;
        Api(context, vendorList.delete(id)).onSuccess(function(response) {
            context.get();
            context.notify('Data Successfully Deleted', 'success')
        }).call();
      }
    },
    filesChange(e) {
        this.vendorData.attachment = e.target.files[0];
    },
    changePage(page){
      this.pagination.page = page;
      this.get();
    },
    notify(message, type) {
      if (type == 'success') {
        toast.success(message, {
          autoClose: 2000,
        }); // ToastOptions
      }else{
        toast.error(message, {
          autoClose: 2000,
        }); // ToastOptions
      }
    },

  }
};
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 500px;
  margin-top: auto;
  margin-bottom: auto;
}
.modal__title {
  font-size: 1rem;
  font-weight: 600;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
.scroll { 
  overflow: auto; 
  height: 600px; 
}
.scroll thead th { 
  position: sticky; 
  top: 0; 
  z-index: 100; 
  background-color: #6495ED;
}
</style>
