import Service from '@/helpers/service'

export default {

    show(id) {
        return Service().get('attandance/show/'+id);
    },
    create(params) {
        return Service().post('attandance/create', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    update(id, params) {
        return Service().post('attandance/update/'+id, params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    deleteLog(id) {
        return Service().post('attandance/delete/'+id);
    },
}