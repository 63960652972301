import Service from '@/helpers/service'

export default {

    index(params) {
        return Service().get('storage-location/index', {params});
    },
    getItem(params) {
        return Service().get('storage-location/get-item', {params});
    },
    show(id) {
        return Service().get('storage-location/show/'+id);
    },
    create(params) {
        return Service().post('storage-location/create', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    update(id, params) {
        return Service().post('storage-location/update/'+id, params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    delete(id) {
        return Service().post('storage-location/delete/'+id);
    },
    itemsDetail(params) {
        return Service().get('storage-location/items-detail', {params});
    },
}