<template>
  <div class="py-4 container-fluid">
    <a :style="search.periode_start == '' ? 'margin-right: 10px; pointer-events: none; cursor: default;' : 'margin-right: 10px;'" style="margin-right: 10px;" :href="apiUrl+'export-excel/payroll?periode_start='+search.periode_start+'&periode_end='+search.periode_end+'&nama='+search.nama+''" target="_BLANK"><argon-button :disabled="search.periode_start == ''" color="primary" size="sm" class="mb-3" variant="gradient"><i class="fa fa-file-excel-o" style="margin-right: 5px;"></i> Export Excel</argon-button></a>
    <argon-button color="warning" size="sm" class="mb-3" variant="gradient" @click="changeNoteSlipGaji()"><i class="fa fa-cog" style="margin-right: 5px;"></i> Setting Note Slip Gaji</argon-button>
    <div class=" row mb-5">
      <div class="col-12">
          <div class="card"> 
            <div class="row">
              <div class="col-2">
                <div class="card-header pb-0">
                  <h6>Data Payroll</h6>
                </div>
              </div>
              <div class="col-5">
                <p style="margin-top: 25px; font-weight: bold; padding-left: 85px;">PERIODE {{ moment(search.periode_start).locale('id').format('D MMMM').toUpperCase() }} - {{ moment(search.periode_end).locale('id').format('D MMMM YYYY').toUpperCase() }}</p>
              </div>
              <div class="col-5 float-right">
                <argon-button
                  style="margin-right: 10px; margin-left: 100px;"
                  class="mt-4"
                  variant="gradient"
                  color="secondary"
                  size="sm"
                  @click="filter()"
                ><i class="fa fa-filter fa-sm" aria-hidden="true" style="margin-right: 5px;"></i> Filter</argon-button>
                <argon-button
                  class="mt-4"
                  variant="gradient"
                  color="success"
                  size="sm"
                  @click="sendSlip()"
                  :disabled="onLoadingSendSlip == true || search.periode_start == ''"
                >
                  <span v-if="onLoadingSendSlip"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
                  <span v-else>
                      <span><i class="fa fa-paper-plane fa-sm" style="margin-right: 5px;" aria-hidden="true"></i> Send Slip</span>
                  </span>
                </argon-button>
              </div>
            </div>
            
            <div class="card-body px-0 pt-0 pb-2 mt-4">
              <div class="table-responsive p-0 scroll">
                <table class="table align-items-center mb-0">
                  <thead>
                    <tr style="color: white;">
                      <th colspan="8" style="background-color: #FF7F50;" class="text-uppercase text-xxs font-weight-bolder text-center">Basic Data</th>
                      <th colspan="6" style="background-color: #7CFC00;" class="text-uppercase text-xxs font-weight-bolder text-center">Allowance</th>
                      <th rowspan="2" class="text-uppercase text-xxs font-weight-bolder align-middle">Total</th>
                      <th colspan="3" style="background-color: #FFB6C1;" class="text-uppercase text-xxs font-weight-bolder text-center">Over Time</th>
                      <th rowspan="2" class="text-uppercase text-xxs font-weight-bolder align-middle">Gross Salary</th>
                      <th colspan="9" style="background-color: #FF4500;" class="text-uppercase text-xxs font-weight-bolder text-center">Deduction</th>
                      <th rowspan="2" class="text-uppercase text-xxs font-weight-bolder align-middle">TOTAL Deduction</th>
                      <th colspan="8" style="background-color: #00CED1;" class="text-uppercase text-xxs font-weight-bolder text-center">Final Salary</th>
                    </tr>
                    <tr style="color: white;">
                      <th style="background-color: #FF7F50;" class="text-uppercase text-xxs font-weight-bolder">Employee Name</th>
                      <th style="background-color: #FF7F50;" class="text-uppercase text-xxs font-weight-bolder ">Department</th>
                      <th style="background-color: #FF7F50;" class="text-uppercase text-xxs font-weight-bolder ">Status</th>
                      <th style="background-color: #FF7F50;" class="text-uppercase text-xxs font-weight-bolder ">HK</th>
                      <th style="background-color: #FF7F50;" class="text-uppercase text-xxs font-weight-bolder ">ALPA</th>
                      <th style="background-color: #FF7F50;" class="text-uppercase text-xxs font-weight-bolder ">Basic Salary / Day</th>
                      <th style="background-color: #FF7F50;" class="text-uppercase text-xxs font-weight-bolder ">Total Salary / Day</th>
                      <th style="background-color: #FF7F50;" class="text-uppercase text-xxs font-weight-bolder ">Basic Salary / Month</th>
                      <th style="background-color: #7CFC00;" class="text-uppercase text-xxs font-weight-bolder ">Position</th>
                      <th style="background-color: #7CFC00;" class="text-uppercase text-xxs font-weight-bolder ">Health</th>
                      <th style="background-color: #7CFC00;" class="text-uppercase text-xxs font-weight-bolder ">Pension</th>
                      <th style="background-color: #7CFC00;" class="text-uppercase text-xxs font-weight-bolder ">Comunication</th>
                      <th style="background-color: #7CFC00;" class="text-uppercase text-xxs font-weight-bolder ">Transport</th>
                      <th style="background-color: #7CFC00;" class="text-uppercase text-xxs font-weight-bolder ">Meal</th>
                      <th style="background-color: #FFB6C1;" class="text-uppercase text-xxs font-weight-bolder align-middle">Basic Salary / Hour</th>
                      <th style="background-color: #FFB6C1;" class="text-uppercase text-xxs font-weight-bolder align-middle">Hour</th>
                      <th style="background-color: #FFB6C1;" class="text-uppercase text-xxs font-weight-bolder align-middle">Salary</th>
                      <th style="background-color: #FF4500;" class="text-uppercase text-xxs font-weight-bolder ">BPJS</th>
                      <th style="background-color: #FF4500;" class="text-uppercase text-xxs font-weight-bolder ">Borrow/exs</th>
                      <th style="background-color: #FF4500;" class="text-uppercase text-xxs font-weight-bolder ">Alpa</th>
                      <th style="background-color: #FF4500;" class="text-uppercase text-xxs font-weight-bolder ">Absen</th>
                      <th style="background-color: #FF4500;" class="text-uppercase text-xxs font-weight-bolder ">JHT 2 %</th>
                      <th style="background-color: #FF4500;" class="text-uppercase text-xxs font-weight-bolder ">JP 1 %</th>
                      <th style="background-color: #FF4500;" class="text-uppercase text-xxs font-weight-bolder ">Tax Status</th>
                      <th style="background-color: #FF4500;" class="text-uppercase text-xxs font-weight-bolder ">Tax By Employee</th>
                      <th style="background-color: #FF4500;" class="text-uppercase text-xxs font-weight-bolder ">Iuran Kematian</th>
                      <th style="background-color: #90EE90;" class="text-uppercase text-xxs font-weight-bolder ">NET PAY</th>
                      <th style="background-color: #90EE90;" class="text-uppercase text-xxs font-weight-bolder ">Sudah Di TF</th>
                      <!-- <th style="background-color: #90EE90;" class="text-uppercase text-xxs font-weight-bolder ">Kekurangan</th> -->
                      <th style="background-color: #90EE90;" class="text-uppercase text-xxs font-weight-bolder ">No Rek</th>
                      <th style="background-color: #90EE90;" class="text-uppercase text-xxs font-weight-bolder ">AN Rek</th>
                      <th style="background-color: #90EE90;" class="text-uppercase text-xxs font-weight-bolder ">Bank</th>
                      <th style="background-color: #90EE90;" class="text-uppercase text-xxs font-weight-bolder ">Slip Gaji</th>
                      <th style="background-color: #90EE90;" class="text-uppercase text-xxs font-weight-bolder ">Send Slip Gaji</th>
                      
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, i) in absenKaryawan.data" :key="i" @click="setBg(i)" :style="(backgroundRed == i) ? 'background-color: #87CEEB; cursor: pointer;' : 'cursor: pointer;' ">

                      <!-- =========================================== END BASIC DATA ================================== -->
                      <td style="position: sticky; left: 0; background-color: #ddd;">
                        <div class="d-flex px-2 py-1">
                          <div class="d-flex flex-column justify-content-center">
                            <h6 class="mb-0 text-sm">{{ row.name }}</h6>
                            <p class="text-xs text-secondary mb-0">{{ row.position}}</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <p class="text-xs font-weight-bold mb-0">{{ row.department }}</p>
                        <p class="text-xs font-weight-bold mb-0">{{ row.nik }}</p>
                      </td>
                      <!-- STATUS -->
                      <td class="align-middle text-center text-sm">
                        <span class="badge badge-sm bg-gradient-success">{{ row.employee_status }}</span>
                      </td>
                      <!-- HK -->
                      <td class="align-middle text-center text-sm">
                        <span class="badge badge-sm bg-gradient-info">{{ row.total_kerja_count }}</span>
                      </td>
                      <!-- ALPA -->
                      <td class="align-middle text-center text-sm">
                        <span class="badge badge-sm bg-gradient-danger">{{ row.total_alpa_count }}</span>
                      </td>
                      <!-- HARIAN -->
                      <td class="align-middle text-center">
                        <span class="text-black text-xs font-weight-bold">{{ convertRp(row.basic_day) }}</span>
                      </td>
                      <!-- TOTAL HARIAN -->
                      <td class="align-middle text-center">
                        <span class="text-black text-xs font-weight-bold">{{ convertRp(Math.round((row.basic_day * row.total_kerja_count))) }}</span>
                      </td>
                      <!-- BULANAN -->
                      <td class="align-middle text-center">
                        <span class="text-black text-xs font-weight-bold">{{ convertRp(row.basic_mounth) }}</span>
                      </td>
                      <!-- ALLOWANCE POSITION -->
                      <td class="align-middle text-center">
                        <span class="text-black text-xs font-weight-bold">{{ convertRp(row.position_allowance) }}</span>
                      </td>
                      <!-- ALLOWANCE HEALTH -->
                      <td class="align-middle text-center">
                        <span class="text-black text-xs font-weight-bold">{{ convertRp(row.health_allowance) }}</span>
                      </td>
                      <!-- ALLOWANCE PENSION-->
                      <td class="align-middle text-center">
                        <span class="text-black text-xs font-weight-bold">{{ convertRp(row.pension_allowance) }}</span>
                      </td>
                      <!-- ALLOWANCE COMMUNICATION -->
                      <td class="align-middle text-center">
                        <span class="text-black text-xs font-weight-bold">{{ convertRp(row.communication_allowance) }}</span>
                      </td>
                      <!-- ALLOWANCE TRANSPORT -->
                      <td class="align-middle text-center">
                        <span class="text-black text-xs font-weight-bold">{{ convertRp(row.transport_allowance) }}</span>
                      </td>
                      <!-- ALLOWANCE MEAL -->
                      <td class="align-middle text-center">
                        <span class="text-black text-xs font-weight-bold">{{ convertRp(row.meal_allowance) }}</span>
                      </td>

                      <!-- =========================================== TOTAL GAJI ================================== -->
                      <td class="align-middle text-center">
                        <span class="text-black text-xs font-weight-bold">
                          {{ convertRp(Number(
                            Math.round(((row.basic_day == 0) ? (row.basic_mounth) : (row.basic_day * row.total_kerja_count) )) +
                            row.position_allowance + 
                            row.health_allowance + 
                            row.pension_allowance + 
                            row.communication_allowance + 
                            row.transport_allowance + 
                            row.meal_allowance)) }}
                        </span>
                      </td>

                      <!-- =========================================== TOTAL GAJI ================================== -->
                      <!-- PER JAM OT -->
                      <td class="align-middle text-center">
                        <span class="text-black text-xs font-weight-bold">{{ convertRp(row.basic_hour) }}</span>
                      </td>
                      <!-- TOTAL OT -->
                      <td class="align-middle text-center">
                        <span class="text-black text-xs font-weight-bold">{{ row.total_ot_count }}</span>
                      </td>
                      <!-- LEMBUR -->
                      <td class="align-middle text-center">
                        <span class="text-black text-xs font-weight-bold">{{ convertRp(Math.round(row.total_ot_count * row.basic_hour)) }}</span>
                      </td>
                      <!-- GROSS SALARY -->
                      <td class="align-middle text-center">
                        <span class="text-black text-xs font-weight-bold" v-if="row.rel_payroll != null">
                          {{ convertRp(Number(
                            Math.round(((row.basic_day == 0) ? (row.basic_mounth) : (row.basic_day * row.total_kerja_count) )) + 
                            row.position_allowance + 
                            row.health_allowance + 
                            row.pension_allowance + 
                            row.communication_allowance + 
                            row.transport_allowance + 
                            row.meal_allowance) +
                            Math.round(row.total_ot_count * row.basic_hour) 
                          )}}
                        </span>
                      </td>

                      <!-- =========================================== DEDUCTION ================================== -->
                      <!-- BPJS -->
                      <td class="align-middle text-center">
                        <span class="text-black text-xs font-weight-bold">{{ convertRp(Math.round(row.basic_mounth * 0.01)) }}</span>
                      </td>
                      <!-- JHT -->
                      <td class="align-middle text-center">
                        <span class="text-black text-xs font-weight-bold">{{ convertRp(row.borrow) }}</span>
                      </td>
                      <!-- ALPA -->
                      <td class="align-middle text-center">
                        <span class="badge badge-sm bg-gradient-danger">{{ row.total_alpa_count }}</span>
                      </td>
                      <!-- ABSEN -->
                      <td class="align-middle text-center">
                        <span class="text-black text-xs font-weight-bold">{{ convertRp(Math.round(row.basic_mounth / 22 * row.total_alpa_count)) }}</span>
                      </td>
                      <!-- JHT -->
                      <td class="align-middle text-center">
                        <span class="text-black text-xs font-weight-bold">{{ convertRp(Math.round(row.basic_mounth * 0.02)) }}</span>
                      </td>
                      <!-- JP -->
                      <td class="align-middle text-center">
                        <span class="text-black text-xs font-weight-bold">{{ convertRp(Math.round(row.basic_mounth * 0.01)) }}</span>
                      </td>
                      <!-- TAX STATUS -->
                      <td class="align-middle text-center">
                        <span class="badge badge-sm bg-gradient-info">{{ row.tax_status }}</span>
                      </td>
                      <!-- TAX -->
                      <td class="align-middle text-center">
                        <span class="text-black text-xs font-weight-bold">{{ convertRp(taxEngine(row.tax_status, 
                          Number(
                            row.basic_mounth + 
                            row.position_allowance + 
                            row.health_allowance + 
                            row.pension_allowance + 
                            row.communication_allowance + 
                            row.transport_allowance + 
                            row.meal_allowance) +
                            Math.round(row.total_ot_count * row.basic_hour) ))
                          }}</span>
                      </td>
                      <!-- IURAN KEMATIAN -->
                      <td class="align-middle text-center">
                        <span class="text-black text-xs font-weight-bold">{{ convertRp(row.iuran_kematian) }}</span>
                      </td>
                      <!-- TOTAL POTONGAN -->
                      <td class="align-middle text-center">
                        <span class="text-black text-xs font-weight-bold">{{ 
                          convertRp(Number(Math.round(row.basic_mounth * 0.01) +
                          row.borrow +
                          Math.round(row.basic_mounth / 22 * row.total_alpa_count) + 
                          Math.round(row.basic_mounth * 0.02) +
                          Math.round(row.basic_mounth * 0.01) +
                          row.iuran_kematian +

                          Number(taxEngine(row.tax_status, 
                            row.basic_mounth + 
                            row.position_allowance + 
                            row.health_allowance + 
                            row.pension_allowance + 
                            row.communication_allowance + 
                            row.transport_allowance + 
                            row.meal_allowance +
                            Math.round(row.total_ot_count * row.basic_hour))) ))
                          }}</span>
                      </td>

                      <!-- =========================================== FINAL SALARY ================================== -->
                      <!-- GAJI DITERIMA -->
                      <td class="align-middle text-center" v-if="row.rel_payroll != null">
                        <span class="text-black text-xs font-weight-bold">
                          {{ convertRp(
                            (Number(
                            Math.round(((row.basic_day == 0) ? (row.basic_mounth) : (row.basic_day * row.total_kerja_count) )) + 
                            row.position_allowance + 
                            row.health_allowance + 
                            row.pension_allowance + 
                            row.communication_allowance + 
                            row.transport_allowance + 
                            row.meal_allowance) +
                            Math.round(row.total_ot_count * row.basic_hour) )
                            -
                            (Math.round(row.basic_mounth * 0.01) +
                            row.borrow +
                            Math.round(row.basic_mounth / 22 * row.total_alpa_count) + 
                            Math.round(row.basic_mounth * 0.02) +
                            Math.round(row.basic_mounth * 0.01) +
                            row.iuran_kematian  +
                            Number(taxEngine(row.tax_status, 
                            row.basic_mounth + 
                            row.position_allowance + 
                            row.health_allowance + 
                            row.pension_allowance + 
                            row.communication_allowance + 
                            row.transport_allowance + 
                            row.meal_allowance +
                            Math.round(row.total_ot_count * row.basic_hour))))
                          )}}
                        </span>
                      </td>
                      <!-- STATUS TF -->
                      <td class="align-middle text-center">
                        <span v-if="row.rel_payroll != null">
                          <input type="checkbox" v-model="row.rel_payroll.status_tf" @change="updatePayroll(row.rel_payroll.id, row.rel_payroll.piutang, row.rel_payroll.pinjaman, row.rel_payroll.kekurangan, row.rel_payroll.status_tf, row.rel_payroll.send_slip)">
                        </span>
                      </td>
                      <!-- KEKURANGAN -->
                      <!-- <td class="align-middle text-center">
                        <span class="text-black text-xs font-weight-bold" v-if="row.rel_payroll != null">
                          <input style="border: 1px solid transparent; background: transparent; text-align: center;" size="8" v-model="row.rel_payroll.kekurangan" @change="updatePayroll(row.rel_payroll.id, row.rel_payroll.piutang, row.rel_payroll.pinjaman, row.rel_payroll.kekurangan, row.rel_payroll.status_tf, row.rel_payroll.send_slip)">
                        </span>
                      </td> -->
                      <!-- NO REK -->
                      <td class="align-middle text-center">
                        <span class="text-black text-xs font-weight-bold">{{ row.no_rek }}</span>
                      </td>
                      <!-- AN REK -->
                      <td class="align-middle text-center">
                        <span class="text-black text-xs font-weight-bold">{{ row.an_rek }}</span>
                      </td>
                      <!-- BANK -->
                      <td class="align-middle text-center">
                        <span class="text-black text-xs font-weight-bold">{{ row.bank }}</span>
                      </td>
                      <!-- DETAIL SLIP -->
                      <td class="align-middle text-center text-sm">
                        <router-link :to="/detail-slip/+row.nik+'/'+search.periode_start+'/'+search.periode_end"><span class="badge badge-sm bg-gradient-info">Slip</span></router-link>
                      </td>
                      <!-- SEND SLIP -->
                      <td class="align-middle text-center">
                        <span v-if="row.rel_payroll != null">
                          <input type="checkbox" v-model="row.rel_payroll.send_slip" @change="updatePayroll(row.rel_payroll.id, row.rel_payroll.piutang, row.rel_payroll.pinjaman, row.rel_payroll.kekurangan, row.rel_payroll.status_tf, row.rel_payroll.send_slip)">
                        </span>
                      </td>
                      <!-- =========================================== END FINAL SALARY ================================== -->

                    </tr>
                    <!-- <tr>
                      <td colspan="" style="background-color: #F8F8FF; text-align: center;">TOTAL</td>
                      <td style="background-color: #F8F8FF; text-align: center;">{{ totalSakitAll }}</td>
                      <td style="background-color: #F8F8FF; text-align: center;">{{ totalIjinAll }}</td>
                      <td style="background-color: #F8F8FF; text-align: center;">{{ totalAlpaAll }}</td>
                      <td style="background-color: #F8F8FF; text-align: center;">{{ totalCutiAll }}</td>
                      <td style="background-color: #F8F8FF; text-align: center;">{{ totalKerjAll }}</td>
                      <td style="background-color: #F8F8FF; text-align: center;">{{ totalOTAll }}</td>
                    </tr> -->
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer">
            </div>
          </div>
      </div>
    </div>

    <!-- TAB CLASSIFICATION BANK -->
    <div class="container-fluid" style="margin-top: 90px;" v-if="search.periode_start">
    <!--   <div
        class="page-header min-height-300"
        style="
          background-image: url('https://images.unsplash.com/photo-1531512073830-ba890ca4eba2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80');
          margin-right: -24px;
          margin-left: -34%;
        "
      >
        <span class="mask bg-gradient-success opacity-6"></span>
      </div> -->
      <div class="card shadow-lg mt-n6">
        <div class="card-body p-3">
          <div class="row gx-4">
         
            <div
              class="mx-auto mt-3 col-lg-12 col-md-6 my-sm-auto ms-sm-auto me-sm-0"
            >
              <div class="nav-wrapper position-relative end-0">
                <ul
                  class="p-1 bg-transparent nav nav-pills nav-fill"
                  role="tablist"
                >
                  <li class="nav-item" @click="changeTab('TAX LIST')">
                    <a
                      class="px-0 py-1 mb-0 nav-link active"
                      data-bs-toggle="tab"
                      href="javascript:;"
                      role="tab"
                      aria-selected="true"
                    >
                      <svg
                        class="text-dark"
                        width="16px"
                        height="16px"
                        viewBox="0 0 42 42"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                      >
                        <g
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <g
                            transform="translate(-2319.000000, -291.000000)"
                            fill="#FFFFFF"
                            fill-rule="nonzero"
                          >
                            <g transform="translate(1716.000000, 291.000000)">
                              <g transform="translate(603.000000, 0.000000)">
                                <path
                                  class="color-background"
                                  d="M22.7597136,19.3090182 L38.8987031,11.2395234 C39.3926816,10.9925342 39.592906,10.3918611 39.3459167,9.89788265 C39.249157,9.70436312 39.0922432,9.5474453 38.8987261,9.45068056 L20.2741875,0.1378125 L20.2741875,0.1378125 C19.905375,-0.04725 19.469625,-0.04725 19.0995,0.1378125 L3.1011696,8.13815822 C2.60720568,8.38517662 2.40701679,8.98586148 2.6540352,9.4798254 C2.75080129,9.67332903 2.90771305,9.83023153 3.10122239,9.9269862 L21.8652864,19.3090182 C22.1468139,19.4497819 22.4781861,19.4497819 22.7597136,19.3090182 Z"
                                />
                                <path
                                  class="color-background"
                                  d="M23.625,22.429159 L23.625,39.8805372 C23.625,40.4328219 24.0727153,40.8805372 24.625,40.8805372 C24.7802551,40.8805372 24.9333778,40.8443874 25.0722402,40.7749511 L41.2741875,32.673375 L41.2741875,32.673375 C41.719125,32.4515625 42,31.9974375 42,31.5 L42,14.241659 C42,13.6893742 41.5522847,13.241659 41,13.241659 C40.8447549,13.241659 40.6916418,13.2778041 40.5527864,13.3472318 L24.1777864,21.5347318 C23.8390024,21.7041238 23.625,22.0503869 23.625,22.429159 Z"
                                  opacity="0.7"
                                />
                                <path
                                  class="color-background"
                                  d="M20.4472136,21.5347318 L1.4472136,12.0347318 C0.953235098,11.7877425 0.352562058,11.9879669 0.105572809,12.4819454 C0.0361450918,12.6208008 6.47121774e-16,12.7739139 0,12.929159 L0,30.1875 L0,30.1875 C0,30.6849375 0.280875,31.1390625 0.7258125,31.3621875 L19.5528096,40.7750766 C20.0467945,41.0220531 20.6474623,40.8218132 20.8944388,40.3278283 C20.963859,40.1889789 21,40.0358742 21,39.8806379 L21,22.429159 C21,22.0503869 20.7859976,21.7041238 20.4472136,21.5347318 Z"
                                  opacity="0.7"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                      <span class="ms-1">TAX LIST</span>
                    </a>
                  </li>
                  <li class="nav-item" @click="changeTab('LIST OF BANK')">
                    <a
                      class="px-0 py-1 mb-0 nav-link"
                      data-bs-toggle="tab"
                      href="javascript:;"
                      role="tab"
                      aria-selected="false"
                    >
                      <svg
                        class="text-dark"
                        width="16px"
                        height="16px"
                        viewBox="0 0 40 44"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                      >
                        <title>document</title>
                        <g
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <g
                            transform="translate(-1870.000000, -591.000000)"
                            fill="#FFFFFF"
                            fill-rule="nonzero"
                          >
                            <g transform="translate(1716.000000, 291.000000)">
                              <g transform="translate(154.000000, 300.000000)">
                                <path
                                  class="color-background"
                                  d="M40,40 L36.3636364,40 L36.3636364,3.63636364 L5.45454545,3.63636364 L5.45454545,0 L38.1818182,0 C39.1854545,0 40,0.814545455 40,1.81818182 L40,40 Z"
                                  opacity="0.603585379"
                                />
                                <path
                                  class="color-background"
                                  d="M30.9090909,7.27272727 L1.81818182,7.27272727 C0.814545455,7.27272727 0,8.08727273 0,9.09090909 L0,41.8181818 C0,42.8218182 0.814545455,43.6363636 1.81818182,43.6363636 L30.9090909,43.6363636 C31.9127273,43.6363636 32.7272727,42.8218182 32.7272727,41.8181818 L32.7272727,9.09090909 C32.7272727,8.08727273 31.9127273,7.27272727 30.9090909,7.27272727 Z M18.1818182,34.5454545 L7.27272727,34.5454545 L7.27272727,30.9090909 L18.1818182,30.9090909 L18.1818182,34.5454545 Z M25.4545455,27.2727273 L7.27272727,27.2727273 L7.27272727,23.6363636 L25.4545455,23.6363636 L25.4545455,27.2727273 Z M25.4545455,20 L7.27272727,20 L7.27272727,16.3636364 L25.4545455,16.3636364 L25.4545455,20 Z"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                      <span class="ms-1">LIST OF BANK</span>
                    </a>
                  </li>
                  <!-- <li class="nav-item" @click="changeTab('BANK LAIN')">
                    <a
                      class="px-0 py-1 mb-0 nav-link"
                      data-bs-toggle="tab"
                      href="javascript:;"
                      role="tab"
                      aria-selected="false"
                    >
                      <svg
                        class="text-dark"
                        width="16px"
                        height="16px"
                        viewBox="0 0 40 40"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                      >
                        <title>settings</title>
                        <g
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <g
                            transform="translate(-2020.000000, -442.000000)"
                            fill="#FFFFFF"
                            fill-rule="nonzero"
                          >
                            <g transform="translate(1716.000000, 291.000000)">
                              <g transform="translate(304.000000, 151.000000)">
                                <polygon
                                  class="color-background"
                                  opacity="0.596981957"
                                  points="18.0883333 15.7316667 11.1783333 8.82166667 13.3333333 6.66666667 6.66666667 0 0 6.66666667 6.66666667 13.3333333 8.82166667 11.1783333 15.315 17.6716667"
                                />
                                <path
                                  class="color-background"
                                  d="M31.5666667,23.2333333 C31.0516667,23.2933333 30.53,23.3333333 30,23.3333333 C29.4916667,23.3333333 28.9866667,23.3033333 28.48,23.245 L22.4116667,30.7433333 L29.9416667,38.2733333 C32.2433333,40.575 35.9733333,40.575 38.275,38.2733333 L38.275,38.2733333 C40.5766667,35.9716667 40.5766667,32.2416667 38.275,29.94 L31.5666667,23.2333333 Z"
                                  opacity="0.596981957"
                                />
                                <path
                                  class="color-background"
                                  d="M33.785,11.285 L28.715,6.215 L34.0616667,0.868333333 C32.82,0.315 31.4483333,0 30,0 C24.4766667,0 20,4.47666667 20,10 C20,10.99 20.1483333,11.9433333 20.4166667,12.8466667 L2.435,27.3966667 C0.95,28.7083333 0.0633333333,30.595 0.00333333333,32.5733333 C-0.0583333333,34.5533333 0.71,36.4916667 2.11,37.89 C3.47,39.2516667 5.27833333,40 7.20166667,40 C9.26666667,40 11.2366667,39.1133333 12.6033333,37.565 L27.1533333,19.5833333 C28.0566667,19.8516667 29.01,20 30,20 C35.5233333,20 40,15.5233333 40,10 C40,8.55166667 39.685,7.18 39.1316667,5.93666667 L33.785,11.285 Z"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                      <span class="ms-1">BANK LAIN</span>
                    </a>
                  </li> -->
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- TAX LIST -->
    <div class="py-4 container-fluid" style="margin-top: -0px;" v-if="tab == 'TAX LIST'">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header pb-0">
              <div class="d-flex align-items-center">
                <p>TAX LIST</p>
                <!-- <argon-button color="success" size="xs" class="ms-auto" @click="approveCuti()"><i class="fa fa-bell fa-sm" aria-hidden="true" style="margin-right: 5px;"></i> Export</argon-button> -->
                <a class="ms-auto" :href="apiUrl+'export-excel/payroll-bank?periode_start='+search.periode_start+'&periode_end='+search.periode_end+'&nama_bank='+tab+''" target="_BLANK"><argon-button :disabled="search.periode_start == ''" color="success" size="xs" class="ms-auto" variant="gradient"><i class="fa fa-file-excel-o" aria-hidden="true" style="margin-right: 5px;"></i> Export Excel</argon-button></a>
              </div>
            </div>
            <div class="card-body">
              <div class="card-body px-0 pt-0 pb-2 ">
                  <div class="table-responsive p-0 scroll">
                    <table class="table table-striped table-hover align-items-center mb-0">
                      <thead>
                        <tr style="background-color: #F0F8FF;">
                          <th class="text-center text-uppercase text-white text-xxs font-weight-bolder ">NO</th>
                          <th class="text-center text-uppercase text-white text-xxs font-weight-bolder  ps-2">DEPARTMENT</th>
                          <th class="text-center text-uppercase text-white text-xxs font-weight-bolder  ps-2">NAMA</th>
                          <th class="text-center text-uppercase text-white text-xxs font-weight-bolder  ps-2">STATUS</th>
                          <th class="text-center text-uppercase text-white text-xxs font-weight-bolder ">GAJI SUB TOTAL</th>
                          <th class="text-center text-uppercase text-white text-xxs font-weight-bolder ">DUTK</th>
                          <th class="text-center text-uppercase text-white text-xxs font-weight-bolder ">GAJI TOTAL</th>
                          <th class="text-center text-uppercase text-white text-xxs font-weight-bolder ">TAXES THIS MONTH </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(row, i) in tableBankBSI.data" :key="i">
                          <td class="align-middle text-center text-sm">
                            <span class="text-secondary text-xs font-weight-bold"> {{ i + 1  }}</span>
                          </td>
                          <td class="align-middle text-left text-sm">
                            <span class="text-secondary text-xs font-weight-bold"> {{ row.name }} </span>
                          </td>
                          <td class="align-middle text-left text-sm">
                            <span class="text-secondary text-xs font-weight-bold"> {{ row.department }} </span>
                          </td>
                          <td class="align-middle text-center text-sm">
                            <span class="text-secondary text-xs font-weight-bold"> {{ row.tax_status }} </span>
                          </td>
                          <td class="align-middle text-center">
                            <span class="text-secondary text-xs font-weight-bold"> {{
                             convertRp(Math.round(Number((row.basic_day == 0) ? (row.basic_mounth) : (row.basic_day * row.total_kerja_count) ))) 
                            }} </span>
                          </td>
                          <td class="align-middle text-center">
                            <span class="text-secondary text-xs font-weight-bold"> 
                            {{ convertRp(Math.round(Number((row.basic_day == 0) ? (row.basic_mounth) : (row.basic_day * row.total_kerja_count) ))) }} </span>
                          </td>
                          <td class="align-middle text-center">
                            <span class="text-secondary text-xs font-weight-bold"> 
                            {{ convertRp(
                            (Number(
                            Math.round(((row.basic_day == 0) ? (row.basic_mounth) : (row.basic_day * row.total_kerja_count) )) + 
                            row.position_allowance + 
                            row.health_allowance + 
                            row.pension_allowance + 
                            row.communication_allowance + 
                            row.transport_allowance + 
                            row.meal_allowance) +
                            Math.round(row.total_ot_count * row.basic_hour) )
                          )}} </span>
                          </td>
                          <td class="align-middle text-center">
                            <span class="text-secondary text-xs font-weight-bold">{{ convertRp(taxEngine(row.tax_status, 
                            row.basic_mounth + 
                            row.position_allowance + 
                            row.health_allowance + 
                            row.pension_allowance + 
                            row.communication_allowance + 
                            row.transport_allowance + 
                            row.meal_allowance +
                            Math.round(row.total_ot_count * row.basic_hour))) }}</span>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="4" class="text-center font-weight-bold">TOTAL</td>
                          <td class="text-center font-weight-bold">{{ convertRp(totalBasicGaji) }}</td>
                          <td class="text-center font-weight-bold">{{ convertRp(totalBasicGaji) }}</td>
                          <td class="text-center font-weight-bold">{{ convertRp(totalGrossGaji) }}</td>
                          <td class="text-center font-weight-bold">{{ convertRp(totalTaxAllEmployee) }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-md-4">
          <profile-card />
        </div> -->
      </div>
    </div>

    <!-- LIST OF BANK -->
    <div class="py-4 container-fluid" style="margin-top: -0px;" v-if="tab == 'LIST OF BANK'">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header pb-0">
              <div class="d-flex align-items-center">
                <p>LIST OF BANK</p>
                <a class="ms-auto" :href="apiUrl+'export-excel/payroll-bank?periode_start='+search.periode_start+'&periode_end='+search.periode_end+'&nama_bank='+tab+''" target="_BLANK"><argon-button :disabled="search.periode_start == ''" color="success" size="xs" class="ms-auto" variant="gradient"><i class="fa fa-file-excel-o" aria-hidden="true" style="margin-right: 5px;"></i> Export Excel</argon-button></a>
              </div>
            </div>
            <div class="card-body">
              <div class="card-body px-0 pt-0 pb-2">
                  <div class="table-responsive p-0 scroll">
                    <table class="table align-items-center mb-0">
                      <thead>
                        <tr style="background-color: #F0F8FF;">
                          <th class="text-center text-uppercase text-white text-xxs font-weight-bolder ">NO</th>
                          <th class="text-center text-uppercase text-white text-xxs font-weight-bolder  ps-2">NPP</th>
                          <th class="text-center text-uppercase text-white text-xxs font-weight-bolder  ps-2">NAMA</th>
                          <th class="text-center text-uppercase text-white text-xxs font-weight-bolder ">JUMLAH GAJI</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(row, i) in tableBankBSI.data" :key="i">
                          <td class="align-middle text-center text-sm">
                            <span class="text-secondary text-xs font-weight-bold"> {{ i + 1  }}</span>
                          </td>
                          <td class="align-middle text-left text-sm">
                            <span class="text-secondary text-xs font-weight-bold"> </span>
                          </td>
                          <td class="align-middle text-left text-sm">
                            <span class="text-secondary text-xs font-weight-bold"> {{ row.name }} </span>
                          </td>
                          <td class="align-middle text-center">
                            <span class="text-secondary text-xs font-weight-bold">Rp. {{ convertRp(
                              (Number(
                              Math.round(((row.basic_day == 0) ? (row.basic_mounth) : (row.basic_day * row.total_kerja_count) )) + 
                              row.position_allowance + 
                              row.health_allowance + 
                              row.pension_allowance + 
                              row.communication_allowance + 
                              row.transport_allowance + 
                              row.meal_allowance) +
                              Math.round(row.total_ot_count * row.basic_hour) )
                              -
                              (Math.round(row.basic_mounth * 0.01) +
                              row.borrow +
                              Math.round(row.basic_mounth / 22 * row.total_alpa_count) + 
                              Math.round(row.basic_mounth * 0.02) +
                              Math.round(row.basic_mounth * 0.01) +
                              row.iuran_kematian  +
                              Number(taxEngine(row.tax_status, 
                              row.basic_mounth + 
                              row.position_allowance + 
                              row.health_allowance + 
                              row.pension_allowance + 
                              row.communication_allowance + 
                              row.transport_allowance + 
                              row.meal_allowance +
                              Math.round(row.total_ot_count * row.basic_hour))))
                            )}} </span>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3" class="text-center font-weight-bold">TOTAL</td>
                          <td class="text-center font-weight-bold">Rp. {{ convertRp(totalBasicGaji) }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-md-4">
          <profile-card />
        </div> -->
      </div>
    </div>

  </div>

  <!-- =======  MODAL FILTER ======= -->
  <div class="container">
    <vue-final-modal v-model="formFilter.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formFilter.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formFilter.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label">Nama <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Nama" v-model="search.nama" required>
        <label for="example-text-input" class="form-control-label mt-3">Periode Start <span style="color: red;">*</span></label>
        <input type="date" class="form-control" placeholder="Nama" v-model="search.periode_start" required>
        <label for="example-text-input" class="form-control-label mt-3">Periode End <span style="color: red;">*</span></label>
        <input type="date" class="form-control" placeholder="Nama" v-model="search.periode_end" required>
      </div>
      <!-- footer -->
      <div class="row float-right mt-5">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="formFilter.show = false">Close</argon-button>
        </div>
        <div class="col-1">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="get()" :disabled="onLoading == true">
            <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
            <span v-else>
                <span> Filter</span>
            </span>
          </argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

  <!-- =======  MODAL CHANGE NOTE SLIP GAJI ======= -->
  <div class="container">
    <vue-final-modal v-model="formNoteSlipGaji.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formNoteSlipGaji.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formNoteSlipGaji.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label">Note Slip Gaji <span style="color: red;">*</span></label>
        <textarea class="form-control" v-model="noteSlipGaji" rows="10"></textarea>
      </div>
      <!-- footer -->
      <div class="row float-right mt-5">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="formNoteSlipGaji.show = false">Close</argon-button>
        </div>
        <div class="col-1">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="saveNoteSlip()" :disabled="onLoading == true">
            <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
            <span v-else>
                <span> Save</span>
            </span>
          </argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
import { VueFinalModal } from 'vue-final-modal'
import Api from '@/helpers/api';
import config from '@/configs/config';
var moment = require('moment');
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import dataPayroll from '@/services/human_resource/dataPayroll.service';
import akun from '@/services/akun.service';

export default {
  name: "tables",
  components: {
    ArgonButton,
    VueFinalModal,
  },
  data() {
    return {
      moment:moment,
      absenKaryawan: {
        data: []
      },
      tableBankBSI: {
        data: []
      },
      formFilter: {
        add: true,
        title: "Filter",
        show: false
      },
      formNoteSlipGaji: {
        add: true,
        title: "Change Note Slip Gaji",
        show: false
      },
      noteSlipGaji: '',
      onLoading: false,
      onLoadingSendSlip: false,
      // karyawan: {},
      // totalSakitAll: '',
      // totalIjinAll: '',
      // totalAlpaAll: '',
      // totalCutiAll: '',
      // totalKerjAll: '',
      // totalOTAll: '',
      totalBasicGaji: '',
      totalGrossGaji: '',
      totalTaxAllEmployee: '',
      search: {
        nama: '',
        periode_start: '',
        periode_end: '',
      },
      backgroundRed: null,
      apiUrl :config.apiUrl,
      tab: '',
      role: '',
    };
  },
  mounted(){
    this.get();
    this.tokenApi = 'Bearer '+localStorage.getItem('token');
    this.getRole();
  },
  methods: {
    get(){
      let context = this; 
      this.onLoading = true;
                    
      Api(context, dataPayroll.index({nama: context.search.nama, periode_start: context.search.periode_start, periode_end: context.search.periode_end,})).onSuccess(function(response) {    
          context.absenKaryawan.data = response.data.data.payroll;
          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.absenKaryawan.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.onLoading = false;
         context.formFilter.show  = false;
      })
      .call()
    },
    getRole(){
      let context = this;     
      context.onLoading = true;
      Api(context, akun.indexProfile()).onSuccess(function(response) {
          context.role = response.data.data[0].role;
          if (response.data.data[0].role != 'Admin') {
            context.$router.push({ path: /detail-profile/+response.data.data[0].employee_id})
          }
      }).onError(function(error) {  
      })
      .call() 
    },
    getBank(nama_bank){
      let context = this; 
      this.onLoading = true;
                    
      Api(context, dataPayroll.getBank({nama_bank: nama_bank, periode_start: context.search.periode_start, periode_end: context.search.periode_end,})).onSuccess(function(response) {    
          context.tableBankBSI.data   = response.data.data.payroll;
          context.totalBasicGaji      = response.data.data.totalBasicGaji;
          context.totalGrossGaji      = response.data.data.totalGrossGaji;
          context.totalTaxAllEmployee = response.data.data.totalTaxAllEmployee;
          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableBankBSI.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.onLoading = false;
         context.formFilter.show  = false;
      })
      .call()
    },
    filter() {
      this.formFilter.add   = true;
      this.formFilter.show  = true;
      this.formFilter.title = "Filter";
    },
    updatePayroll(id, piutang, pinjaman, kekurangan, status_tf, send_slip){
      let api     = null;
      let context = this;

      api = Api(context, dataPayroll.updatePayroll({
          id: id,
          piutang: piutang,
          pinjaman: pinjaman,
          kekurangan: kekurangan,
          status_tf: status_tf,
          send_slip: send_slip,
      }));
      api.onSuccess(function(response) {
          context.notify('Updated Success', 'success')
      }).onError(function(error) { 
          context.notify('Updated Failed', 'error')
      }).onFinish(function() {  
          context.get();
      })
      .call();
    },
    sendSlip(){
      let context    = this;       
      this.onLoadingSendSlip = true;

      Api(context, dataPayroll.sendSlip({periode_start: context.search.periode_start, periode_end: context.search.periode_end})).onSuccess(function(response) {    
          // context.absenKaryawan.data = response.data;
          context.notify('Send Slip Success', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              // context.table.data = [];
          }
          context.notify('Send Slip Failed', 'error')
      }).onFinish(function() {  
          context.onLoadingSendSlip = false;
          context.get();
      })
      .call()
    },
    saveNoteSlip(id, piutang, pinjaman, kekurangan, status_tf, send_slip){
      let api     = null;
      let context = this;

      api = Api(context, dataPayroll.updateNoteSlip({note: context.noteSlipGaji}));
      api.onSuccess(function(response) {
          context.notify('Updated Success', 'success')
      }).onError(function(error) { 
          context.notify('Updated Failed', 'error')
      }).onFinish(function() {  
      })
      .call();
    },
    changeNoteSlipGaji() {
      let context = this;               
      Api(context, dataPayroll.getNoteSlip()).onSuccess(function(response) {
          context.noteSlipGaji           = response.data.data;
          context.formNoteSlipGaji.show  = true;
          context.formNoteSlipGaji.title = 'Change Note Slip Gaji';       
      })
      .call()        
    },
    setBg(id) {
      this.backgroundRed = id
    },
    convertRp(bilangan) {
      if (bilangan) {
        var number_string = bilangan.toString(),
            sisa    = number_string.length % 3,
            rupiah  = number_string.substr(0, sisa),
            ribuan  = number_string.substr(sisa).match(/\d{3}/g);

        if(ribuan){
          var separator = sisa ? '.' : '';
          rupiah += separator + ribuan.join('.');
          return rupiah
        }else{
          return bilangan
        }
      }
    },
    changeTab(tab){
      this.tab = tab
      this.getBank(tab);
    },
    notify(message, type) {
      if (type == 'success') {
        toast.success(message, {
          autoClose: 2000,
        }); // ToastOptions
      }else{
        toast.error(message, {
          autoClose: 2000,
        }); // ToastOptions
      }
    },
    taxEngine(tax_status, gross_salary){
      if (tax_status == 'TK0' || tax_status == 'TK1' || tax_status == 'K0') {
        if (gross_salary <= 5400000) {
          // 0
          return Math.round((gross_salary * 0.00))
        }
        if (gross_salary > 5400000 && gross_salary <= 5650000) {
          // 0,25%
          return Math.round((gross_salary * 0.0025))
        }
        if (gross_salary > 5650000 && gross_salary <= 5950000) {
          // 0,5%
          return Math.round((gross_salary * 0.005))
        }
        if (gross_salary > 5950000 && gross_salary <= 6300000) {
          // 0,75%
          return Math.round((gross_salary * 0.0075))
        }
        if (gross_salary > 6300000 && gross_salary <= 6750000) {
          // 1%
          return Math.round((gross_salary * 0.01))
        }
        if (gross_salary > 6750000 && gross_salary <= 7500000) {
          // 1,25%
          return Math.round((gross_salary * 0.0125))
        }
        if (gross_salary > 7500000 && gross_salary <= 8550000) {
          // 1,5%
          return Math.round((gross_salary * 0.0150))
        }
        if (gross_salary > 8550000 && gross_salary <= 9650000) {
          // 1,75%
          return Math.round((gross_salary * 0.0175))
        }
        if (gross_salary > 9650000 && gross_salary <= 10050000) {
          // 2%
          return Math.round((gross_salary * 0.02))
        }
        if (gross_salary > 10050000 && gross_salary <= 10350000) {
          // 2,25%
          return Math.round((gross_salary * 0.0225))
        }
        if (gross_salary > 10350000 && gross_salary <= 10700000) {
          // 2,5%
          return Math.round((gross_salary * 0.025))
        }
        if (gross_salary > 10700000 && gross_salary <= 11050000) {
          // 3%
          return Math.round((gross_salary * 0.03))
        }
        if (gross_salary > 11050000 && gross_salary <= 11600000) {
          // 3,5%
          return Math.round((gross_salary * 0.035))
        }
        if (gross_salary > 11600000 && gross_salary <= 12500000) {
          // 4%
          return Math.round((gross_salary * 0.04))
        }
        if (gross_salary > 12500000 && gross_salary <= 13750000) {
          // 5%
          return Math.round((gross_salary * 0.05))
        }
        if (gross_salary > 13750000 && gross_salary <= 15100000) {
          // 6%
          return Math.round((gross_salary * 0.06))
        }
        if (gross_salary > 15100000 && gross_salary <= 16950000) {
          // 7%
          return Math.round((gross_salary * 0.07))
        }
        if (gross_salary > 16950000 && gross_salary <= 19750000) {
          // 8%
          return Math.round((gross_salary * 0.08))
        }
        if (gross_salary > 19750000 && gross_salary <= 24150000) {
          // 9%
          return Math.round((gross_salary * 0.09))
        }
        if (gross_salary > 24150000 && gross_salary <= 26450000) {
          // 10%
          return Math.round((gross_salary * 0.1))
        }
        if (gross_salary > 26450000 && gross_salary <= 28000000) {
          // 11%
          return Math.round((gross_salary * 0.11))
        }
        if (gross_salary > 28000000 && gross_salary <= 30050000) {
          // 12%
          return Math.round((gross_salary * 0.12))
        }
        if (gross_salary > 30050000 && gross_salary <= 32400000) {
          // 13%
          return Math.round((gross_salary * 0.13))
        }
        if (gross_salary > 32400000 && gross_salary <= 35400000) {
          // 14%
          return Math.round((gross_salary * 0.14))
        }
        if (gross_salary > 35400000 && gross_salary <= 39100000) {
          // 15%
          return Math.round((gross_salary * 0.15))
        }
        if (gross_salary > 39100000 && gross_salary <= 43850000) {
          // 16%
          return Math.round((gross_salary * 0.16))
        }
        if (gross_salary > 43850000 && gross_salary <= 47800000) {
          // 17%
          return Math.round((gross_salary * 0.17))
        }
        if (gross_salary > 47800000 && gross_salary <= 51400000) {
          // 18%
          return Math.round((gross_salary * 0.18))
        }
        if (gross_salary > 51400000 && gross_salary <= 56300000) {
          // 19%
          return Math.round((gross_salary * 0.19))
        }
        if (gross_salary > 56300000 && gross_salary <= 62200000) {
          // 20%
          return Math.round((gross_salary * 0.20))
        }
        if (gross_salary > 62200000 && gross_salary <= 68600000) {
          // 21%
          return Math.round((gross_salary * 0.21))
        }
        if (gross_salary > 68600000 && gross_salary <= 77500000) {
          // 22%
          return Math.round((gross_salary * 0.22))
        }
        if (gross_salary > 77500000 && gross_salary <= 89000000) {
          // 23%
          return Math.round((gross_salary * 0.23))
        }
        if (gross_salary > 89000000 && gross_salary <= 103000000) {
          // 24%
          return Math.round((gross_salary * 0.24))
        }
        if (gross_salary > 103000000 && gross_salary <= 125000000) {
          // 25%
          return Math.round((gross_salary * 0.25))
        }
        if (gross_salary > 125000000 && gross_salary <= 157000000) {
          // 26%
          return Math.round((gross_salary * 0.26))
        }
        if (gross_salary > 157000000 && gross_salary <= 206000000) {
          // 27%
          return Math.round((gross_salary * 0.27))
        }
        if (gross_salary > 206000000 && gross_salary <= 337000000) {
          // 28%
          return Math.round((gross_salary * 0.28))
        }
        if (gross_salary > 337000000 && gross_salary <= 454000000) {
          // 29%
          return Math.round((gross_salary * 0.29))
        }
        if (gross_salary > 454000000 && gross_salary <= 550000000) {
          // 30%
          return Math.round((gross_salary * 0.30))
        }
        if (gross_salary > 550000000 && gross_salary <= 695000000) {
          // 31%
          return Math.round((gross_salary * 0.31))
        }
        if (gross_salary > 695000000 && gross_salary <= 910000000) {
          // 32%
          return Math.round((gross_salary * 0.32))
        }
        if (gross_salary > 910000000 && gross_salary <= 1400000000) {
          // 33%
          return Math.round((gross_salary * 0.33))
        }
        if (gross_salary > 1400000000) {
          // 34%
          return Math.round((gross_salary * 0.34))
        }
      }
      if (tax_status == 'TK2' || tax_status == 'TK3' || tax_status == 'K1' || tax_status == 'K2') {
        if (gross_salary <= 6200000) {
          // 0
          return Math.round((gross_salary * 0.00))
        }
        if (gross_salary > 6200000 && gross_salary <= 6500000) {
          // 0,25%
          return Math.round((gross_salary * 0.0025))
        }
        if (gross_salary > 6500000 && gross_salary <= 6850000) {
          // 0,5%
          return Math.round((gross_salary * 0.005))
        }
        if (gross_salary > 6850000 && gross_salary <= 7300000) {
          // 0,75%
          return Math.round((gross_salary * 0.0075))
        }
        if (gross_salary > 7300000 && gross_salary <= 9200000) {
          // 1%
          return Math.round((gross_salary * 0.01))
        }
        if (gross_salary > 9200000 && gross_salary <= 10750000) {
          // 1,5%
          return Math.round((gross_salary * 0.0150))
        }
        if (gross_salary > 10750000 && gross_salary <= 11250000) {
          // 2%
          return Math.round((gross_salary * 0.02))
        }
        if (gross_salary > 11250000 && gross_salary <= 11600000) {
          // 2,5%
          return Math.round((gross_salary * 0.025))
        }
        if (gross_salary > 11600000 && gross_salary <= 12600000) {
          // 3%
          return Math.round((gross_salary * 0.03))
        }
        if (gross_salary > 12600000 && gross_salary <= 13600000) {
          // 4%
          return Math.round((gross_salary * 0.04))
        }
        if (gross_salary > 13600000 && gross_salary <= 14950000) {
          // 5%
          return Math.round((gross_salary * 0.05))
        }
        if (gross_salary > 14950000 && gross_salary <= 16400000) {
          // 6%
          return Math.round((gross_salary * 0.06))
        }
        if (gross_salary > 16400000 && gross_salary <= 18450000) {
          // 7%
          return Math.round((gross_salary * 0.07))
        }
        if (gross_salary > 18450000 && gross_salary <= 21850000) {
          // 8%
          return Math.round((gross_salary * 0.08))
        }
        if (gross_salary > 21850000 && gross_salary <= 26000000) {
          // 9%
          return Math.round((gross_salary * 0.09))
        }
        if (gross_salary > 26000000 && gross_salary <= 27700000) {
          // 10%
          return Math.round((gross_salary * 0.1))
        }
        if (gross_salary > 27700000 && gross_salary <= 29350000) {
          // 11%
          return Math.round((gross_salary * 0.11))
        }
        if (gross_salary > 29350000 && gross_salary <= 31450000) {
          // 12%
          return Math.round((gross_salary * 0.12))
        }
        if (gross_salary > 31450000 && gross_salary <= 33950000) {
          // 13%
          return Math.round((gross_salary * 0.13))
        }
        if (gross_salary > 33950000 && gross_salary <= 37100000) {
          // 14%
          return Math.round((gross_salary * 0.14))
        }
        if (gross_salary > 37100000 && gross_salary <= 41100000) {
          // 15%
          return Math.round((gross_salary * 0.15))
        }
        if (gross_salary > 41100000 && gross_salary <= 45800000) {
          // 16%
          return Math.round((gross_salary * 0.16))
        }
        if (gross_salary > 45800000 && gross_salary <= 49500000) {
          // 17%
          return Math.round((gross_salary * 0.17))
        }
        if (gross_salary > 49500000 && gross_salary <= 53800000) {
          // 18%
          return Math.round((gross_salary * 0.18))
        }
        if (gross_salary > 53800000 && gross_salary <= 58500000) {
          // 19%
          return Math.round((gross_salary * 0.19))
        }
        if (gross_salary > 58500000 && gross_salary <= 64000000) {
          // 20%
          return Math.round((gross_salary * 0.20))
        }
        if (gross_salary > 64000000 && gross_salary <= 71000000) {
          // 21%
          return Math.round((gross_salary * 0.21))
        }
        if (gross_salary > 71000000 && gross_salary <= 80000000) {
          // 22%
          return Math.round((gross_salary * 0.22))
        }
        if (gross_salary > 80000000 && gross_salary <= 93000000) {
          // 23%
          return Math.round((gross_salary * 0.23))
        }
        if (gross_salary > 93000000 && gross_salary <= 109000000) {
          // 24%
          return Math.round((gross_salary * 0.24))
        }
        if (gross_salary > 109000000 && gross_salary <= 129000000) {
          // 25%
          return Math.round((gross_salary * 0.25))
        }
        if (gross_salary > 129000000 && gross_salary <= 163000000) {
          // 26%
          return Math.round((gross_salary * 0.26))
        }
        if (gross_salary > 163000000 && gross_salary <= 211000000) {
          // 27%
          return Math.round((gross_salary * 0.27))
        }
        if (gross_salary > 211000000 && gross_salary <= 374000000) {
          // 28%
          return Math.round((gross_salary * 0.28))
        }
        if (gross_salary > 374000000 && gross_salary <= 459000000) {
          // 29%
          return Math.round((gross_salary * 0.29))
        }
        if (gross_salary > 459000000 && gross_salary <= 555000000) {
          // 30%
          return Math.round((gross_salary * 0.30))
        }
        if (gross_salary > 555000000 && gross_salary <= 704000000) {
          // 31%
          return Math.round((gross_salary * 0.31))
        }
        if (gross_salary > 704000000 && gross_salary <= 957000000) {
          // 32%
          return Math.round((gross_salary * 0.32))
        }
        if (gross_salary > 957000000 && gross_salary <= 1405000000) {
          // 33%
          return Math.round((gross_salary * 0.33))
        }
        if (gross_salary > 1405000000) {
          // 34%
          return Math.round((gross_salary * 0.34))
        }
      }
      if (tax_status == 'K3') {
        if (gross_salary <= 6600000) {
          // 0
          return Math.round((gross_salary * 0.00))
        }
        if (gross_salary > 6600000 && gross_salary <= 6950000) {
          // 0,25%
          return Math.round((gross_salary * 0.0025))
        }
        if (gross_salary > 6950000 && gross_salary <= 7350000) {
          // 0,5%
          return Math.round((gross_salary * 0.005))
        }
        if (gross_salary > 7350000 && gross_salary <= 7800000) {
          // 0,75%
          return Math.round((gross_salary * 0.0075))
        }
        if (gross_salary > 7800000 && gross_salary <= 8850000) {
          // 1%
          return Math.round((gross_salary * 0.01))
        }
        if (gross_salary > 8850000 && gross_salary <= 9800000) {
          // 1,25%
          return Math.round((gross_salary * 0.0125))
        }
        if (gross_salary > 9800000 && gross_salary <= 10950000) {
          // 1,5%
          return Math.round((gross_salary * 0.0150))
        }
        if (gross_salary > 10950000 && gross_salary <= 11200000) {
          // 1,75%
          return Math.round((gross_salary * 0.0175))
        }
        if (gross_salary > 11200000 && gross_salary <= 12050000) {
          // 2%
          return Math.round((gross_salary * 0.02))
        }
        if (gross_salary > 12050000 && gross_salary <= 12950000) {
          // 3%
          return Math.round((gross_salary * 0.03))
        }
        if (gross_salary > 12950000 && gross_salary <= 14150000) {
          // 4%
          return Math.round((gross_salary * 0.04))
        }
        if (gross_salary > 14150000 && gross_salary <= 15550000) {
          // 5%
          return Math.round((gross_salary * 0.05))
        }
        if (gross_salary > 15550000 && gross_salary <= 17050000) {
          // 6%
          return Math.round((gross_salary * 0.06))
        }
        if (gross_salary > 17050000 && gross_salary <= 19500000) {
          // 7%
          return Math.round((gross_salary * 0.07))
        }
        if (gross_salary > 19500000 && gross_salary <= 22700000) {
          // 8%
          return Math.round((gross_salary * 0.08))
        }
        if (gross_salary > 22700000 && gross_salary <= 26600000) {
          // 9%
          return Math.round((gross_salary * 0.09))
        }
        if (gross_salary > 26600000 && gross_salary <= 28100000) {
          // 10%
          return Math.round((gross_salary * 0.1))
        }
        if (gross_salary > 28100000 && gross_salary <= 30100000) {
          // 11%
          return Math.round((gross_salary * 0.11))
        }
        if (gross_salary > 30100000 && gross_salary <= 32600000) {
          // 12%
          return Math.round((gross_salary * 0.12))
        }
        if (gross_salary > 32600000 && gross_salary <= 35400000) {
          // 13%
          return Math.round((gross_salary * 0.13))
        }
        if (gross_salary > 35400000 && gross_salary <= 38900000) {
          // 14%
          return Math.round((gross_salary * 0.14))
        }
        if (gross_salary > 38900000 && gross_salary <= 43000000) {
          // 15%
          return Math.round((gross_salary * 0.15))
        }
        if (gross_salary > 43000000 && gross_salary <= 47400000) {
          // 16%
          return Math.round((gross_salary * 0.16))
        }
        if (gross_salary > 47400000 && gross_salary <= 51200000) {
          // 17%
          return Math.round((gross_salary * 0.17))
        }
        if (gross_salary > 51200000 && gross_salary <= 55800000) {
          // 18%
          return Math.round((gross_salary * 0.18))
        }
        if (gross_salary > 55800000 && gross_salary <= 60400000) {
          // 19%
          return Math.round((gross_salary * 0.19))
        }
        if (gross_salary > 60400000 && gross_salary <= 66700000) {
          // 20%
          return Math.round((gross_salary * 0.20))
        }
        if (gross_salary > 66700000 && gross_salary <= 74500000) {
          // 21%
          return Math.round((gross_salary * 0.21))
        }
        if (gross_salary > 74500000 && gross_salary <= 83200000) {
          // 22%
          return Math.round((gross_salary * 0.22))
        }
        if (gross_salary > 83200000 && gross_salary <= 95600000) {
          // 23%
          return Math.round((gross_salary * 0.23))
        }
        if (gross_salary > 95600000 && gross_salary <= 110000000) {
          // 24%
          return Math.round((gross_salary * 0.24))
        }
        if (gross_salary > 110000000 && gross_salary <= 134000000) {
          // 25%
          return Math.round((gross_salary * 0.25))
        }
        if (gross_salary > 134000000 && gross_salary <= 169000000) {
          // 26%
          return Math.round((gross_salary * 0.26))
        }
        if (gross_salary > 169000000 && gross_salary <= 221000000) {
          // 27%
          return Math.round((gross_salary * 0.27))
        }
        if (gross_salary > 221000000 && gross_salary <= 390000000) {
          // 28%
          return Math.round((gross_salary * 0.28))
        }
        if (gross_salary > 390000000 && gross_salary <= 463000000) {
          // 29%
          return Math.round((gross_salary * 0.29))
        }
        if (gross_salary > 463000000 && gross_salary <= 561000000) {
          // 30%
          return Math.round((gross_salary * 0.30))
        }
        if (gross_salary > 561000000 && gross_salary <= 709000000) {
          // 31%
          return Math.round((gross_salary * 0.31))
        }
        if (gross_salary > 709000000 && gross_salary <= 965000000) {
          // 32%
          return Math.round((gross_salary * 0.32))
        }
        if (gross_salary > 965000000 && gross_salary <= 1419000000) {
          // 33%
          return Math.round((gross_salary * 0.33))
        }
        if (gross_salary > 1419000000) {
          // 34%
          return Math.round((gross_salary * 0.34))
        }
      }
    }
  }
};
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 500px;
  margin-top: auto;
  margin-bottom: auto;
}
.modal__title {
  font-size: 1rem;
  font-weight: 600;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
.scroll { 
  overflow: auto; 
  height: 600px; 
}
.scroll thead th { 
  position: sticky; 
  top: 0; 
  z-index: 100; 
  background-color: #6495ED; 
}
</style>
