import Service from '@/helpers/service'

export default {

    searchAnggota(params) {
        return Service().get('approval-lembur/search-anggota', {params});
    },
    index(params) {
        return Service().get('approval-lembur/index', {params});
    },
    show(params) {
        return Service().get('approval-lembur/show', {params});
    },
    create(params) {
        return Service().post('approval-lembur/create', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    appLembur(code_spl, params) {
        return Service().post('approval-lembur/app-lembur/'+code_spl, params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    editLembur(id) {
        return Service().get('approval-lembur/edit-lembur/'+id);
    },
    updateLembur(id, params) {
        return Service().post('approval-lembur/update-lembur/'+id, params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    deleteAnggota(id) {
        return Service().post('approval-lembur/delete-anggota/'+id);
    },
}