import Service from '@/helpers/service'

export default {

    index(params) {
        return Service().get('absensi/index', {params});
    },
    attLog(params) {
        return Service().get('absensi/get-att-log', {params});
    },
    getHisLembur(params) {
        return Service().get('absensi/get-history-lembur', {params});
    },
    getHisCuti(params) {
        return Service().get('absensi/get-history-cuti', {params});
    },
    attLogIdKaryawan(params) {
        return Service().get('absensi/get-att-log-per-id-karyawan', {params});
    },
    show(id) {
        return Service().get('absensi/show/'+id);
    },
    create(params) {
        return Service().post('absensi/create', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    updateAbsen(params) {
        return Service().post('absensi/update-absen', params);
    },
    setLibur(params) {
        return Service().post('absensi/set-libur', params);
    },
}