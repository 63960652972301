import Service from '@/helpers/service'

export default {

    index(params) {
        return Service().get('comparasion-price/index', {params});
    },
    searchCp(params) {
        return Service().get('comparasion-price/get-comparasion-price', {params});
    },
    getWarehouseLocation(params) {
        return Service().get('comparasion-price/get-comparasion-price', {params});
    },
    generateCPNo(params) {
        return Service().get('comparasion-price/generate-cp-no', {params});
    },
    create(params) {
        return Service().post('comparasion-price/create', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    delete(id, params) {
        return Service().post('comparasion-price/delete/'+id, params);
    },
    // ------- ITEM ROUTE -----
    transactionDetail(params) {
        return Service().get('comparasion-price/transaction-detail', {params});
    },
    showItem(id) {
        return Service().get('comparasion-price/show-item/'+id);
    },
    deleteItem(id, params) {
        return Service().post('comparasion-price/delete-item/'+id, params);
    },
    cpApproved(params) {
        return Service().post('comparasion-price/cp-approved', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
}