<template>
  <div
    class="collapse navbar-collapse w-auto h-auto h-100"
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav">
      <!-- <li class="nav-item" v-for="(row, i) in table.data" :key="i">
        <sidenav-item
          :url="row.url"
          :class="getRoute() === 'dashboard-default' ? 'active' : ''"
          :navText="row.name"
        >
          <template v-slot:icon>
            <span v-html="row.icon"></span>
          </template>
        </sidenav-item>
      </li> -->

      <li class="nav-item nav-with-child" v-for="(row, i) in table.data" :key="i">
        <div v-for="(prev, j) in row.rel_prev_menu" :key="j">
          <!-- menu -->
          <a class="nav-link"  @click="showSubMenu(i)" style="cursor: pointer;">
            <span v-html="prev.icon" style="margin-right: 10px;"></span> 
              <span class="font-weight-bold" style="margin-right: 10px; font-size: 13px;">{{prev.name}}</span> 
            <i class="fa fa-chevron-down" aria-hidden="true" v-if="showMenu == i"></i>
            <i class="fa fa-chevron-left" aria-hidden="true" v-else></i>
          </a>
          <!-- sub menu -->
          <ul class="nav-item-child" style="list-style-type: none;">
            <li class="nav-item" :style="(showMenu == i) ? 'margin-left: -1px;' : 'display: none; cursor: pointer;' " v-for="(subMenu, k) in prev.rel_prev_sub_menu" :key="k">
              <sidenav-item
                style="margin-left: -15px; font-size: 13px;"
                :url="subMenu.redirect_to"
                :class="getRoute() === 'dashboard-default' ? 'active' : ''"
                :navText="subMenu.name"
              >
                <template v-slot:icon>
                </template>
              </sidenav-item>
            </li>
          </ul>
        </div>
      </li>
     <!--  <li class="nav-item nav-with-child">
        <a class="nav-link"  @click="showSubMenu(i)">
          <i class="ni ni-align-left-2"></i> Multilevel
        </a>
        <ul class="nav-item-child">
          <li class="nav-item" :style="(showMenu == i) ? 'display: none;' : 'cursor: pointer;' ">
            <a class="nav-link" href="">
           Multilevel 1
            </a>
          </li>
          <li class="nav-item" :style="(showMenu == i) ? 'display: none;' : 'cursor: pointer;' ">
            <a class="nav-link" href="">
           Multilevel 2
            </a>
          </li>

        </ul>
      </li> -->

      <!-- <li class="nav-item" v-for="(row, i) in table.data" :key="i">
        <sidenav-item
          :url="row.url"
          :class="getRoute() === 'dashboard-default' ? 'active' : ''"
          :navText="row.name"
        >
          <template v-slot:icon>
            <span v-html="row.icon"></span>
          </template>
        </sidenav-item>
      </li> -->

      <!-- <li class="nav-item">
        <sidenav-item
          url="/barang-masuk"
          :class="getRoute() === 'tables' ? 'active' : ''"
          navText="Barang Masuk"
        >
          <template v-slot:icon>
            <i
              class="ni ni-cloud-download-95 text-success text-sm opacity-10"
            ></i>
          </template>
        </sidenav-item>
      </li> -->
      <!-- <li class="nav-item">
        <sidenav-item
          url="/barang-keluar"
          :class="getRoute() === 'virtual-reality' ? 'active' : ''"
          navText="Barang Keluar"
        >
          <template v-slot:icon>
            <i class="ni ni-delivery-fast text-warning text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
          url="/remaind-stock"
          :class="getRoute() === 'billing' ? 'active' : ''"
          navText="Remaind Stock"
        >
          <template v-slot:icon>
            <i class="ni ni-bell-55 text-danger text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
          url="/stock-barang"
          :class="getRoute() === 'billing' ? 'active' : ''"
          navText="Master Data"
        >
          <template v-slot:icon>
            <i class="ni ni-collection text-dark text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item" v-if="role == 'Admin'">
        <sidenav-item
          url="/account"
          :class="getRoute() === 'rtl-page' ? 'active' : ''"
          navText="Account"
        >
          <template v-slot:icon>
            <i class="ni ni-satisfied text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li> -->

    </ul>
  </div>
</template>
<script>
import SidenavItem from "./SidenavItem.vue";
import config from '@/configs/config';
import axios from 'axios';
import Api from '@/helpers/api';
import akun from '@/services/akun.service';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export default {
  name: "SidenavList",
  props: {
    cardBg: String
  },
  data() {
    return {
      title: "Argon Dashboard 2",
      controls: "dashboardsExamples",
      isActive: "active",
      role: '',
      table: {
        data: []
      },
      showMenu: '',
    };
  },
  components: {
    SidenavItem,
  },
  mounted(){
    if(!window.location.href.includes('proc-form-rfq-vendor')){
      this.getMenu();
    }
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
    getMenu(){
      let context = this;     
      context.onLoading = true;
      Api(context, akun.getMenu()).onSuccess(function(response) {
          context.table.data = response.data.data;
      }).onError(function(error) {  
      })
      .call() 
    },
    showSubMenu(id){
      this.showMenu = id
    },
  }
};
</script>
<style type="text/css">
  /*.nav-item.nav-with-child > .nav-item-child
{
    list-style: none;
    height: 0;
    min-height: 0px;
    overflow: hidden !important;
    padding: 0px 1.5rem;
    transition: all 0.5s ease-in-out;
    margin-left: 25px;
}
.nav-item.nav-with-child.nav-item-expanded > .nav-item-child
 {
    padding: 0.5rem 1.5rem;
    position: relative;
    height: auto;
    min-height: 50px;
    display: block;
    transition: all 0.5s ease-in-out;
}*/

#selectul ul li:hover ul li {
    border-radius: 0;
}
</style>
