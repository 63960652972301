import Service from '@/helpers/service'

export default {

    index(params) {
        return Service().get('employee/index', {params});
    },
    show(id) {
        return Service().get('employee/show/'+id);
    },
    getEmployee(params) {
        return Service().get('employee/get-employee', {params});
    },
    create(params) {
        return Service().post('employee/create', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    update(id, params) {
        return Service().post('employee/update/'+id, params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    delete(id) {
        return Service().post('employee/delete/'+id);
    },
    import(params) {
        return Service().post('import/import-data-employee', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    changePassFoto(params) {
        return Service().post('employee/change-password-foto', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
}