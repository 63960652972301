<template>
  <div class="py-4 container-fluid">
    <!-- <a class="btn btn-sm btn-primary" style="margin-right: 10px;" :href="apiUrl+'export-excel/karyawan?id_karyawan='+search.id_karyawan+'&nama='+search.nama+'&nik='+search.nik+'&jabatan='+search.jabatan+'&unit='+search.unit+'&status='+search.status+''" target="_BLANK"><i class="fa fa-download fa-sm"></i> Export</a> -->
    <!-- <argon-button color="info" size="sm" class="mb-3" variant="gradient" style="margin-right: 10px;"><i class="fa fa-download fa-sm"></i> Export</argon-button> -->
    <!-- <argon-button color="warning" size="sm" class="mb-3" variant="gradient" @click="modalImport()"><i class="fa fa-upload fa-sm"></i> Import</argon-button> -->
    <div class=" row">
      <div class="col-12">
          <div class="card"> 
            <div class="row">
              <div class="col-4">
                <div class="card-header pb-0">
                  <h6>Setting Shift</h6>
                </div>
              </div>
              <div class="col-4">
              </div>
              <div class="col-4 float-right">
                <argon-button
                  style="margin-right: 10px; margin-left: 60px;"
                  class="mt-4"
                  variant="gradient"
                  color="secondary"
                  size="sm"
                  @click="filter()"
                ><i class="fa fa-filter fa-sm" aria-hidden="true"></i> Filter</argon-button>
                <argon-button
                  class="mt-4"
                  variant="gradient"
                  color="success"
                  size="sm"
                  @click="create()"
                ><i class="fa fa-plus fa-sm" aria-hidden="true"></i> Add New</argon-button>
              </div>
            </div>
            
            <div class="card-body px-0 pt-0 pb-2 mt-4">
              <div class="table-responsive p-0 scroll">
                <table class="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder ">Nama Shift</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder ">Jam Masuk Start</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder ">Jam Masuk End</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder ">Jam Keluar Start</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder ">Jam Keluar End</th>
                      <th class="text-secondary"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, i) in table.data" :key="i">
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.shift }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.jam_masuk_start  }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.jam_masuk_end }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.jam_keluar_start }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.jam_keluar_end }}</span>
                      </td>
                      <td>
                        <i class="fas fa-edit fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="edit(row.id)" title="Edit"></i>
                        <i class="fa fa-trash fa-sm" aria-hidden="true" title="Hapus" style="cursor: pointer; margin-right: 20px;" @click="remove(row.id)"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer">
              <!-- <div>
                <argon-pagination class="float-right">
                  <argon-pagination-item prev />
                  <argon-pagination-item label="1" active />
                  <argon-pagination-item label="2" disabled />
                  <argon-pagination-item label="3" />
                  <argon-pagination-item next />
                 </argon-pagination>
              </div> -->
            </div>
          </div>
      </div>
    </div>

    <!-- TABEL UBAH SHIFT KARYAWAN -->
    <div class="row mt-4">
      <div class="col-12">
          <div class="card"> 
            <div class="row">
              <div class="col-4">
                <div class="card-header pb-0">
                  <h6>Ubah Shift Karyawan</h6>
                </div>
              </div>
              <div class="col-3">
                <select class="form-select mt-3" aria-label="Default select example" v-model="searchKaryawan.change_shift">
                  <option selected>Select Shift</option>
                  <option v-for="(row, i) in table.data" :key="i" :value="row.shift">{{ row.shift }}</option>
                </select>
              </div>
              <div class="col-5 float-right">
                <argon-button
                  style="margin-right: 10px; margin-left: 60px;"
                  class="mt-4"
                  variant="gradient"
                  color="secondary"
                  size="sm"
                  @click="filterKaryawan()"
                ><i class="fa fa-filter fa-sm" aria-hidden="true"></i> Cari Karyawan</argon-button>
                <argon-button
                  class="mt-4"
                  variant="gradient"
                  color="success"
                  size="sm"
                  @click="saveSettingShift()"
                ><i class="fa fa-plus fa-sm" aria-hidden="true"></i> Save</argon-button>
              </div>
            </div>
            <div class="card-body px-0 pt-0 pb-2 mt-4">
              <div class="table-responsive p-0 scroll">
                <table class="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder ">Nama</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder  ps-2">Unit</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder ">Shift</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder ">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, i) in tableKaryawan.data" :key="i">
                      <td>
                        <router-link :to="/detail-profile/+row.id_karyawan">
                          <div class="d-flex px-2 py-1">
                            <div>
                              <img
                                v-if="row.foto_karyawan"
                                :src="storageUrl+'/foto_karyawan/'+row.foto_karyawan"
                                class="avatar avatar-sm me-3"
                                alt="user1"
                              />
                              <img
                                v-else
                                src="../../assets/img/avatar_karyawan.png"
                                class="avatar avatar-sm me-3"
                                alt="user1"
                              />
                            </div>
                            <div class="d-flex flex-column justify-content-center">
                              <h6 class="mb-0 text-sm">{{ row.nama }}</h6>
                              <p class="text-xs text-secondary mb-0">{{ row.jabatan}}</p>
                            </div>
                          </div>
                        </router-link>
                      </td>
                      <td>
                        <p class="text-xs font-weight-bold mb-0">{{ row.unit }}</p>
                        <p class="text-xs text-secondary mb-0">{{ row.id_karyawan }}</p>
                      </td>
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.shift }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="badge badge-sm bg-gradient-success">{{ row.status }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer">
              <!-- <div>
                <argon-pagination class="float-right">
                  <argon-pagination-item prev />
                  <argon-pagination-item label="1" active />
                  <argon-pagination-item label="2" disabled />
                  <argon-pagination-item label="3" />
                  <argon-pagination-item next />
                 </argon-pagination>
              </div> -->
            </div>
          </div>
      </div>
    </div>
  </div>

  <!-- =======  MODAL ADD DATA ======= -->
  <div class="container">
    <vue-final-modal v-model="form.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{form.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="form.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Nama Shift <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Nama Shift" v-model="dataShift.shift" required>
        <label for="example-text-input" class="form-control-label mt-3">Jam Masuk Start <span style="color: red;">*</span></label>
        <input type="time" class="form-control" placeholder="NIK" v-model="dataShift.jam_masuk_start">
        <label for="example-text-input" class="form-control-label mt-3">Jam Masuk End <span style="color: red;">*</span></label>
        <input type="time" class="form-control" placeholder="NIK" v-model="dataShift.jam_masuk_end">
        <label for="example-text-input" class="form-control-label mt-3">Jam Keluar Start <span style="color: red;">*</span></label>
        <input type="time" class="form-control" placeholder="NIK" v-model="dataShift.jam_keluar_start">
        <label for="example-text-input" class="form-control-label mt-3">Jam Keluar End <span style="color: red;">*</span></label>
        <input type="time" class="form-control" placeholder="NIK" v-model="dataShift.jam_keluar_end">
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="form.show = true">Close</argon-button>
        </div>
        <div class="col-1">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="save()">Save</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

  <!-- =======  MODAL FILTER ======= -->
  <div class="container">
    <vue-final-modal v-model="formFilter.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formFilter.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formFilter.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Nama Shift</label>
        <input type="text" class="form-control" placeholder="ID Karyawan" v-model="search.shift">
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="formFilter.show = true">Close</argon-button>
        </div>
        <div class="col-1">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="get()">Filter</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

    <!-- =======  MODAL CARI KARYAWAN======= -->
  <div class="container">
    <vue-final-modal v-model="formFilterKaryawan.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formFilterKaryawan.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formFilterKaryawan.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">ID Karyawan</label>
        <input type="text" class="form-control" placeholder="ID Karyawan" v-model="searchKaryawan.id_karyawan">
        <label for="example-text-input" class="form-control-label mt-3">Nama</label>
        <input type="text" class="form-control" placeholder="Nama" v-model="searchKaryawan.nama" required>
        <label for="example-text-input" class="form-control-label mt-3">Jabatan</label>
        <input type="text" class="form-control" placeholder="Jabatan" v-model="searchKaryawan.jabatan" required>
        <label for="example-text-input" class="form-control-label mt-3">Unit</label>
        <input type="text" class="form-control" placeholder="Unit" v-model="searchKaryawan.unit" required>
        <label for="example-text-input" class="form-control-label mt-3">Shift</label>
        <select class="form-select" aria-label="Default select example" v-model="searchKaryawan.shift">
          <option selected>Select Shift</option>
          <option v-for="(row, i) in table.data" :key="i" :value="row.shift">{{ row.shift }}</option>
        </select>
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="formFilterKaryawan.show = true">Close</argon-button>
        </div>
        <div class="col-1">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="getKaryawan()">Filter</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
import { VueFinalModal } from 'vue-final-modal'
// import ArgonPagination from "@/components/ArgonPagination.vue";
// import ArgonPaginationItem from "@/components/ArgonPaginationItem.vue";
import Api from '@/helpers/api';
import config from '@/configs/config';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import settingShift from '@/services/human_resource/settingShift.service';
import employee from '@/services/human_resource/employee.service';
import akun from '@/services/akun.service';

export default {
  name: "tables",
  components: {
    ArgonButton,
    VueFinalModal,
    // ArgonPagination,
    // ArgonPaginationItem,
  },
  data() {
    return {
      table: {
        data: []
      },
      tableKaryawan: {
        data: []
      },
      form: {
        add: true,
        title: "Add Data",
        show: false
      },
      formImport: {
        add: true,
        title: "Import Data Setting Approval",
        show: false
      },
      formFilter: {
        add: true,
        title: "Filter",
        show: false
      },
      formFilterKaryawan: {
        add: true,
        title: "Filter",
        show: false
      },
      dataImport: '',
      onLoading: false,
      tabelError: {
        data: []
      },
      storageUrl : config.storageUrl,
      dataShift: {},
      search: {
        shift: '',
      },
      searchKaryawan: {
        id_karyawan: '',
        nama: '',
        nik: '',
        jabatan: '',
        unit: '',
        status: '',
        shift: '',
      },
      apiUrl :config.apiUrl,
      role: '',
    };
  },
  mounted(){
    this.get();
    this.tokenApi = 'Bearer '+localStorage.getItem('token');
    this.getRole();
  },
  methods: {
    get(param){
      let context = this;               
      Api(context, settingShift.index({shift: context.search.shift})).onSuccess(function(response) {    
          context.table.data = response.data.data.data;
          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.table.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.formFilter.show  = false;
      })
      .call()
    },
    getRole(){
      let context = this;     
      context.onLoading = true;
      Api(context, akun.indexProfile()).onSuccess(function(response) {
          context.role = response.data.data[0].role;
          if (response.data.data[0].role != 'Admin') {
            context.$router.push({ path: /detail-profile/+response.data.data[0].employee_id})
          }
      }).onError(function(error) {  
      })
      .call() 
    },
    filter() {
      this.formFilter.add   = true;
      this.formFilter.show  = true;
      this.formFilter.title = "Filter";
      this.onLoading = false;
    },
    create() {
      this.form.add   = true;
      this.form.show  = true;
      this.form.title = "Add Data";
      this.dataShift   = {};
    },
    edit(id) {
      let context = this;               
      Api(context, settingShift.show(id)).onSuccess(function(response) {
        console.log(response.data.data)
          context.dataShift  = response.data.data;
          context.form.show  = true;
          context.form.title = 'Edit Data';       
      })
      .call()        
    },
    save(){
      let api      = null;
      let context  = this;
      let formData = new FormData();

      if (context.dataShift.shift != undefined && context.dataShift.jam_masuk_start != undefined && context.dataShift.jam_masuk_end != undefined && context.dataShift.jam_keluar_start != undefined && context.dataShift.jam_keluar_end != undefined) {
        formData.append('shift', context.dataShift.shift);
        formData.append('jam_masuk_start', context.dataShift.jam_masuk_start);
        formData.append('jam_masuk_end', context.dataShift.jam_masuk_end);
        formData.append('jam_keluar_start', context.dataShift.jam_keluar_start);
        formData.append('jam_keluar_end', context.dataShift.jam_keluar_end);
      }else{
        return alert('Field Bintang Merah Wajib Di Isi')
      }

      if (context.form.title == 'Add Data') {
          api = Api(context, settingShift.create(formData));
      } else {
          api = Api(context, settingShift.update(this.dataShift.id, formData));
      }
      // eslint-disable-next-line no-unused-vars
      api.onSuccess(function(response) {
        context.get();
        context.form.show = false;
        context.notify((context.form === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      // eslint-disable-next-line no-unused-vars
      }).onError(function(error) { 
        context.notify((context.form != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
      })
      .call();
    },
    remove(id) {
      var r = confirm("Anda yakin ingin menghapus data ini ?");
      if (r == true) {
        let context = this;

        Api(context, settingShift.delete(id)).onSuccess(function(response) {
            context.get();
            context.notify('Data Successfully Deleted', 'success')
        }).call();
      }
    },

    // ======== UBAH SHIFT KARYAWAN ========
    filterKaryawan() {
      this.formFilterKaryawan.add   = true;
      this.formFilterKaryawan.show  = true;
      this.formFilterKaryawan.title = "Cari Karyawan";
      this.onLoading = false;
    },
    getKaryawan(){
      let context = this;               
      Api(context, employee.index({id_karyawan: context.searchKaryawan.id_karyawan, nama: context.searchKaryawan.nama, nik: context.searchKaryawan.nik, jabatan: context.searchKaryawan.jabatan, unit: context.searchKaryawan.unit, shift: context.searchKaryawan.shift,})).onSuccess(function(response) {    
          context.tableKaryawan.data = response.data.data.data;
          console.log(response.data.data.data)
          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableKaryawan.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.formFilterKaryawan.show  = false;
      })
      .call()
    },
    saveSettingShift(){
      let context = this;               
      Api(context, settingShift.saveShiftKaryawan({id_karyawan: context.searchKaryawan.id_karyawan, nama: context.searchKaryawan.nama, nik: context.searchKaryawan.nik, jabatan: context.searchKaryawan.jabatan, unit: context.searchKaryawan.unit, status: context.searchKaryawan.status, shift: context.searchKaryawan.shift, change_shift: context.searchKaryawan.change_shift})).onSuccess(function(response) {    
          context.tableKaryawan.data = response.data.data.data;
          context.notify('Data Retrieved Successfully', 'success')
          context.getKaryawan()
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableKaryawan.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.formFilterKaryawan.show  = false;
      })
      .call()
    },
    notify(message, type) {
      if (type == 'success') {
        toast.success(message, {
          autoClose: 2000,
        }); // ToastOptions
      }else{
        toast.error(message, {
          autoClose: 2000,
        }); // ToastOptions
      }
    },

  }
};
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 500px;
  margin-top: auto;
  margin-bottom: auto;
}
.modal__title {
  font-size: 1rem;
  font-weight: 600;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
.scroll { 
  overflow: auto; 
  height: 250px; 
}
.scroll thead th { 
  position: sticky; 
  top: 0; 
  z-index: 100; 
  background-color: #F0F8FF;
}
</style>
