import Service from '@/helpers/service'

export default {

    index(params) {
        return Service().get('purchase-order/index', {params});
    },
    getWarehouseLocation(params) {
        return Service().get('purchase-order/get-purchase-order', {params});
    },
    generatePONo(params) {
        return Service().get('purchase-order/generate-po-no', {params});
    },
    create(params) {
        return Service().post('purchase-order/create', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    delete(id, params) {
        return Service().post('purchase-order/delete/'+id, params);
    },
    // ------- ITEM ROUTE -----
    transactionDetail(params) {
        return Service().get('purchase-order/transaction-detail', {params});
    },
    showItem(id) {
        return Service().get('purchase-order/show-item/'+id);
    },
    deleteItem(id, params) {
        return Service().post('purchase-order/delete-item/'+id, params);
    },
    fixData(params) {
        return Service().post('purchase-order/fix-data', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
}