import Service from '@/helpers/service'

export default {

    index(params) {
        return Service().get('working-group/index', {params});
    },
    getGroup(params) {
        return Service().get('working-group/get-working-group', {params});
    },
    show(id) {
        return Service().get('working-group/show/'+id);
    },
    create(params) {
        return Service().post('working-group/create', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    update(id, params) {
        return Service().post('working-group/update/'+id, params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    delete(id) {
        return Service().post('working-group/delete/'+id);
    },
}