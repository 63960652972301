<template>
  <div class="py-4 container-fluid">
    <!-- <a class="btn btn-sm btn-primary" style="margin-right: 10px;" :href="apiUrl+'export-excel/karyawan?id_karyawan='+search.id_karyawan+'&nama='+search.nama+'&nik='+search.nik+'&jabatan='+search.jabatan+'&unit='+search.unit+'&status='+search.status+''" target="_BLANK"><i class="fa fa-download fa-sm"></i> Export</a> -->
    <!-- <argon-button color="info" size="sm" class="mb-3" variant="gradient" style="margin-right: 10px;"><i class="fa fa-download fa-sm"></i> Export</argon-button> -->
    <!-- <argon-button color="warning" size="sm" class="mb-3" variant="gradient" @click="modalImport()"><i class="fa fa-upload fa-sm"></i> Import</argon-button> -->
    <div class=" row">
      <div class="col-12">
          <div class="card"> 
            <div class="row">
              <div class="col-12 text-center">
                <div class="card-header pb-0">
                  <h6>ITEMS DETAIL</h6><hr>
                </div>
              </div>
            </div>
            
            <div class="card-body px-0 pt-0 pb-2 mt-4">
              <div class="row">
                <div class="col-lg-6 col-sm-12 text-center">
                  <img :src="storageUrl+'image_warehouse/'+dataItems.image" class="rounded mb-4 img-fluid" />
                </div>
                <div class="col-lg-6 col-sm-12">
                  <div class="container">
                    <p style="margin-top: 10px; font-size: 13px; font-weight: bold;">ITEM CODE <span style="margin-left: 68px; margin-right: 10px;">:</span> {{ dataItems.code }}</p>
                    <p style="margin-top: -7px; font-size: 13px; font-weight: bold;">ITEM NAME <span style="margin-left: 67px; margin-right: 10px;">:</span> {{ dataItems.name }}</p>
                    <p style="margin-top: -7px; font-size: 13px; font-weight: bold;">DESCRIPTION <span style="margin-left: 54px; margin-right: 10px;">:</span> {{ dataItems.description }}</p>
                    <p style="margin-top: -7px; font-size: 13px; font-weight: bold;">CATEGORY <span style="margin-left: 74px; margin-right: 10px;">:</span> {{ dataItems.category }}</p>
                    <p style="margin-top: -7px; font-size: 13px; font-weight: bold;">UOM <span style="margin-left: 110px; margin-right: 10px;">:</span> {{ dataItems.uom_code }}</p>
                    <p style="margin-top: -7px; font-size: 13px; font-weight: bold;">MIN STOCK <span style="margin-left: 70px; margin-right: 10px;">:</span> {{ dataItems.min_stock }}</p>
                    <p style="margin-top: -7px; font-size: 13px; font-weight: bold;">REORDER TIME DAYS <span style="margin-left: 10px; margin-right: 10px;">:</span> {{ dataItems.reorder_time_days }} Days</p>
                    <p style="margin-top: -7px; font-size: 13px; font-weight: bold;">REORDER QTY <span style="margin-left: 55px; margin-right: 10px;">:</span> {{ dataItems.reorder_qty }}</p>
                    <p style="margin-top: -7px; font-size: 13px; font-weight: bold;">AVERAGE UNIT PRICE <span style="margin-left: 10px; margin-right: 10px;">:</span> Rp. {{ convertRp(dataItems.average_price_count) }}</p>
                    <p style="margin-top: -10px; font-size: 15px; font-weight: bold;">ACTUAL STOCK <span style="margin-left: 35px; margin-right: 10px;">:</span> 
                      <span class="badge bg-gradient-success"> {{ dataItems.total_qty_item_count - dataItems.total_qty_item_on_loan_count }} </span>
                    </p>
                  </div>
                </div>
                <!-- WAREHOUSE LOCATION LIST -->
                <div>
                  <div class="container-fluid">
                  </div>
                  <div class="container-fluid">
                    <hr style="height: 5px;">
                    <h6><i class="fas fa-warehouse" style="margin-right: 10px;"></i> Warehouse Location</h6>
                    <div class="table-responsive p-0 scroll-min">
                      <table class="table table-hover align-items-center mb-0">
                        <thead>
                          <tr style="background-color: #6495ED; color: white;">
                            <th class="text-center text-uppercase text-white text-secondary text-xxs font-weight-bolder">No</th>
                            <th class="text-center text-uppercase text-white text-secondary text-xxs font-weight-bolder">Warehouse Location</th>
                            <th class="text-center text-uppercase text-white text-secondary text-xxs font-weight-bolder">Storage Location</th>
                            <th class="text-center text-uppercase text-white text-secondary text-xxs font-weight-bolder">Quantity</th>
                            <th class="text-center text-uppercase text-white text-secondary text-xxs font-weight-bolder">Quantity On Loan</th>
                            <th class="text-center text-uppercase text-white text-secondary text-xxs font-weight-bolder">Actual Stock</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(row, i) in tableWarehouseLocation.data" :key="i">
                            <td class="align-middle text-center text-sm">
                              <span class="text-secondary text-xs font-weight-bold">{{ i + 1 }}</span>
                            </td>
                            <td class="align-middle text-center text-sm">
                              <span class="text-secondary text-xs font-weight-bold">{{ row.warehouse_location }}</span>
                            </td>
                            <td class="align-middle text-center text-sm">
                              <span class="text-secondary text-xs font-weight-bold">{{ row.storage_location }}</span>
                            </td>
                            <!-- Quantity -->
                            <td class="align-middle text-center text-sm">
                              <span class="badge bg-gradient-success" >{{ row.qty }}</span>
                            </td>
                            <!-- Quantity On Loan -->
                            <td class="align-middle text-center text-sm">
                              <span class="badge bg-gradient-danger">{{ row.qty_on_loan }}</span>
                            </td>
                            <!-- Actual Stock -->
                            <td class="align-middle text-center text-sm">
                              <span class="badge bg-gradient-info">{{ row.qty - row.qty_on_loan }}</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- ITEM HISTORY -->
                <div>
                  <div class="container-fluid">
                  </div>
                  <div class="container-fluid">
                    <hr style="height: 5px;">
                    <h6><i class="fa fa-history" aria-hidden="true" style="margin-right: 10px;"></i> Item History</h6>
                    <div class="table-responsive p-0 scroll">
                      <table class="table table-hover align-items-center mb-0">
                        <thead>
                          <tr style="background-color: #F0F8FF;">
                            <th class="text-center text-uppercase text-white text-secondary text-xxs font-weight-bolder">No</th>
                            <th class="text-center text-uppercase text-white text-secondary text-xxs font-weight-bolder">Transaction Number</th>
                            <th class="text-center text-uppercase text-white text-secondary text-xxs font-weight-bolder">Transaction Type</th>
                            <th class="text-center text-uppercase text-white text-secondary text-xxs font-weight-bolder">In / Out</th>
                            <th class="text-center text-uppercase text-white text-secondary text-xxs font-weight-bolder">Warehouse Location</th>
                            <th class="text-center text-uppercase text-white text-secondary text-xxs font-weight-bolder">From Location</th>
                            <th class="text-center text-uppercase text-white text-secondary text-xxs font-weight-bolder">Qty</th>
                            <th class="text-center text-uppercase text-white text-secondary text-xxs font-weight-bolder">Date</th>
                            <th class="text-center text-uppercase text-white text-secondary text-xxs font-weight-bolder">Additional Info</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(row, i) in tableItemHistory.data" :key="i">
                            <td class="align-middle text-center text-sm">
                              <span class="text-secondary text-xs font-weight-bold">{{ i + 1 }}</span>
                            </td>
                            <td class="align-middle text-center text-sm">
                              <router-link :to="{ name: 'TransactionInventoryDetail', params: { type: row.transaction_type, code: row.transaction_number }}">
                                <span class="badge bg-gradient-primary">{{ row.transaction_number }}</span>
                              </router-link>
                            </td>
                            <td class="align-middle text-center text-sm">
                              <span class="text-secondary text-xs font-weight-bold">{{ row.transaction_type }}</span>
                            </td>
                            <td class="align-middle text-center text-sm">
                              <span class="text-secondary text-xs font-weight-bold" v-if="row.transaction_type == 'Reciev New' || row.transaction_type == 'Return'">IN</span>
                              <span class="text-secondary text-xs font-weight-bold" v-if="row.transaction_type == 'Usage / Loan' || row.transaction_type == 'Reduction'">OUT</span>
                            </td>
                            <td class="align-middle text-center text-sm">
                              <span class="text-secondary text-xs font-weight-bold">{{ row.warehouse_location }}</span>
                            </td>
                            <td class="align-middle text-center text-sm">
                              <span class="text-secondary text-xs font-weight-bold">{{ row.change_location }}</span>
                            </td>
                            <td class="align-middle text-center text-sm">
                              <span class="badge bg-gradient-success" v-if="row.transaction_type == 'Reciev New' || row.transaction_type == 'Return'">+ {{ row.qty }}</span>
                              <span class="badge bg-gradient-danger" v-if="row.transaction_type == 'Usage / Loan' || row.transaction_type == 'Reduction'">- {{ row.qty }}</span>
                            </td>
                            <td class="align-middle text-center text-sm">
                              <span class="text-secondary text-xs font-weight-bold">{{ moment(row.date).locale('id').format('LL') }}</span>
                            </td>
                            <td class="align-middle text-left text-sm">
                              <template v-if="row.transaction_type == 'Reciev New'">
                                <span class="text-secondary text-xs font-weight-bold"><span class="font-weight-bold text-dark">PO NUMBER : </span> {{ row.po_number }}</span><br>
                                <span class="text-secondary text-xs font-weight-bold"><span class="font-weight-bold text-dark">DELIVERY ORDER NUMBER : </span> {{ row.delivery_order_number }}</span><br>
                                <span class="text-secondary text-xs font-weight-bold"><span class="font-weight-bold text-dark">SUPPLIER : </span> {{ row.supplier }}</span><br>
                                <span class="text-secondary text-xs font-weight-bold"><span class="font-weight-bold text-dark">UNIT PRICE : </span> Rp. {{ convertRp(row.unit_price) }}</span><br>
                              </template>
                              <template v-if="row.transaction_type == 'Usage / Loan' || row.transaction_type == 'Return'">
                                <span class="text-secondary text-xs font-weight-bold"><span class="font-weight-bold text-dark">PROJECT : </span> {{ row.project }}</span><br>
                                <span class="text-secondary text-xs font-weight-bold"><span class="font-weight-bold text-dark">USER : </span> {{ row.user }}</span><br>
                              </template>
                              <template v-if="row.transaction_type == 'Reduction'">
                                <span class="text-secondary text-xs font-weight-bold"><span class="font-weight-bold text-dark">REDUCED SELECT : </span> {{ row.reducted_select }}</span><br>
                                <span class="text-secondary text-xs font-weight-bold"><span class="font-weight-bold text-dark">REDUCTION CATEGORY : </span> {{ row.reduction_category }}</span><br>
                                <span class="text-secondary text-xs font-weight-bold"><span class="font-weight-bold text-dark">ATTACHMENT : </span> <a :href="storageUrl+'/attachment_inv_transaction/'+row.attachment" target="_BLANK" style="color: blue;">See Attachment</a></span><br>
                                <span class="text-secondary text-xs font-weight-bold"><span class="font-weight-bold text-dark">USER : </span> {{ row.user }}</span><br>
                              </template>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="card-footer">
            </div>
          </div>
      </div>
    </div>
  </div>

  <!-- =======  MODAL ADD DATA ======= -->
  <div class="container">
    <vue-final-modal v-model="form.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{form.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="form.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Name <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Name" v-model="supplierData.name">
        <label for="example-text-input" class="form-control-label mt-3">Address <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Address" v-model="supplierData.address">
        <label for="example-text-input" class="form-control-label mt-3">Phone Number <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Phone Number" v-model="supplierData.phone_number">
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="form.show = true">Close</argon-button>
        </div>
        <div class="col-1">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="save()">Save</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

   <!-- =======  MODAL FILTER ======= -->
  <div class="container">
    <vue-final-modal v-model="formFilter.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formFilter.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formFilter.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Name</label>
        <input type="text" class="form-control" placeholder="Name" v-model="search.name" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Address</label>
        <input type="text" class="form-control" placeholder="Address" v-model="search.address" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Phone Number</label>
        <input type="text" class="form-control" placeholder="Phone Number" v-model="search.phone_number" v-on:keyup.enter="get()">
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="search = {}">Clear</argon-button>
        </div>
        <div class="col-1">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="get()">Filter</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

  <!-- =======  MODAL IMPORT ======= -->
  <div class="container">
    <vue-final-modal v-model="formImport.show" classes="modal-container" content-class="modal-content" :z-index="10000">
    <!-- header -->
    <div class="row">
      <div class="col-11 float-left">
        <span class="modal__title">{{formImport.title}}</span>
      </div>
      <div class="col-1 float-right">
        <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formImport.show = false"></i>
      </div>
    </div><hr>
    <!-- end header -->
    <div class="modal__content container">
      <div>
        <label for="example-text-input" class="form-control-label mt-3">Upload File <span style="color: red;">*</span></label>
        <input type="file" class="form-control" placeholder="Upload File" @change="filesChange" style="margin-bottom: 20px;">
        <small>Download Template<a :href="storageUrl+'/template_import/Template Import Data Users.xlsx'" style="color: blue;"> Import Data Setting Approval</a></small>
        <div class="mt-2" v-if="tabelError.data.length !== 0 ">
          <table>
            <thead>
              <slot name="columns">
                <tr style="background-color: #F0F8FF;">
                  <th style="font-size: 13px; text-align: center;">Column</th>
                  <th style="font-size: 13px; text-align: center;">Error</th>
                  <th style="font-size: 13px; text-align: center;">Row</th>
                  <th style="font-size: 13px; text-align: center;">Values</th>
                </tr>
              </slot>
            </thead>
            <tbody>
              <tr v-for="(row, i) in tabelError.data" :key="i">
                <td style="font-size: 13px; text-align: center;">{{ row.attribute }}</td>
                <td style="font-size: 13px; text-align: center;">{{ row.errors }}</td>
                <td style="font-size: 13px; text-align: center;">{{ row.row }}</td>
                <td style="font-size: 13px; text-align: center;">{{ row.values }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- footer -->
    <div class="row float-right mt-5">
      <div class="col-6"> 
      </div>
      <div class="col-2" style="margin-right: 20px;">
        <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="formImport.show = false">Close</argon-button>
      </div>
      <div class="col-1">
        <button type="primary" class="btn btn-sm btn-info btn-fill" @click="importData()" :disabled="onLoading">
          <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
          <span v-else>
              <span>Import</span>
          </span>
        </button>
        <!-- <argon-button variant="gradient" color="success" size="sm" width="1" @click="get(), formImport.show = false">Import</argon-button> -->
      </div>
    </div>
    <!-- end footer -->
    </vue-final-modal>
  </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
import { VueFinalModal } from 'vue-final-modal'
// import ArgonPagination from "@/components/ArgonPagination.vue";
// import ArgonPaginationItem from "@/components/ArgonPaginationItem.vue";
import Api from '@/helpers/api';
import config from '@/configs/config';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
var moment = require('moment');
import inventoryList from '@/services/inventoryList.service';

export default {
  name: "tables",
  components: {
    ArgonButton,
    VueFinalModal,
    // ArgonPagination,
    // ArgonPaginationItem,
  },
  data() {
    return {
      moment:moment,
      table: {
        data: []
      },
      tableWarehouseLocation: {
        data: []
      },
      tableItemHistory: {
        data: []
      },
      form: {
        add: true,
        title: "Add Data",
        show: false
      },
      formImport: {
        add: true,
        title: "Import Data Setting Approval",
        show: false
      },
      formFilter: {
        add: true,
        title: "Filter",
        show: false
      },
      dataImport: '',
      onLoading: false,
      tabelError: {
        data: []
      },
      storageUrl : config.storageUrl,
      dataItems: {},
      supplierData: {},
      search: {
        code: '',
        name: '',
        address: '',
      },
      apiUrl :config.apiUrl,

    };
  },
  mounted(){
    this.get();
    this.tokenApi = 'Bearer '+localStorage.getItem('token');
  },
  methods: {
    get(param){
      let context = this;               
      Api(context, inventoryList.itemsDetail({code: context.$route.params.code})).onSuccess(function(response) {    
          context.dataItems                   = response.data.data.data;
          context.tableWarehouseLocation.data = response.data.data.warehouseLocation;
          context.tableItemHistory.data       = response.data.data.itemHistory;
          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.dataItems = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
      })
      .call()
    },
    filter() {
      this.formFilter.add   = true;
      this.formFilter.show  = true;
      this.formFilter.title = "Filter";
      this.onLoading = false;
    },
    create() {
      this.form.add   = true;
      this.form.show  = true;
      this.form.title = "Add Data";
      this.supplierData   = {};
    },
    edit(id) {
      let context = this;               
      Api(context, inventoryList.show(id)).onSuccess(function(response) {
          context.supplierData = response.data.data;
          context.form.show      = true;
          context.form.title     = 'Edit Data';   
      })
      .call()        
    },
    save(){
      let api      = null;
      let context  = this;
      let formData = new FormData();

      if (context.supplierData.name != undefined && context.supplierData.address != undefined &&  context.supplierData.phone_number != undefined) {
        formData.append('name', context.supplierData.name);
        formData.append('address', context.supplierData.address);
        formData.append('phone_number', context.supplierData.phone_number);
      }else{
        return alert('The Red Star Field is Mandatory')
      }

      if (context.form.title == 'Add Data') {
          api = Api(context, inventoryList.create(formData));
      } else {
          api = Api(context, inventoryList.update(this.supplierData.id, formData));
      }
      // eslint-disable-next-line no-unused-vars
      api.onSuccess(function(response) {
        context.get();
        context.form.show = false;
        context.notify((context.form === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      // eslint-disable-next-line no-unused-vars
      }).onError(function(error) { 
        context.notify((context.form != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
      })
      .call();
    },
    remove(id) {
      var r = confirm("Are you sure you want to delete this data ?");
      if (r == true) {
        let context = this;

        Api(context, inventoryList.delete(id)).onSuccess(function(response) {
            context.get();
            context.notify('Data Successfully Deleted', 'success')
        }).call();
      }
    },
    modalImport(){
      this.formImport.show  = true;
      this.formImport.title = "Import Data Setting Approval";
      this.tabelError.data  = [];
    },
    filesChange(e) {
        this.dataImport = e.target.files[0];
    },
    importData(){
      let api = null;
      let context = this;
      let formData = new FormData();
      this.onLoading = true;

      if (this.dataImport != undefined) {
        formData.append('import_data', this.dataImport);
      }else{
        return alert('File Import Not Found')
      }

      api = Api(context, inventoryList.import(formData));
      api.onSuccess(function(response) {
          context.onLoading = false;
          context.get();
          context.formImport.show = false;
          context.notify('Import Data Success', 'success')
      }).onError(function(error) {      
          context.tabelError.data = error.response.data.data;              
          context.notify('Import Data Failed', 'error')
          context.onLoading = false;
      }).onFinish(function() {  
      })
      .call();
    },
    convertRp(bilangan) {
      if (bilangan) {
        var number_string = bilangan.toString(),
            sisa    = number_string.length % 3,
            rupiah  = number_string.substr(0, sisa),
            ribuan  = number_string.substr(sisa).match(/\d{3}/g);

        if(ribuan){
          var separator = sisa ? '.' : '';
          rupiah += separator + ribuan.join('.');
          return rupiah
        }else{
          return bilangan
        }
      }
    },
    notify(message, type) {
      if (type == 'success') {
        toast.success(message, {
          autoClose: 2000,
        }); // ToastOptions
      }else{
        toast.error(message, {
          autoClose: 2000,
        }); // ToastOptions
      }
    },

  }
};
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 500px;
  margin-top: auto;
  margin-bottom: auto;
}
.modal__title {
  font-size: 1rem;
  font-weight: 600;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
.scroll { 
  overflow: auto; 
  height: 600px; 
}
.scroll-min { 
  overflow: auto; 
  height: 300px; 
}
.scroll thead th { 
  position: sticky; 
  top: 0; 
  z-index: 100; 
  background-color: #6495ED;
}
</style>
