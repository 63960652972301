<template>
  <div class="py-4 container-fluid">
    <a class="btn btn-sm btn-primary" style="margin-right: 10px;" :href="apiUrl+'export-excel/daily-control-board?group='+search.group['group_name']+'&employee='+search.employee['nik']+'&date='+search.date+''" target="_BLANK"><i class="fa fa-file-excel-o"></i> Export to Excel</a>
    <a class="btn btn-sm btn-warning" style="margin-right: 10px;" :href="apiUrl+'print-pdf/daily-control-board?group='+search.group['group_name']+'&employee='+search.employee['nik']+'&date='+search.date+''" target="_BLANK"><i class="fa fa-file-pdf-o"></i> Export to PDF</a>
    <div class=" row">
      <div class="col-12">
          <div class="card"> 
            <div class="row">
              <div class="col-4">
                <div class="card-header pb-0">
                  <h6>Daily Control Board</h6>
                </div>
              </div>
              <div class="col-6">
              </div>
              <div class="col-2 float-right">
                <argon-button
                  style="margin-right: 10px; margin-left: 60px;"
                  class="mt-4"
                  variant="gradient"
                  color="secondary"
                  size="sm"
                  @click="filter()"
                ><i class="fa fa-filter fa-sm" aria-hidden="true"></i> Filter</argon-button>
              </div>
            </div>
            
            <div class="card-body px-0 pt-0 pb-2 mt-4">
              <div class="table-responsive p-0 scroll">
                <table class="table table-hover table-striped align-items-center mb-0">
                  <thead>
                    <tr>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">No</th>
                      <th class="text-left text-uppercase text-white text-xxs font-weight-bolder">Date</th>
                      <th class="text-left text-uppercase text-white text-xxs font-weight-bolder">Group</th>
                      <th class="text-left text-uppercase text-white text-xxs font-weight-bolder">Employee</th>
                      <th class="text-left text-uppercase text-white text-xxs font-weight-bolder">Total Working Hour</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, i) in table.data" :key="i">
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ i + 1 }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-dark text-xs font-weight-bold">{{ moment(row.start_working).locale('id').format('ll') }} </span><br>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-dark text-xs font-weight-bold">{{ row.group_name }} </span><br>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-dark text-xs font-weight-bold">{{ row.employee_name }} </span><br>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-dark text-xs font-weight-bold">{{ row.total_hour }} Hour {{ row.total_minute }} Minute </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer">
              <div class="float-right">
                <span class="font-weight-bold text-danger ">TOTAL WORKING HOUR : {{ totalWorkingHour }} Hour {{ totalWorkingMinutes }} Minutes</span>
              </div>
              <div class="float-right">
                <base-pagination :page-count="pagination.page_count" :value="pagination.page" @input="changePage" :total="pagination.page_count" class="justify-content-end"></base-pagination>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>

   <!-- =======  MODAL FILTER ======= -->
  <div class="container">
    <vue-final-modal v-model="formFilter.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formFilter.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formFilter.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Group</label>
        <multiselect v-model="search.group" :options="tableGroup.data" :multiple="false" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Select Group" label="group_name" track-by="group_name" :preselect-first="true" @keyup="getGroup"  v-on:keyup.enter="get()">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
        <label for="example-text-input" class="form-control-label mt-3">Employee</label>
        <multiselect v-model="search.employee" :options="tableEmployee.data" :multiple="false" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Select Employee" label="name" track-by="name" :preselect-first="true" @keyup="getEmployee" v-on:keyup.enter="get()">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
        <label for="example-text-input" class="form-control-label mt-3">Date Range</label>
        <flat-pickr :config="{ mode: 'range',}" class="form-control" v-model="search.date"/>
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="search = {}">Clear</argon-button>
        </div>
        <div class="col-1">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="get()">Filter</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

</template>

<script>

import ArgonButton from "@/components/ArgonButton.vue";
import { VueFinalModal } from 'vue-final-modal'
// import ArgonPagination from "@/components/ArgonPagination.vue";
// import ArgonPaginationItem from "@/components/ArgonPaginationItem.vue";
import Api from '@/helpers/api';
import config from '@/configs/config';
import { toast } from 'vue3-toastify';
import Multiselect from 'vue-multiselect'
import 'vue3-toastify/dist/index.css';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import BasePagination from "@/components/BasePagination";
var moment = require('moment');
import reportWorkingHour from '@/services/reportWorkingHour.service';
import workingGroup from '@/services/workingGroup.service';
import employee from '@/services/employee.service';

export default {
  name: "tables",
  components: {
    ArgonButton,
    VueFinalModal,
    Multiselect,
    flatPickr,
    BasePagination
  },
  data() {
    return {
      pagination: {
        page_count: '',
        page: 1,
      },
      moment:moment,
      table: {
        data: []
      },
      tableGroup: {
        data: []
      },
      tableEmployee: {
        data: []
      },
      form: {
        add: true,
        title: "Add Data",
        show: false
      },
      formImport: {
        add: true,
        title: "Import Data Setting Approval",
        show: false
      },
      formFilter: {
        add: true,
        title: "Filter",
        show: false
      },
      dataImport: '',
      onLoading: false,
      tabelError: {
        data: []
      },
      storageUrl : config.storageUrl,
      reportWorkingHourData: {},
      search: {
        group: '',
        employee: '',
        date: '',
      },
      searchMenu: '',
      apiUrl :config.apiUrl,
      totalWorkingHour: '',
      totalWorkingMinutes: '',

    };
  },
  mounted(){
    this.get();
    this.tokenApi = 'Bearer '+localStorage.getItem('token');
  },
  methods: {
    get(param){
      let context = this;               
      Api(context, reportWorkingHour.getDailyControlBoard({group: this.search.group['group_name'], employee: this.search.employee['nik'], date: this.search.date, page: context.pagination.page})).onSuccess(function(response) {    
          context.table.data            = response.data.data.data.data;
          context.pagination.page_count = response.data.data.data.last_page
          context.totalWorkingHour      = response.data.data.totalHour;
          context.totalWorkingMinutes   = response.data.data.totalMinutes;

          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.table.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.formFilter.show  = false;
      })
      .call()
    },
    getGroup(param){
      let context = this;        
      Api(context, workingGroup.getGroup({search: param.target.value})).onSuccess(function(response) {    
          context.tableGroup.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableGroup.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    getEmployee(param){
      let context = this;        
      Api(context, employee.getEmployee({search: param.target.value})).onSuccess(function(response) {    
          context.tableEmployee.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableEmployee.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    filter() {
      this.formFilter.add   = true;
      this.formFilter.show  = true;
      this.formFilter.title = "Filter";
      this.onLoading        = false;
      this.pagination.page  = 1 ;
    },
    create() {
      this.form.add   = true;
      this.form.show  = true;
      this.form.title = "Add Data";
      this.reportWorkingHourData   = {};
    },
    edit(id) {
      let context = this;               
      Api(context, reportWorkingHour.show(id)).onSuccess(function(response) {
          context.reportWorkingHourData          = response.data.data.data;
          context.reportWorkingHourData.project  = response.data.data.dataProject
          context.reportWorkingHourData.activity = response.data.data.dataActivity
          context.reportWorkingHourData.group    = response.data.data.dataGroup
          context.reportWorkingHourData.employee = response.data.data.dataEmployee
          context.form.show                  = true;
          context.form.title                 = 'Edit Data';   
      })
      .call()        
    },
    save(){
      let api      = null;
      let context  = this;
      let formData = new FormData();

      if (context.reportWorkingHourData.project != undefined && context.reportWorkingHourData.activity != undefined && context.reportWorkingHourData.group != undefined && context.reportWorkingHourData.employee != undefined && context.reportWorkingHourData.start_working_date != undefined && context.reportWorkingHourData.start_working_time != undefined && context.reportWorkingHourData.end_working_date != undefined && context.reportWorkingHourData.end_working_time != undefined) {
        formData.append('project', JSON.stringify(context.reportWorkingHourData.project));
        formData.append('activity', JSON.stringify(context.reportWorkingHourData.activity));
        formData.append('group', JSON.stringify(context.reportWorkingHourData.group));
        formData.append('employee', JSON.stringify(context.reportWorkingHourData.employee));
        formData.append('start_working_date', context.reportWorkingHourData.start_working_date);
        formData.append('start_working_time', context.reportWorkingHourData.start_working_time);
        formData.append('end_working_date', context.reportWorkingHourData.end_working_date);
        formData.append('end_working_time', context.reportWorkingHourData.end_working_time);
        formData.append('attachment', context.reportWorkingHourData.attachment);
        formData.append('remarks', (context.reportWorkingHourData.remarks == undefined) ? '-' : context.reportWorkingHourData.remarks);
      }else{
        return alert('The Red Star Field is Mandatory')
      }

      if (context.form.title == 'Add Data') {
          api = Api(context, reportWorkingHour.create(formData));
      } else {
          api = Api(context, reportWorkingHour.update(this.reportWorkingHourData.id, formData));
      }
      // eslint-disable-next-line no-unused-vars
      api.onSuccess(function(response) {
        context.get();
        context.form.show = false;
        context.notify((context.form === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      // eslint-disable-next-line no-unused-vars
      }).onError(function(error) { 
        context.notify((context.form != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
      })
      .call();
    },
    remove(id) {
      var r = confirm("Are you sure you want to delete this data ?");
      if (r == true) {
        let context = this;

        Api(context, reportWorkingHour.delete(id)).onSuccess(function(response) {
            context.get();
            context.notify('Data Successfully Deleted', 'success')
        }).call();
      }
    },
    filesChange(e) {
        this.reportWorkingHourData.attachment = e.target.files[0];
    },
    changePage(page){
      this.pagination.page = page;
      this.get();
    },
    notify(message, type) {
      if (type == 'success') {
        toast.success(message, {
          autoClose: 2000,
        }); // ToastOptions
      }else{
        toast.error(message, {
          autoClose: 2000,
        }); // ToastOptions
      }
    },

  }
};
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 500px;
  margin-top: auto;
  margin-bottom: auto;
}
.modal__title {
  font-size: 1rem;
  font-weight: 600;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
.scroll { 
  overflow: auto; 
  height: 600px; 
}
.scroll thead th { 
  position: sticky; 
  top: 0; 
  z-index: 100; 
  background-color: #6495ED;
}
</style>
