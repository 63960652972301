<template>
  <div class="py-4 container-fluid">
    <!-- <a class="btn btn-sm btn-primary" style="margin-right: 10px;" :href="apiUrl+'export-excel/rfq?rfq_code='+search.rfq_code+'&vendor_code='+search.vendor_code+'&status='+search.status+''" target="_BLANK"><i class="fa fa-download fa-sm"></i> Export</a> -->
    <!-- <argon-button color="warning" size="sm" class="mb-3" variant="gradient" @click="modalImport()"><i class="fa fa-upload fa-sm"></i> Import</argon-button> -->
    <div class="row">
      <div class="col-lg-3 col-md-6 col-12">
        <card
          title="CP Available"
          :value="convertRp(totalTask)"
          iconClass="fa fa-tasks"
          iconBackground="bg-gradient-primary"
          directionReverse
        ></card>
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <card
          title="PO Draf"
          :value="convertRp(totalPoDraft)"
          iconClass="fa fa-book"
          iconBackground="bg-gradient-secondary"
          directionReverse
        ></card>
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <card
          title="Fixed PO"
          :value="convertRp(totalPoApproved)"
          iconClass="fa fa-thumbs-up"
          iconBackground="bg-gradient-success"
          directionReverse
        ></card>
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <card
          title="Total PO"
          :value="convertRp(totalPo)"
          iconClass="ni ni-satisfied"
          iconBackground="bg-gradient-warning"
          directionReverse
        ></card>
      </div>
    </div>
    <div class=" row">
      <div class="col-12">
          <div class="card"> 
            <div class="row">
              <div class="col-lg-4">
                <div class="card-header pb-0">
                  <h6>Purchase Order</h6>
                </div>
              </div>
              <div class="col-lg-4">
              </div>
              <div class="col-lg-4 float-right">
                <argon-button
                  style="margin-right: 10px; margin-left: 60px;"
                  class="mt-4"
                  variant="gradient"
                  color="secondary"
                  size="sm"
                  @click="filter()"
                ><i class="fa fa-filter fa-sm" aria-hidden="true"></i> Filter</argon-button>
                <argon-button
                  class="mt-4"
                  variant="gradient"
                  color="success"
                  size="sm"
                  @click="formSub.title ='==== TRANSACTION PURCHASE ORDER ADD DATA ====', createWithSubForm()"
                ><i class="fa fa-plus fa-sm" aria-hidden="true"></i> Add New</argon-button>
              </div>
            </div>
            
            <div class="card-body px-0 pt-0 pb-2 mt-4">
              <div class="table-responsive p-0 scroll">
                <table class="table table-hover table-striped align-items-center mb-0">
                  <thead>
                    <tr>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">No</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">PO NO</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">VENDOR NAME</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">NOTE</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">TOTAL ITEM</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">STATUS</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">ISSUE DATE</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">CREATED BY</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, i) in table.data" :key="i" @click="formSub.title ='==== TRANSACTION PURCHASE ORDER DETAIL ====', getDetailTransaction(row.po_no)" style="cursor: pointer;">
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ i + 1 }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="badge badge-sm bg-gradient-info">  {{ row.po_no }} </span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">  {{ row.company_name }} </span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">  {{ row.note }} </span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold"> {{ row.total_item_count }} </span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="badge badge-sm bg-gradient-info"> {{ row.status }} </span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">  {{ moment(row.issue_date).locale('id').format('LL') }} </span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">  {{ row.created_by }} </span>
                      </td>
                      <td class="align-middle text-center">
                        <!-- <i class="fa fa-check-circle-o" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="fixData(row.po_no)" title="Fixed Data"></i> -->
                        <i class="fa fa-trash fa-sm" aria-hidden="true" title="Hapus" style="cursor: pointer; margin-right: 20px;" @click="remove(row.id)"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer">
              <div class="float-right">
                <base-pagination :page-count="pagination.page_count" :value="pagination.page" @input="changePage" :total="pagination.page_count" class="justify-content-end"></base-pagination>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>

   <!-- ====================================================================  MODAL PRIVIEW  ============================================================================== -->
  <div class="container">
    <vue-final-modal v-model="formSub.show" classes="modal-container" content-class="modal-content-width" :z-index="10000" :focus-retain="false">
      <!-- header -->
      <div class="row">
        <div class="col-11 text-center">
          <span class="modal__title">{{formSub.title}} <template v-if="headerData.transaction_type">{{ headerData.transaction_type.toUpperCase() }} ====</template></span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formSub.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <div class="row">
          <div class="col-10 text-center">
          </div>
          <div class="col-2 float-right">
            <a class="btn btn-sm btn-warning" style="margin-left: 0px;" :href="apiUrl+'print-pdf/po-detail?po_no='+headerData.po_no+''" target="_BLANK"><i class="fa fa-print fa-sm"></i> Print</a>
          </div>
        </div>
        <table class="table table-sm table-bordered">
          <tbody>
            <tr>
              <td style="background-color: #F0F8FF; font-weight: bold; font-size: 11px;" class="align-middle" width="150"> PO NUMBER </td>
              <td colspan="4"> <input type="text" class="form-control" placeholder="PO NO" v-model="headerData.po_no" disabled> </td>
            </tr>
            <tr>
              <td style="background-color: #F0F8FF; font-weight: bold; font-size: 11px;" class="align-middle" width="150"> ISSUE DATE <span style="color: red;">*</span></td>
              <td> <input type="date" class="form-control" placeholder="Issue Date" v-model="headerData.issue_date"> </td>
              <td style="background-color: #F0F8FF; font-weight: bold; font-size: 11px;" class="align-middle" width="150"> STATUS <span style="color: red;">*</span></td>
              <td> <input type="text" class="form-control" placeholder="Status" v-model="headerData.status" disabled> </td>
            </tr>
            <tr>
              <td style="background-color: #F0F8FF; font-weight: bold; font-size: 11px;" class="align-middle" width="150"> Attachment </td>
              <td colspan="2"> <input type="file" accept="image/*" class="form-control" placeholder="Attachment" @change="filesChange" :disabled="formSub.title == '==== TRANSACTION PR DETAIL ===='"> </td>
              <td class="align-middle text-center"><a :href="storageUrl+'attachment_po/'+headerData.attachment" target="_BLANK" style="color: blue; size: 5px;">{{ headerData.attachmentView }} ...</a></td>
            </tr>
            <tr>
              <td style="background-color: #F0F8FF; font-weight: bold; font-size: 11px;" class="align-middle" width="150"> GENERAL NOTES </td>
              <td colspan="4">
               <!-- <input type="text" class="form-control" placeholder="General Note" v-model="headerData.note">  -->
               <textarea rows="4" class="form-control" v-model="headerData.note" placeholder="General Note"></textarea>
              </td>
            </tr>
          </tbody>
        </table>
          <!-- ===== FOR ITEM LIST ===== -->
          <div>
            <div class="">
              <hr style="border: 2px solid #008000;">
              <div class="row mb-2">
                <div class="col-4">
                  <div class="card-header pb-0">
                    <h6><i class="fas fa-list-ol" style="margin-right: 10px;"></i> Item List</h6>
                  </div>
                </div>
                <div class="col-5">
                </div>
                <div class="col-3 float-right">
                  <!-- <argon-button
                    style="margin-right: 10px; margin-left: 60px;"
                    class="mt-4"
                    variant="gradient"
                    color="warning"
                    size="sm"
                    :disabled="headerData.status == 'Completed'"
                    @click="fixData(headerData.pr_code)"
                  ><i class="fa fa-check-circle-o" aria-hidden="true"></i> Fixed PO</argon-button> -->
                  <argon-button
                    class="mt-4"
                    variant="gradient"
                    color="success"
                    size="sm"
                    :disabled="headerData.status == 'Completed'"
                    @click="createItem()"
                  ><i class="fa fa-plus fa-sm" aria-hidden="true"></i> Add New</argon-button>
                </div>
              </div>
              <div class="table-responsive p-0 scroll">
                <table class="table table-hover table-striped align-items-center mb-0">
                  <thead>
                    <tr>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #008000">No</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #008000">Vendor</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #008000">Part Name</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #008000">Specification</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #008000">Size</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #008000">Unit</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #008000">Qty</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #008000">Unit Price</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #008000">Amount</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #008000">Discount</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #008000">Price</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #008000">Del Condition</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #008000">Del Time</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #008000">Payment Terms</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #008000">Manufacture</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #008000"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, i) in dataItemPO.data" :key="i">
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ i + 1 }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.company_name }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.part_name }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.specification }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.size }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.unit }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.qty }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ convertRp(row.unit_price) }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ convertRp(row.amount) }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ convertRp(row.discount) }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.price }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.del_condition }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.del_time }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.payment_terms }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.manufacture }}</span>
                      </td>
                      <td class="align-middle text-center">
                        <!-- <i class="fas fa-edit fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="editItem(row.id)" title="Edit"></i> -->
                        <i class="fa fa-trash fa-sm" aria-hidden="true" title="Hapus" style="cursor: pointer; margin-right: 20px;" @click="removeItem(row.id)" v-if="headerData.status === 'Draf'"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </div>
          </div>
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
        </div>
        <div class="col-1">
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

  <!-- =======  MODAL ADD CP ======= -->
  <div class="container">
    <vue-final-modal v-model="formAddItem.show" classes="modal-container" content-class="modal-content-width" :z-index="10001">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formAddItem.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formAddItem.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Search By Comparasion No / RFQ NO </label>
        <input type="text" class="form-control mb-3" placeholder="Keyword : Comparasion No / RFQ NO" @keyup="searchCp()" v-model="searchComPrice">
        <div class="table-responsive p-0 scroll">
          <table class="table align-items-center mb-0">
            <thead>
              <tr style="background-color: #F0F8FF;">
                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">No</th>
                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">COMPARASION NO</th>
                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">RFQ NO</th>
                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">DUE DATE</th>
                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">NOTE</th>
                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">TOTAL ITEM</th>
                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">STATUS</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, i) in tableSearchaCp.data" :key="i" @click="getDetailCPItem(null, row.safety_code)" style="cursor: pointer;">
                <td class="align-middle text-center text-sm">
                  <span class="text-secondary text-xs font-weight-bold">{{ i + 1 }}</span>
                </td>
                <td class="align-middle text-center text-sm">
                  <span class="badge badge-sm bg-gradient-primary">{{ row.cp_no }}</span>
                </td>
                <td class="align-middle text-center text-sm">
                  <span class="badge badge-sm bg-gradient-info">{{ row.rfq_code }}</span>
                </td>
                <td class="align-middle text-center text-sm">
                  <span class="text-secondary text-xs font-weight-bold">{{ moment(row.due_date).locale('id').format('LL') }}</span>
                </td>
                <td class="align-middle text-center text-sm">
                  <span class="text-secondary text-xs font-weight-bold">{{ row.note }}</span>
                </td>
                <td class="align-middle text-center text-sm">
                  <span class="text-secondary text-xs font-weight-bold">{{ row.total_item_count }}</span>
                </td>
                <td class="align-middle text-center text-sm">
                  <span class="badge badge-sm bg-gradient-success">{{ row.status }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
        </div>
        <div class="col-1">
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
  </div>

  <!-- =======  MODAL ADD ITEM CP ======= -->
  <div class="container">
    <vue-final-modal v-model="formAddItemCP.show" classes="modal-container" content-class="modal-content-width" :z-index="10002">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formAddItemCP.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formAddItemCP.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <!-- <label for="example-text-input" class="form-control-label mt-3">Search By RFQ NO / MAT SRV GRP </label>
        <input type="text" class="form-control mb-3" placeholder="Keyword : RFQ NO / MAT SRV GRP" @keyup="searchCp()" v-model="itemData.vendor"> -->
        <div class="table-responsive p-0 scroll">
          <table class="table table-hover table-striped align-items-center mb-0">
            <thead>
              <tr>
                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #008000">No</th>
                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #008000">Action</th>
                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #008000">Vendor</th>
                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #008000">Part Name</th>
                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #008000">Specification</th>
                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #008000">Size</th>
                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #008000">Unit</th>
                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #008000">Qty</th>
                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #008000">Unit Price</th>
                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #008000">Amount</th>
                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #008000">Discount</th>
                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #008000">Price</th>
                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #008000">Del Condition</th>
                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #008000">Del Time</th>
                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #008000">Payment Terms</th>
                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #008000">Manufacture</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, i) in dataListVendor.data" :key="i">
                <td class="align-middle text-center text-sm">
                  <span class="text-secondary text-xs font-weight-bold">{{ i + 1 }}</span>
                </td>
                <td class="align-middle text-center text-sm">
                  <i class="fa fa-plus-square text-primary" title="Add Item PO" style="cursor: pointer;" @click="saveItemPo(row.id)" disabled></i>
                </td>
                <td class="align-middle text-center text-sm">
                  <span class="text-secondary text-xs font-weight-bold">{{ row.company_name }}</span>
                </td>
                <td class="align-middle text-center text-sm">
                  <span class="text-secondary text-xs font-weight-bold">{{ row.part_name }}</span>
                </td>
                <td class="align-middle text-center text-sm">
                  <span class="text-secondary text-xs font-weight-bold">{{ row.specification }}</span>
                </td>
                <td class="align-middle text-center text-sm">
                  <span class="text-secondary text-xs font-weight-bold">{{ row.size }}</span>
                </td>
                <td class="align-middle text-center text-sm">
                  <span class="text-secondary text-xs font-weight-bold">{{ row.unit }}</span>
                </td>
                <td class="align-middle text-center text-sm">
                  <span class="text-secondary text-xs font-weight-bold">{{ row.qty }}</span>
                </td>
                <td class="align-middle text-center text-sm">
                  <span class="text-secondary text-xs font-weight-bold">{{ convertRp(row.unit_price) }}</span>
                </td>
                <td class="align-middle text-center text-sm">
                  <span class="text-secondary text-xs font-weight-bold">{{ convertRp(row.amount) }}</span>
                </td>
                <td class="align-middle text-center text-sm">
                  <span class="text-secondary text-xs font-weight-bold">{{ convertRp(row.discount) }}</span>
                </td>
                <td class="align-middle text-center text-sm">
                  <span class="text-secondary text-xs font-weight-bold">{{ row.price }}</span>
                </td>
                <td class="align-middle text-center text-sm">
                  <span class="text-secondary text-xs font-weight-bold">{{ row.del_condition }}</span>
                </td>
                <td class="align-middle text-center text-sm">
                  <span class="text-secondary text-xs font-weight-bold">{{ moment(row.del_time).locale('id').format('LL') }}</span>
                </td>
                <td class="align-middle text-center text-sm">
                  <span class="text-secondary text-xs font-weight-bold">{{ row.payment_terms }}</span>
                </td>
                <td class="align-middle text-center text-sm">
                  <span class="text-secondary text-xs font-weight-bold">{{ row.manufacture }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
        </div>
        <div class="col-1">
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
  </div>

   <!-- ================================================  MODAL FILTER ============================================== -->
  <div class="container">
    <vue-final-modal v-model="formFilter.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formFilter.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formFilter.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">PO No </label>
        <input type="text" class="form-control" placeholder="PO No" v-model="search.po_no" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Vendor </label>
        <multiselect v-model="search.vendor_code" :options="tableVendor.data" :multiple="false" :close-on-select="true" :clear-on-select="true" :preserve-search="true" placeholder="Vendor Code" label="vendor_code" track-by="vendor_code" :preselect-first="true" @keyup="getVendor" :custom-label="nameWithLangVendor">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect> 
        <label for="example-text-input" class="form-control-label mt-3">Note </label>
        <input type="text" class="form-control" placeholder="Note" v-model="search.note" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Issue Date </label>
        <input type="date" class="form-control" placeholder="Issue Date" v-model="search.issue_date" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Created By </label>
        <input type="text" class="form-control" placeholder="Created By" v-model="search.created_by" v-on:keyup.enter="get()">
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="search = {}">Clear</argon-button>
        </div>
        <div class="col-1">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="get()" :disabled="onLoading">
            <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
            <span v-else>
                <span>Filter</span>
            </span>
          </argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

</template>

<script>
import Card from "@/examples/Cards/Card.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { VueFinalModal } from 'vue-final-modal'
import BasePagination from "@/components/BasePagination";
import Api from '@/helpers/api';
import config from '@/configs/config';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css';
var moment = require('moment');
import purchaseOrder from '@/services/procurement/purchaseOrder.service';
import comparasionPrice from '@/services/procurement/comparasionPrice.service';
import requestForQuotation from '@/services/procurement/requestForQuotation.service';
import purchasingRequisition from '@/services/procurement/purchasingRequisition.service';
import vendorList from '@/services/procurement/vendorList.service';

export default {
  name: "tables",
  components: {
    Card,
    ArgonButton,
    VueFinalModal,
    Multiselect,
    BasePagination
  },
  data() {
    return {
      pagination: {
        page_count: '',
        page: 1,
      },
      moment:moment,
      table: {
        data: []
      },
      totalTask: '',
      totalPoDraft: '',
      totalPoApproved: '',
      totalPo: '',
      tableVendor: {
        data: []
      },
      formSub: {
        add: true,
        title: "==== TRANSACTION PURCHASE ORDER DETAIL ====",
        show: false
      },
      formAddItem: {
        add: true,
        title: "==== TRANSACTION PURCHASE ORDER DETAIL ====",
        show: false
      },
      formAddItemCP: {
        add: true,
        title: "Add Item CP",
        show: false
      },
      formImport: {
        add: true,
        title: "Import Data Setting Approval",
        show: false
      },
      formFilter: {
        add: true,
        title: "Filter",
        show: false
      },
      onLoading: false,
      storageUrl : config.storageUrl,
      headerData: {},
      search: {
        po_no: '',
        vendor_code: '',
        note: '',
        issue_date: '',
        created_by: '',
        status: '',
      },
      apiUrl :config.apiUrl,
      tablePr: {
        data: []
      },
      tableSearchaCp: {
        data: []
      },
      tableItem: {
        data: []
      },

      // ----- Item Transaction PURCHASE ORDER -----
      dataTransaction: {},
      dataListItem: {
        data: []
      },
      dataListVendor: {
        data: []
      },
      dataItemPO: {
        data: []
      },
      itemData: {},
      searchComPrice: ''
    };
  },
  mounted(){
    this.get();
    this.tokenApi = 'Bearer '+localStorage.getItem('token');
  },
  methods: {
    nameWithLangVendor ({ vendor_code, company_name }) {
      return `${vendor_code} - ${company_name}`
    },
    // nameWithLangPr ({ pr_code }) {
    //   return `${pr_code}`
    // },
    get(param){
      let context = this; 
      context.onLoading = true;

      Api(context, purchaseOrder.index({po_no: this.search.po_no, vendor_code: this.search.vendor_code['vendor_code'], note: this.search.note, issue_date: this.search.issue_date, created_by: this.search.created_by, status: this.search.status, page: context.pagination.page})).onSuccess(function(response) {    
          context.table.data            = response.data.data.data.data;
          context.pagination.page_count = response.data.data.data.last_page

          context.totalTask       = response.data.data.task;
          context.totalPoDraft    = response.data.data.poDraft;
          context.totalPoApproved = response.data.data.poApproved;
          context.totalPo         = response.data.data.totalPo;

          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.table.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.formFilter.show = false;
         context.onLoading       = false;
      })
      .call()
    },
    getPrNo(param){
      if (param.target.value.length >= 3) {
        let context = this;        
        Api(context, purchasingRequisition.getPrNo({search: param.target.value})).onSuccess(function(response) {    
            context.tablePr.data = response.data.data;
        }).onError(function(error) {                    
            if (error.response.status == 404) {
                context.tablePr.data = [];
            }
        }).onFinish(function() { 
        })
        .call()
      }
    },
    getVendor(param){
      if (param.target.value.length >= 3) {
        let context = this;        
        Api(context, vendorList.getVendor({search: param.target.value})).onSuccess(function(response) {    
            context.tableVendor.data = response.data.data;
        }).onError(function(error) {                    
            if (error.response.status == 404) {
                context.tableVendor.data = [];
            }
        }).onFinish(function() { 
        })
        .call()
      }
    },
    searchCp(){
      let context = this;              
      Api(context, comparasionPrice.searchCp({search: context.searchComPrice})).onSuccess(function(response) {   
          context.tableSearchaCp.data = response.data.data;
      })
      .call()
    },
    getDetailCPItem(cpNo, safetyCode){
      let context = this; 
      context.headerData.cp_no = cpNo;

      Api(context, requestForQuotation.transactionDetail({safety_code: safetyCode, page: ''})).onSuccess(function(response) {
          context.dataListVendor.data = response.data.data.dataListVendor;
          context.createItemCP();
          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
            context.dataListVendor.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
      })
      .call()
    },
    getDetailTransaction(PoNo){
      let context = this;

      Api(context, purchaseOrder.transactionDetail({po_no: PoNo})).onSuccess(function(response) {
          context.headerData.po_no      = response.data.data.data.po_no;
          context.headerData.issue_date = response.data.data.data.issue_date;
          context.headerData.status     = response.data.data.data.status;
          context.headerData.note       = response.data.data.data.note;
          context.headerData.attachment = response.data.data.data.attachment;
          if (response.data.data.data.attachment) {
            context.headerData.attachmentView = response.data.data.data.attachment.substring(0,30);
          }

          context.dataItemPO.data = response.data.data.dataItemPO;
          context.createWithSubForm();
          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
            context.dataItemPO.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
      })
      .call()
    },
    filter() {
      this.formFilter.add   = true;
      this.formFilter.show  = true;
      this.formFilter.title = "Filter";
      this.onLoading        = false;
      this.pagination.page  = 1 ;
    },
    createWithSubForm() {
      this.formSub.add       = true;
      this.formSub.show      = true;
      if (this.formSub.title != "==== TRANSACTION PURCHASE ORDER DETAIL ====") {
        this.headerData        = {};
        this.headerData.status = 'Draf'
        this.generatePONo()
        this.defaultDate()
        this.dataItemPO.data = {}
      }
    },
    createItem() {
      this.formAddItem.add   = true;
      this.formAddItem.show  = true;
      this.formAddItem.title = "Priview Comparasion Price List";
      this.itemData          = {};
      this.searchCp()
    },
    createItemCP() {
      this.formAddItemCP.add   = true;
      this.formAddItemCP.show  = true;
      this.formAddItemCP.title = "Add Item cp";
      // this.itemData                = {};
    },
    editItem(id) {
      let context = this;               
      Api(context, comparasionPrice.showItem(id)).onSuccess(function(response) {
          context.itemData          = response.data.data;
          context.itemData.id_item  = response.data.data.id;
          context.formAddItem.show  = true;
          context.formAddItem.title = 'Edit Data';   
      })
      .call()        
    },
    filesChange(e) {
      this.headerData.attachment = e.target.files[0];
    },
    generatePONo(){
      let context = this;              
      Api(context, purchaseOrder.generatePONo()).onSuccess(function(response) {   
          context.headerData.po_no = response.data.data;
          console.log(response.data.data)
      })
      .call()
    },
    saveItemPo(id){
      let api      = null;
      let context  = this;
      let formData = new FormData();

      // INSERT HEADER
      if (context.headerData.po_no != undefined && context.headerData.issue_date != undefined && context.headerData.status != undefined) {
        formData.append('po_no', context.headerData.po_no);
        formData.append('issue_date', context.headerData.issue_date);
        formData.append('status', context.headerData.status);
        formData.append('note', (context.headerData.note == undefined) ? '-' : context.headerData.note);
        formData.append('attachment', (context.headerData.attachment == undefined) ? '' : context.headerData.attachment);
        // INSERT ITEM
        formData.append('rfq_vendor_id', id);
      }else{
        return alert('The Red Star Field is Mandatory')
      }

      if (context.formSub.title == '==== TRANSACTION PURCHASE ORDER ADD DATA ') {
          api = Api(context, purchaseOrder.create(formData));
      } else {
          api = Api(context, purchaseOrder.create(formData));
      }
      api.onSuccess(function(response) {
        context.formSub.title = '==== TRANSACTION PURCHASE ORDER DETAIL ====';
        context.getDetailTransaction(context.headerData.po_no);
        context.get();
        context.notify((context.formSub.title === '==== TRANSACTION PURCHASE ORDER ADD DATA ') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      }).onError(function(error) { 
        context.notify(error.response.data.message, 'error')                   
      }).onFinish(function() { 
        // context.searchPrVendorRfq() 
      })
      .call();
    },
    remove(id) {
      var r = confirm("Are you sure you want to delete this data ?");
      if (r == true) {
        // if ((transaction_type == 'Reduction') || (total_item == 0)) {
          let context = this;
          Api(context, purchaseOrder.delete(id)).onSuccess(function(response) {
            context.get();
            context.notify('Data Successfully Deleted', 'success')
          }).call();
        // }else{
          // this.notify('Failed to delete. Please clear items first', 'error')
        // }
      }
    },
    removeItem(id) {
      var r = confirm("Are you sure you want to delete this data ?");
      if (r == true) {
        let context  = this;

        Api(context, purchaseOrder.deleteItem(id)).onSuccess(function(response) {
          context.getDetailTransaction(context.headerData.po_no);
          context.get();
          context.notify('Data Successfully Deleted', 'success')
        }).call();
      }
    },
    fixData(param){
      let api      = null;
      let context  = this;

      api = Api(context, comparasionPrice.fixData({pr_code: param}));
      // eslint-disable-next-line no-unused-vars
      api.onSuccess(function(response) {
        context.get();
        context.getDetailTransaction(context.headerData.pr_code)
        context.notify((context.form === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      // eslint-disable-next-line no-unused-vars
      }).onError(function(error) { 
        context.notify((context.form != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
      })
      .call();
    },
    defaultDate(){
      var date  = new Date();
      var day   = ("0" + date.getDate()).slice(-2);
      var month = ("0" + (date.getMonth() + 1)).slice(-2);
      var today = date.getFullYear() + "-" + (month) + "-" + (day);

      this.headerData.issue_date = today
    },
    changePage(page){
      this.pagination.page = page;
      this.get();
    },
    notify(message, type) {
      if (type == 'success') {
        toast.success(message, {
          autoClose: 2000,
        }); // ToastOptions
      }else{
        toast.error(message, {
          autoClose: 2000,
        }); // ToastOptions
      }
    },
    convertRp(bilangan) {
      if (bilangan) {
        var number_string = bilangan.toString(),
            sisa    = number_string.length % 3,
            rupiah  = number_string.substr(0, sisa),
            ribuan  = number_string.substr(sisa).match(/\d{3}/g);

        if(ribuan){
          var separator = sisa ? '.' : '';
          rupiah += separator + ribuan.join('.');
          return rupiah
        }else{
          return bilangan
        }
      }
    },

  }
};
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 500px;
  margin-top: auto;
  margin-bottom: auto;
}
/*MODAL WIDTH KUSUS LEBAR BESAR*/
::v-deep .modal-content-width {
  /*display: flex;*/
  /*flex-direction: column;*/
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 1090px;
  margin-top: auto;
  margin-bottom: auto;
}
.modal__title {
  font-size: 1rem;
  font-weight: 600;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
.scroll { 
  overflow: auto; 
  height: 600px; 
}
.scroll thead th { 
  position: sticky; 
  top: 0; 
  z-index: 100; 
  background-color: #6495ED;
}
</style>
