<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          isBlur="blur  border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
          v-bind:darkMode="true"
          isBtn="bg-gradient-success"
        />
      </div>
    </div>
  </div>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <!-- <div class="row"> -->
            <!-- <div class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0">
              <div class="card card-plain">
                <div class="pb-0 card-header text-start">
                  <h4 class="font-weight-bolder">Sign In</h4>
                  <p class="mb-0">Enter your email and password to sign in</p>
                </div>
                <div class="card-body">
                  <form role="form">
                    <div class="mb-3">
                      <argon-input type="email" placeholder="Email" name="email" size="lg" />
                    </div>
                    <div class="mb-3">
                      <argon-input type="password" placeholder="Password" name="password" size="lg" />
                    </div>
                    <argon-switch id="rememberMe">Remember me</argon-switch>

                    <div class="text-center">
                      <argon-button
                        class="mt-4"
                        variant="gradient"
                        color="success"
                        fullWidth
                        size="lg"
                      >Sign in</argon-button>
                    </div>
                  </form>
                </div>
                <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-4 text-sm">
                    Don't have an account?
                    <a
                      href="javascript:;"
                      class="text-success text-gradient font-weight-bold"
                    >Sign up</a>
                  </p>
                </div>
              </div>
            </div> -->
            <div
              class="top-0 my-auto text-center col-12 d-lg-flex h-100 pe-0 position-absolute end-0 justify-content-center flex-column"
            >
              <div class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden bg">
                <span class="mask bg-gradient-success opacity-6"></span>
                <h3 class="mt-5 text-white font-weight-bolder position-relative" style="text-shadow: 2px 2px 4px black;">Please Select Privileges</h3>
                <div class="row scroll">
                  <div v-for="(row, i) in table.data" :key="i" class="col-lg-2 mt-3">
                      <div class="card">
                        <div class="card-body text-start">
                          <div class="font-weight-bold text-center">{{ row.name }}</div><hr>
                          <div style="height: 70px; font-size: 13px;">{{ row.description }}</div>
                        </div>
                        <div class="card-footer bg-dark" style="height: 60px;">
                          <argon-button
                            @click="setPrivilage(row.id, row.redirect_to)"
                            style="margin-top: -20px;"
                            variant="gradient"
                            color="success"
                            fullWidth
                            size="sm"
                          >Select <i class="fa fa-arrow-right mr-5" aria-hidden="true"></i></argon-button>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <!-- </div> -->
      </div>
    </section>
  </main>
</template>

<script>
// import Navbar from "@/examples/PageLayout/Navbar.vue";
// import ArgonInput from "@/components/ArgonInput.vue";
// import ArgonSwitch from "@/components/ArgonSwitch.vue";
import ArgonButton from "@/components/ArgonButton.vue";
const body = document.getElementsByTagName("body")[0];
import config from '@/configs/config';
import axios from 'axios';
import Api from '@/helpers/api';
import akun from '@/services/akun.service';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export default {
  name: "signin",
  components: {
    // Navbar,
    // ArgonInput,
    // ArgonSwitch,
    ArgonButton,
  },
  data() {
    return {
      username: '',
      password: '',
      onLoading: false,
      table: {
        data: []
      },
      roleIdKaryawan: '',
    };
  },
  mounted(){
    this.getPrivilage();
    this.getRole();
    this.tokenApi = 'Bearer '+localStorage.getItem('token');
  },
  methods: {
    getPrivilage(param){
      let context = this;               
      Api(context, akun.getPrivilage()).onSuccess(function(response) {    
          context.table.data = response.data.data;
          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.table.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         // context.formFilter.show  = false;
      })
      .call()
    },
    getRole(){
      let context = this;     
      context.onLoading = true;          
      Api(context, akun.indexProfile()).onSuccess(function(response) {
          context.roleIdKaryawan = response.data.data[0].employee_id;
          console.log(response.data.data[0].employee_id)
      }).onError(function(error) {  
      })
      .call() 
    },
    setPrivilage(param, redirect_to){
      let context = this;               
      Api(context, akun.setPrivilage({id_privilage : param})).onSuccess(function(response) { 
        if (redirect_to != '/detail-profile/') {
          context.$router.push(redirect_to)
        }else{
          console.log(redirect_to+context.roleIdKaryawan)
          context.$router.push(redirect_to+context.roleIdKaryawan)
        }
        context.notify('Set Privilage Successfully', 'success')
      }).onError(function(error) {                    
          context.notify('Set Privilage Failed', 'error')
      }).onFinish(function() { 
         // context.formFilter.show  = false;
      })
      .call()
    },
    notify(message, type) {
      if (type == 'success') {
        toast.success(message, {
          autoClose: 2000,
        }); // ToastOptions
      }else{
        toast.error(message, {
          autoClose: 2000,
        }); // ToastOptions
      }
    },
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
  },
};
</script>
<style scoped lang="scss">
.bg {
  background-image: url('../assets/img/main-privilage.jpg');
  background-size: cover;
}
.scroll { 
  overflow: auto; 
  height: 100; 
}
</style>
