import Service from '@/helpers/service'

export default {

    getDailyControlBoard(params) {
        return Service().get('report-working-hour/get-daily-control-board', {params});
    },
    getSummaryProjectActivity(params) {
        return Service().get('report-working-hour/get-wh-summary-project-activity', {params});
    },
    getSummaryProjectJobTitle(params) {
        return Service().get('report-working-hour/get-wh-summary-project-job-title', {params});
    },
}