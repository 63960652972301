<template>
  <!-- <div class="py-4 container-fluid"> -->
    <!-- <a class="btn btn-sm btn-primary" style="margin-right: 10px;" :href="apiUrl+'export-excel/karyawan?id_karyawan='+search.id_karyawan+'&nama='+search.nama+'&nik='+search.nik+'&jabatan='+search.jabatan+'&unit='+search.unit+'&status='+search.status+''" target="_BLANK"><i class="fa fa-download fa-sm"></i> Export</a> -->
    <!-- <argon-button color="info" size="sm" class="mb-3" variant="gradient" style="margin-right: 10px;"><i class="fa fa-download fa-sm"></i> Export</argon-button> -->
    <!-- <argon-button color="warning" size="sm" class="mb-3" variant="gradient" @click="modalImport()"><i class="fa fa-upload fa-sm"></i> Import</argon-button> -->
    <div class="row container-fluid">
      <div class="col-12">
          <div class="card"> 
            
            <div class="card-body px-0 pt-0 pb-2 mt-4">
              <!-- ============ HEADER =========== -->
              <div class="mt-5 mb-3">
                <div class="row">
                  <div class="col-7" style="margin-left: -120px; margin-top: 10px; margin-top: -40px;">
                    <img src="@/assets/img/logo-dihi.png" style="width: 100px; margin-left: 180px; margin-top: -20px;"/>
                    <h5 style="margin-left: 180px;" class="mt-2">PT. DAEKYUNG INDAH HEAVY INDUSTRY</h5>
                  </div>
                  <div class="col-1">
                    <div style="margin-left: -30px; margin-top: -50px;" class="vl"></div>
                  </div>
                  <div class="col-3">
                    <h5 style="margin-left: -90px; margin-top: -40px; font-weight: bold;">Request For Quotation (RFQ) /</h5>
                    <h5 style="margin-left: -90px; margin-top: -10px; font-weight: bold;">Surat Permintaan Penawaran (SPP)</h5>
                  </div>
                  <div class="col-1">
                    <a class="btn btn-sm btn-warning" style="margin-top: -50px;" :href="apiUrl+'print-pdf/rfq-detail?rfq_code='+headerData.rfq_no+'&company_name='+headerData.company_name+''" target="_BLANK"><i class="fa fa-print fa-sm"></i> Print</a>
                  </div>
                </div>
              </div><hr style="border: 2px solid #008000;">

              <!-- ============ BODY =========== -->
              <div class="container-fluid" style="margin-top: -20px;">
                <div class="mt-3">
                  <div class="card-body px-0 pt-0 pb-2 mt-4">
                <div class="row">
                  <div class="col-6">
                    <p style="margin-top: 10px; font-size:16px; font-weight: bold; margin-right: -70px;">RFQ NO
                      <span style="margin-left: 10px; margin-right: 10px;">:</span>  {{ headerData.rfq_no }}  
                    </p>
                    <p style="margin-left: 30px;">Kepada / To &nbsp;&nbsp;:&nbsp;&nbsp; {{ headerData.company_name }} </p>
                    <p>Harap tawarkan harga kepada kami sesuai spesikasi di bawah ini sebelum tanggal {{ moment(headerData.due_date).locale('id').format('LL') }} </p>
                  </div>
                  <div class="col-6">
                    <p style="margin-top: 90px;">| Please offer us a price according to the specifications below before {{ moment(headerData.due_date).locale('us').format('LL') }} </p>
                  </div>
                  <!-- ITEM LIST -->
                  <div style="margin-top: -30px;">
                    <div>
                      <hr>
                        <span style="font-size: 20px; font-weight: bold;">Item List</span>
                      <div class="scroll">
                        <table class="table table-hover table-striped align-items-center mb-0">
                          <thead>
                            <tr style="background: #0000CD;">
                              <th class="text-center text-uppercase text-white font-weight-bolder align-middle" style="border:1px solid black; background: #5F9EA0;">NO</th>
                              <th class="text-center text-uppercase text-white font-weight-bolder align-middle" style="border:1px solid black; background: #5F9EA0;">PART NAME</th>
                              <th class="text-center text-uppercase text-white font-weight-bolder align-middle" style="border:1px solid black; background: #5F9EA0;">SPECIFICATION</th>
                              <th class="text-center text-uppercase text-white font-weight-bolder align-middle" style="border:1px solid black; background: #5F9EA0;">SIZE</th>
                              <th class="text-center text-uppercase text-white font-weight-bolder align-middle" style="border:1px solid black; background: #5F9EA0;">UNIT</th>
                              <th class="text-center text-uppercase text-white font-weight-bolder align-middle" style="border:1px solid black; background: #5F9EA0;">QTY</th>
                              <th class="text-center text-uppercase text-white font-weight-bolder align-middle" style="border:1px solid black; background: #5F9EA0;">UNIT PRICE</th>
                              <th class="text-center text-uppercase text-white font-weight-bolder align-middle" style="border:1px solid black; background: #5F9EA0;">WEIGHT</th>
                              <th class="text-center text-uppercase text-white font-weight-bolder align-middle" style="border:1px solid black; background: #5F9EA0;">WEIGHT PRICE</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr style="font-size: 17;" v-for="(row, i) in dataListItem.data" :key="i">
                              <td class="align-middle text-center text-sm" style="font-size: 13px; border:1px solid black; text-align: center;">
                                 {{ i + 1 }}  
                              </td>
                              <td class="align-middle text-center text-sm" style="font-size: 13px; border:1px solid black; text-align: center;">
                                {{ row.part_name }} 
                              </td>
                              <td class="align-middle text-center text-sm" style="font-size: 13px; border:1px solid black; text-align: center;">
                                {{ row.specification }}
                              </td>
                              <td class="align-middle text-center text-sm" style="font-size: 13px; border:1px solid black; text-align: center;">
                                {{ row.size }}
                              </td>
                              <td class="align-middle text-center text-sm" style="font-size: 13px; border:1px solid black; text-align: center;">
                                {{ row.unit }}
                              </td>
                              <td class="align-middle text-center text-sm" style="font-size: 13px; border:1px solid black; text-align: center;">
                                {{ row.qty }}
                              </td>
                              <td class="align-middle text-center text-sm" style="font-size: 13px; border:1px solid black; text-align: center;">
                                <input type="text" class="form-control" placeholder="Unit Price" @change="updateItem" :value="convertRp(row.unit_price)" :id="row.pr_item_code" :name="row.rfq_code">
                              </td>
                              <td class="align-middle text-center text-sm" style="font-size: 13px; border:1px solid black; text-align: center;">
                                <input type="text" class="form-control" placeholder="Weight" @change="updateItem" :value="convertRp(row.weight)" :id="row.pr_item_code" :name="row.rfq_code">
                              </td>
                              <td class="align-middle text-center text-sm" style="font-size: 13px; border:1px solid black; text-align: center;">
                                <input type="text" class="form-control" placeholder="Weight Price" @change="updateItem" :value="convertRp(row.weight_price)" :id="row.pr_item_code" :name="row.rfq_code">
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        </div>
                        <div class="row" style="margin-top: 30px;">
                          <div class="col-lg-7">
                          </div>
                          <div class="col-lg-5" style="white-space: nowrap;" v-if="dataListItem.data.length != 0">
                             Other Cost : 
                             <input type="text" class="form-control form-control-sm mb-3" placeholder="Other Cost" @change="updateItem" :value="convertRp(dataListItem.data[0].other_cost)" :id="dataListItem.data[0].pr_item_code" :name="dataListItem.data[0].rfq_code">
                             Other Cost Description: 
                             <input type="text" class="form-control form-control-sm mb-3" placeholder="Other Cost Description" @change="updateItem" :value="dataListItem.data[0].other_cost_description" :id="dataListItem.data[0].pr_item_code" :name="dataListItem.data[0].rfq_code">
                             Discount : 
                             <input type="text" class="form-control form-control-sm mb-3" placeholder="Discount" @change="updateItem" :value="convertRp(dataListItem.data[0].discount)" :id="dataListItem.data[0].pr_item_code" :name="dataListItem.data[0].rfq_code">
                             Currency
                             <select class="form-select mb-3" aria-label="Default select example" placeholder="Currency" @change="updateItem" :value="dataListItem.data[0].currency" :id="dataListItem.data[0].pr_item_code" :name="dataListItem.data[0].rfq_code">
                                <option value="">Choose Currency</option>
                                <option value="IDR">IDR - Indonesian Rupiah</option>
                                <option value="USD">USD - US Dollar</option>
                                <option value="KRW">KRW - South Korean Won</option>
                                <option value="SGD">SGD - Singapore Dollar</option>
                                <option value="JPY">JPY - Japanese Yen</option>
                                <option value="EUR">EUR - Euro</option>
                                <option value="GBP">GBP - British Pound</option>
                             </select>
                             PPN : 
                             <select class="form-select mb-3" aria-label="Default select example" placeholder="Price" @change="updateItem" :value="dataListItem.data[0].price" :id="dataListItem.data[0].pr_item_code" :name="dataListItem.data[0].rfq_code">
                                <option value="">Choose PPN</option>
                                <option value="Include PPN 11 %">Include PPN 11 %</option>
                                <option value="Exclude PPN">Exclude PPN</option>
                             </select>
                             Del Condition : 
                             <input type="text" class="form-control form-control-sm mb-3" placeholder="Delivery Condition" @change="updateItem" :value="dataListItem.data[0].del_condition" :id="dataListItem.data[0].pr_item_code" :name="dataListItem.data[0].rfq_code">
                             Del Time : 
                             <input type="date" class="form-control form-control-sm mb-3" placeholder="Delivery Time" @change="updateItem" :value="dataListItem.data[0].del_time" :id="dataListItem.data[0].pr_item_code" :name="dataListItem.data[0].rfq_code">
                             Payment Terms : 
                             <input type="text" class="form-control form-control-sm mb-3" placeholder="Payment Terms" @change="updateItem" :value="dataListItem.data[0].payment_terms" :id="dataListItem.data[0].pr_item_code" :name="dataListItem.data[0].rfq_code">
                             Manufacture : 
                             <input type="text" class="form-control form-control-sm mb-3" placeholder="Manufacture" @change="updateItem" :value="dataListItem.data[0].manufacture" :id="dataListItem.data[0].pr_item_code" :name="dataListItem.data[0].rfq_code">
                          </div>
                      </div>
                    </div>

                    <table class="mt-5 table table-sm">
                      <!-- <thead> -->
                        <tr>
                          <th style="font-size: 12px; border:0px solid black; text-align: left;">Pembeli / Purchaser <br>PT. Daekyung Indah Heavy Industry <br>Disetujui oleh</th>
                          <th style="font-size: 12px; border:0px solid black; text-align: center;"></th>
                          <th style="font-size: 12px; border:0px solid black; text-align: center;"></th>
                          <th style="font-size: 12px; border:0px solid black; text-align: center;"></th>
                          <th style="font-size: 12px; border:0px solid black; text-align: center;"></th>
                          <th style="font-size: 12px; border:0px solid black; text-align: center;"></th>
                        </tr>
                      <!-- </thead> -->
                      <!-- <tbody> -->
                        <tr>
                          <td style="font-size: 12px; border:0px solid black; text-align: center; padding: 40px;"></td>
                          <td style="font-size: 12px; border:0px solid black; text-align: center; padding: 40px;"></td>
                          <td style="font-size: 12px; border:0px solid black; text-align: center; padding: 40px;"></td>
                          <td style="font-size: 12px; border:0px solid black; text-align: center; padding: 40px;"></td>
                          <td style="font-size: 12px; border:0px solid black; text-align: center; padding: 40px;"></td>
                          <td style="font-size: 12px; border:0px solid black; text-align: center; padding: 40px;"></td>
                        </tr>
                      <!-- </tbody> -->
                      <!-- <tbody> -->
                        <tr>
                          <td style="font-size: 12px; border:0px solid black; text-align: left;"><span style="margin-left: 30px;">Agung Suprapto</span></td>
                          <td style="font-size: 12px; border:0px solid black; text-align: center;"></td>
                          <td style="font-size: 12px; border:0px solid black; text-align: center;"></td>
                          <td style="font-size: 12px; border:0px solid black; text-align: center;"></td>
                          <td style="font-size: 12px; border:0px solid black; text-align: center;"></td>
                          <td style="font-size: 12px; border:0px solid black; text-align: center;"></td>
                        </tr>
                      <!-- </tbody> -->
                    </table>
                  </div>

                </div>
              </div>
              </div>
            </div>
            <div class="card-footer">
            </div>
          </div>
      </div>
    </div>
  </div>

  <!-- ================================================  MODAL CEK NPWP ============================================== -->
  <div class="container">
    <vue-final-modal v-model="formCekNpwp.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formCekNpwp.title}}</span>
        </div>
        <div class="col-1 float-right">
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <!-- <input type="text" class="form-control" placeholder="Enter the Company Name" v-model="npwp" v-on:keyup.enter="cekNpwp()"> -->
        <multiselect v-model="npwp" :options="tableVendor.data" :multiple="false" :close-on-select="true" :clear-on-select="true" :preserve-search="true" placeholder="Enter the Company Name" label="vendor_code" track-by="vendor_code" :preselect-first="true" @keyup="getVendor" :custom-label="nameWithLangVendor" @select="cekNpwp()">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect> 
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-4"> 
        </div>
        <div class="col-1">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="cekNpwp()" :disabled="onLoading">
            <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
            <span v-else>
                <span style="white-space: nowrap">Cek Company Name</span>
            </span>
          </argon-button>
        </div>
        <p style="color: blue; cursor: pointer; font-size: 12px; margin-top: 50px;" @click="createVendor()"><span style="color: red;">*</span> Register As a Partner</p>
      </div>
      <!-- end footer -->
    </vue-final-modal>
  </div>

  <!-- =======  MODAL ADD DATA ======= -->
  <div class="container">
    <vue-final-modal v-model="form.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{form.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="form.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Company Name <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Company Name" v-model="vendorData.company_name">
        <label for="example-text-input" class="form-control-label mt-3">NPWP <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="NPWP" v-model="vendorData.npwp">
        <label for="example-text-input" class="form-control-label mt-3">Contact Person <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Contact Person" v-model="vendorData.contact_person">
        <label for="example-text-input" class="form-control-label mt-3">Address <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Address" v-model="vendorData.address">
        <label for="example-text-input" class="form-control-label mt-3">Email <span style="color: red;">*</span></label>
        <input type="email" class="form-control" placeholder="Email" v-model="vendorData.email">
        <label for="example-text-input" class="form-control-label mt-3">No HP </label>
        <input type="text" class="form-control" placeholder="No HP" v-model="vendorData.no_hp">
        <label for="example-text-input" class="form-control-label mt-3">Phone </label>
        <input type="text" class="form-control" placeholder="Phone" v-model="vendorData.phone">
        <label for="example-text-input" class="form-control-label mt-3">Fax </label>
        <input type="text" class="form-control" placeholder="Fax" v-model="vendorData.fax">
        <label for="example-text-input" class="form-control-label mt-3">Vendor Category <span style="color: red;">*</span></label>
        <select class="form-select" aria-label="Default select example" v-model="vendorData.vendor_category">
          <option value="Agent">Agent</option>
          <option value="Consumable">Consumable</option>
          <option value="Equipment">Equipment</option>
          <option value="Manufacture">Manufacture</option>
          <option value="Mill Maker">Mill Maker</option>
          <option value="Services">Services</option>
          <option value="Supplier">Supplier</option>
          <option value="Tools">Tools</option>
          <option value="Trading Company">Trading Company</option>
          <option value="Transport">Transport</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Item Group <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Item Group" v-model="vendorData.item_group">
        <label for="example-text-input" class="form-control-label mt-3">Note </label>
        <textarea class="form-control" v-model="vendorData.note" placeholder="Note"></textarea>
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-4"> 
        </div>
        <div class="col-3" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="form.show = false">Close</argon-button>
        </div>
        <div class="col-2">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="save()" :disabled="onLoading">
            <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
            <span v-else>
                <span>Save</span>
            </span>
          </argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
  </div>

</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
import Api from '@/helpers/api';
import config from '@/configs/config';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
var moment = require('moment');
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css';
import requestForQuotation from '@/services/procurement/requestForQuotation.service';
import vendorList from '@/services/procurement/vendorList.service';

export default {
  name: "tables",
  components: {
    ArgonButton,
    Multiselect,
  },
  data() {
    return {
      moment:moment,
      dataListItem: {
        data: []
      },
      onLoading: false,
      storageUrl : config.storageUrl,
      headerData: {},
      itemData: {},
      tableVendor: {
        data: []
      },
      search: {
        code: '',
        name: '',
        address: '',
      },
      formCekNpwp: {
        add: true,
        title: "Enter Company Name",
        show: false
      },
      form: {
        add: true,
        title: "Register As a Partner",
        show: false
      },
      vendorData: {},
      apiUrl :config.apiUrl,
      npwp: '',
      vendor_code: '',

    };
  },
  mounted(){
    // this.get();
    this.tokenApi = 'Bearer '+localStorage.getItem('token');
    this.formCekNpwp.show = true;
  },
  methods: {
    nameWithLangVendor ({ vendor_code, company_name }) {
      return `${vendor_code} - ${company_name}`
    },
    get(param){
      let context = this;               
      Api(context, requestForQuotation.transactionDetail({safety_code: context.$route.params.code, vendor_code: context.vendor_code, page: 'form-rfq'})).onSuccess(function(response) {    
          context.headerData.id       = response.data.data.data.id;
          context.headerData.rfq_no   = response.data.data.data.rfq_code;
          context.headerData.due_date = response.data.data.data.due_date;
          context.headerData.note     = response.data.data.data.note;

          context.dataListItem.data = response.data.data.dataListItem;
          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.headerData = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
      })
      .call()
    },
    getVendor(param){
      if (param.target.value.length >= 3) {
        let context = this;        
        Api(context, vendorList.getVendor({search: param.target.value})).onSuccess(function(response) {    
            context.tableVendor.data = response.data.data;
        }).onError(function(error) {                    
            if (error.response.status == 404) {
                context.tableVendor.data = [];
            }
        }).onFinish(function() { 
        })
        .call()
      }
    },
    cekNpwp(){
      let context       = this; 
      context.onLoading = true;     

      Api(context, requestForQuotation.cekNpwp({vendor_code: context.npwp['vendor_code']})).onSuccess(function(response) {    
          context.headerData.company_name = response.data.data.company_name
          context.vendor_code             = response.data.data.vendor_code
          context.formCekNpwp.show        = false;
          context.get();
          context.notify('Company Name found', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.headerData.company_name = '';
          }
          context.notify('Company Name not found', 'error')
      }).onFinish(function() { 
        context.onLoading = false;
      })
      .call()
    },
    updateItem(param){
      let api      = null;
      let context  = this;
      let formData = new FormData();
      console.log(param.target.placeholder)

      formData.append('vendor_code', context.vendor_code);
      formData.append('rfq_code', param.target.name);
      formData.append('pr_item_code', param.target.id);

      if (param.target.placeholder == 'Unit Price') {
        formData.append('unit_price', param.target.value);
      }
      if (param.target.placeholder == 'Weight') {
        formData.append('weight', param.target.value);
      }
      if (param.target.placeholder == 'Weight Price') {
        formData.append('weight_price', param.target.value);
      }
      if (param.target.placeholder == 'Other Cost') {
        formData.append('other_cost', param.target.value);
      }
      if (param.target.placeholder == 'Other Cost Description') {
        formData.append('other_cost_description', param.target.value);
      }
      if (param.target.placeholder == 'Discount') {
        formData.append('discount', param.target.value);
      }
      if (param.target.value == 'IDR' || param.target.value == 'USD' || param.target.value == 'KRW' || param.target.value == 'SGD' || param.target.value == 'JPY' || param.target.value == 'EUR' || param.target.value == 'GBP') {
        formData.append('currency', (param.target.value == undefined) ? '' : param.target.value);
      }
      if (param.target.value == 'Include PPN 11 %' || param.target.value == 'Exclude PPN') {
        formData.append('price', (param.target.value == undefined) ? '' : param.target.value);
      }
      if (param.target.placeholder == 'Delivery Condition') {
        formData.append('del_condition', (param.target.value == undefined) ? '' : param.target.value);
      }
      if (param.target.placeholder == 'Delivery Time') {
        formData.append('del_time', (param.target.value == undefined) ? '' : param.target.value);
      }
      if (param.target.placeholder == 'Payment Terms') {
        formData.append('payment_terms', (param.target.value == undefined) ? '' : param.target.value);
      }
      if (param.target.placeholder == 'Manufacture') {
        formData.append('manufacture', (param.target.value == undefined) ? '' : param.target.value);
      }

      api = Api(context, requestForQuotation.updateItem(param.target.id, formData));
  
      api.onSuccess(function(response) {
        context.get();
        context.notify((context.form === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      }).onError(function(error) { 
        context.notify((context.form != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() { 
      })
      .call();
    },
    createVendor() {
      this.form.add   = true;
      this.form.show  = true;
      this.form.title = "Register As a Partner";
      this.vendorData = {};
    },
    save(){
      let api      = null;
      let context  = this;
      let formData = new FormData();

      if (context.vendorData.company_name != undefined && context.vendorData.npwp != undefined && context.vendorData.contact_person != undefined && context.vendorData.address != undefined && context.vendorData.email != undefined && context.vendorData.vendor_category != undefined && context.vendorData.item_group != undefined) {
        formData.append('company_name', context.vendorData.company_name);
        formData.append('npwp', context.vendorData.npwp);
        formData.append('contact_person', context.vendorData.contact_person);
        formData.append('address', context.vendorData.address);
        formData.append('email', context.vendorData.email);
        formData.append('no_hp', (context.vendorData.no_hp == undefined) ? '-' : context.vendorData.no_hp);
        formData.append('phone', (context.vendorData.phone == undefined) ? '-' : context.vendorData.phone);
        formData.append('fax', (context.vendorData.fax == undefined) ? '-' : context.vendorData.fax);
        formData.append('vendor_category', context.vendorData.vendor_category);
        formData.append('item_group', context.vendorData.item_group);
        formData.append('note', (context.vendorData.note == undefined) ? '-' : context.vendorData.note);
      }else{
        return alert('The Red Star Field is Mandatory')
      }

      api = Api(context, vendorList.createUpdate(formData));

      // eslint-disable-next-line no-unused-vars
      api.onSuccess(function(response) {
        context.get();
        context.form.show = false;
        context.notify('Create or update Successfully', 'success')
      // eslint-disable-next-line no-unused-vars
      }).onError(function(error) { 
        context.notify('Create or update Failed', 'error')                   
      }).onFinish(function() {  
      })
      .call();
    },
    convertRp(bilangan) {
      if (bilangan) {
        var number_string = bilangan.toString(),
            sisa    = number_string.length % 3,
            rupiah  = number_string.substr(0, sisa),
            ribuan  = number_string.substr(sisa).match(/\d{3}/g);

        if(ribuan){
          var separator = sisa ? '.' : '';
          rupiah += separator + ribuan.join('.');
          return rupiah
        }else{
          return bilangan
        }
      }
    },
    notify(message, type) {
      if (type == 'success') {
        toast.success(message, {
          autoClose: 2000,
        }); // ToastOptions
      }else{
        toast.error(message, {
          autoClose: 2000,
        }); // ToastOptions
      }
    },

  },

  beforeMount() {
    this.$store.state.imageLayout = "profile-overview";
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = true;
    // body.classList.add("profile-overview");
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.imageLayout = "default";
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
    // body.classList.remove("profile-overview");
  },
};
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 500px;
  margin-top: auto;
  margin-bottom: auto;
}
.modal__title {
  font-size: 1rem;
  font-weight: 600;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
.scroll { 
  overflow: auto; 
  height: auto; 
}
.scroll thead th { 
  position: sticky; 
  top: 0; 
  z-index: 100; 
  background-color: #F0F8FF;
}
</style>
